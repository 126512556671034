import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Tooltip, Skeleton, Button, TextField, Menu, MenuItem } from '@mui/material';
import { ReactComponent as GreenCard } from '../Images/greenCard.svg';
import { ReactComponent as YellowCard } from '../Images/yellowCard.svg';
import { ReactComponent as RedCard } from '../Images/redCard.svg';
import { ReactComponent as GrayCard } from '../Images/grayCard.svg';
import { ReactComponent as Vacio } from '../Images/Vacio.svg';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteDoc from '../PopUpsAlerts/DeleteDoc/DeleteDoc';
import { fetchUserFile } from '../Api/FilesApi';
import * as handleDoc from "./handleDoc";
import { keyframes } from '@mui/system';

const DocumentSkeleton = () => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '32px',
            borderBottom: '1px solid #E0E0E0',
            minHeight: '120px',
        }}
    >
        <Skeleton variant="circular" width={80} height={80} sx={{ marginRight: '20px' }} />
        <Box sx={{ flexGrow: 1 }}>
            <Skeleton variant="text" width="60%" height={24} sx={{ marginBottom: '8px' }} />
            <Skeleton variant="text" width="40%" height={20} />
        </Box>
    </Box>
);
// Add this keyframes definition outside of the component
const colorChange = keyframes`
  0% { color: #4ECDC4; }  // Light teal
  33% { color: #45B7A0; } // Seafoam green
  66% { color: #2AB7CA; } // Sky blue
  100% { color: #4ECDC4; } // Back to light teal
`;
const DocumentListFolder = ({
    listDocuments,
    isLoading,
    selectedDocument,
    onDocumentSelect,
    selectable = true,
    box = true,
    token,
    user,
    updateListDocuments,
    setShowPremium
}) => {
    const navigate = useNavigate();
    const itemColors = [
        { ReactComponent: GreenCard, color: '#00B564', label: 'Preguntas Memorizadas' },
        { ReactComponent: YellowCard, color: '#FFA726', label: 'Preguntas Aprendiendo' },
        { ReactComponent: RedCard, color: '#AA161D', label: 'Preguntas Incorrectas' },
        { ReactComponent: GrayCard, color: '#BDBDBD', label: 'Preguntas Sin Empezar' }
    ];

    const getMemorizedChaptersText = (document) => {
        const memorizedChapters = document.main_topics.filter(topic =>
            (topic.points / topic.TotalPossiblePoints * 100) >= 80
        ).length;
        const totalChapters = document.main_topics.length;
        const totalPoints = document.main_topics.reduce((sum, topic) => sum + topic.points, 0);
        const totalMaxPoints = document.main_topics.reduce((sum, topic) => sum + topic.TotalPossiblePoints, 0);
        return `${totalPoints} de ${totalMaxPoints} puntos de memoria🧠`;
    };

    const getProgress = (document) => {
        const totalPoints = document.main_topics.reduce((sum, topic) => sum + topic.points, 0);
        const totalMaxPoints = document.main_topics.reduce((sum, topic) => sum + topic.TotalPossiblePoints, 0);
        const percentageMemorized = Math.max(0, (totalPoints / totalMaxPoints) * 100);
        const result = percentageMemorized.toFixed(0);
        return isNaN(result) || result === 'null' ? '0' : result;
    };

    const getProgressTopic = (document) => {
        return { memorized: document.status.memorized, learning: document.status.learning, notKnown: document.status.notKnown, notStarted: document.status.notStarted };
    };

    const handleBoxClick = (document, event) => {
        event.stopPropagation();
        if (!document.is_blocked) {
            navigate(`/${document.folder}/${document.guid}`);
        } else {
            setShowPremium(true)
        }
    };

    const [editingDocId, setEditingDocId] = useState(null);
    const [editableTitle, setEditableTitle] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [showDeleteDoc, setShowDeleteDoc] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);

    const handleEditClick = (event, document) => {
        event.stopPropagation();
        setEditingDocId(document.guid);
        setEditableTitle(document.title);
    };

    const handleTitleBlur = async (document) => {
        setEditingDocId(null);
        try {
            await fetchUserFile(token, "PUT", document.folder, document.guid, { "docName": editableTitle }, user);
            // Update the document title in the list
            updateListDocuments(document.guid, { ...document, title: editableTitle });
        } catch (error) {
            console.error(error);
        }
    };

    const handleTitleChange = (event) => {
        setEditableTitle(event.target.value);
    };

    const handleClickMenu = (event, document) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setDocumentToDelete(document);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleDeleteDocument = async () => {
        try {
            await handleDoc.deleteDocument(token, documentToDelete.guid, documentToDelete.folder, user);
            updateListDocuments(documentToDelete.guid);
        } catch (error) {
            console.error(error);
        }
        setShowDeleteDoc(false);
        handleCloseMenu();
    };

    const handleStudyClick = () => {
        navigate('/new-exam');
        handleCloseMenu();
    };

    const content = (
        <>
            <DeleteDoc
                open={showDeleteDoc}
                onClose={() => setShowDeleteDoc(false)}
                onDelete={handleDeleteDocument}
                documentName={documentToDelete?.title}
            />
            {
                listDocuments.length === 0 ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '32px',
                        minHeight: '300px'
                    }}>
                        <Vacio width="120" height="120" />
                        <Typography sx={{ mt: 2, color: '#666', fontSize: '16px' }}>
                            Debes subir primero un documento
                        </Typography>
                    </Box>
                ) : (
                    listDocuments.map((document, index) => (
                        <Box
                            key={document.guid}
                            onClick={(event) => handleBoxClick(document, event)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '32px',
                                borderBottom: index < listDocuments.length - 1 ? '1px solid #E0E0E0' : 'none',
                                minHeight: '120px',
                                backgroundColor: selectedDocument === document.guid ? '#E2F1F1' : 'white',
                                cursor: 'pointer', // Change cursor to pointer for all items
                                transition: 'background-color 0.3s',
                                '&:hover': selectable ? {
                                    backgroundColor: selectedDocument === document.guid ? '#E2F1F1' : '#F5F5F5',
                                } : {},
                            }}
                        >
                            <Box
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                    backgroundColor: '#E2F1F1',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '20px',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        width: '80px',
                                        height: '80px',
                                    }}
                                >
                                    <CircularProgress
                                        variant="determinate"
                                        value={getProgress(document)}
                                        size={80}
                                        thickness={4}
                                        sx={{
                                            color: '#6BBDBD',
                                            '& .MuiCircularProgress-circle': {
                                                strokeLinecap: 'round',
                                            },
                                            '& .MuiCircularProgress-circleBackground': {
                                                stroke: '#E2F1F1',
                                            },
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '60px',
                                            height: '60px',
                                            borderRadius: '50%',
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#6BBDBD',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {getProgress(document)}%
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ flexGrow: 1, textAlign: 'left', marginLeft: '20px' }}>
                                {editingDocId === document.guid ? (
                                    <TextField
                                        value={editableTitle}
                                        onChange={handleTitleChange}
                                        variant="standard"
                                        onClick={(event) => event.stopPropagation()}
                                        onBlur={() => handleTitleBlur(document)}
                                        autoFocus
                                        inputProps={{
                                            maxLength: 20,
                                        }}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                color: '#333',
                                            }
                                        }}
                                    />
                                ) : (
                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                color: '#333',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            {document.title.length > 20 ? (
                                                <Tooltip title={document.title}>
                                                    <span>{document.title.substring(0, 20)}...</span>
                                                </Tooltip>
                                            ) : (
                                                document.title
                                            )}
                                        </Typography>
                                        <EditIcon
                                            onClick={(event) => handleEditClick(event, document)}
                                            style={{ marginLeft: '8px', marginBottom: '8px', cursor: 'pointer', fontSize: '16px', color: '#C2C2C2' }}
                                        />
                                    </Box>
                                )}
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: '#666',
                                    }}
                                >
                                    {getMemorizedChaptersText(document)}
                                </Typography>
                            </Box>
                            {
                                document.is_blocked ? (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#AA161D',
                                            color: 'white',
                                            textTransform: 'none',
                                            fontWeight: '600',
                                            fontFamily: "'Inter', sans-serif",
                                            borderRadius: '24px',
                                            padding: '8px 24px',
                                            fontSize: '14px',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                backgroundColor: '#8B1217',
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // Add your unblock logic here
                                            setShowPremium(true)
                                        }}
                                    >
                                        Desbloquear
                                    </Button>
                                ) : document.is_error_generating ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start', // Aligns content to the left
                                        flexGrow: 1,
                                        gap: '32px',
                                    }}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#666' }}>
                                            Error generando preguntas
                                        </Typography>
                                    </Box>
                                ) : document.is_generating ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start', // Aligns content to the left
                                        flexGrow: 1,
                                        gap: '32px',
                                    }}>
                                        <Typography sx={{ fontWeight: 'bold', animation: `${colorChange} 2s ease-in-out infinite` }}>
                                            {'Generando preguntas...'.split('').map((char, index) => (
                                                <span
                                                    key={index}
                                                    sx={{
                                                        display: 'inline-block !important',
                                                        animation: `${colorChange} 2s ease-in-out infinite !important`,
                                                        animationDelay: `${index * 0.1}s !important`,
                                                    }}
                                                >
                                                    {char}
                                                </span>
                                            ))}
                                        </Typography>
                                    </Box>
                                ) : !document.questions_generated ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start', // Aligns content to the left
                                        flexGrow: 1,
                                        gap: '32px',
                                    }}>

                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#666' }}>
                                            No tienes preguntas generadas
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        flexGrow: 1,
                                        gap: '32px',
                                    }}>
                                        {Object.entries(getProgressTopic(document)).map(([key, value], i) => (
                                            <Tooltip
                                                key={key}
                                                title={itemColors[i].label}
                                                arrow
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                            color: itemColors[i].color,
                                                            marginRight: '8px',
                                                        }}
                                                    >
                                                        {value}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            width: '24px',
                                                            height: '24px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        {React.createElement(itemColors[i].ReactComponent, {
                                                            width: '24px',
                                                            height: '24px',
                                                        })}
                                                    </Box>
                                                </Box>
                                            </Tooltip>
                                        ))}
                                    </Box>
                                )
                            }

                            <MoreVertIcon
                                onClick={(event) => handleClickMenu(event, document)}
                                style={{ color: "#026277", cursor: 'pointer', marginLeft: '8px' }}
                            />
                        </Box>
                    ))
                )
            }
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {documentToDelete && !documentToDelete.is_blocked && !documentToDelete.is_generating && documentToDelete.questions_generated && (
                    <MenuItem onClick={handleStudyClick}>Estudiar</MenuItem>
                )}
                <MenuItem onClick={() => {
                    setShowDeleteDoc(true);
                    handleCloseMenu();
                }}>Delete</MenuItem>
            </Menu>
        </>
    );

    return box ? (
        <Box sx={{ marginTop: '20px', marginBottom: '40px', backgroundColor: 'white', overflowX: 'hidden' }}>
            <Box className="custom-scrollbar" sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
                {content}
            </Box>
        </Box>
    ) : content;
};

export default DocumentListFolder;