import React, { useState, useEffect, useMemo, useRef } from "react";
import { Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Options from "../NewComponents/Options/Options";
import * as handle from "./handle";
import { fetchUserFolder } from "../Api/FolderApi";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';  // Import upload icon from Material-UI
import { useAuth } from "../Auth/Authhandle";
import { Skeleton } from "@material-ui/lab"; // Import the Skeleton component
import { makeStyles } from '@material-ui/core/styles';
import ApproveSelection from './Components/ApproveSelection'; // Adjust the path as necessary
import CreateFolderButton from './Components/CreateFolderButton'; // Adjust the path as necessary
import SmallAlert from "../NewComponents/Modal/smallAlert";
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Paper, TableHead
} from '@material-ui/core';
import useTableFolderStyles from "./Styles/useTableFolderStyles";
import FolderCard from "../Home/Components/FolderCard";
import DeleteCarpeta from "../PopUpsAlerts/DeleteDoc/DeleteCarpeta"; // Add this import

const MAX_TITLE_LENGTH = 255; // Adjust the maximum title length as needed

// Sample styles
const styles = {
  folder: {
    position: "relative",
    width: "100%",
    boxSizing: "border-box",
    padding: "10px",
    textAlign: "center",
  },
}

function TableFolders({
  initialFolders,
  initialTranscripts,
  fetchDataAndUpdate,

  accessToken,
  user,
  showList, // New prop to determine the display mode
  handleCreateFolderClick

}) {
  const { t } = useTranslation();

  const classes = useTableFolderStyles();

  const [folders, setFolders] = useState(initialFolders);
  const [transcripts, setTranscripts] = useState(initialTranscripts);
  const [autosaveTimeout, setAutosaveTimeout] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [draggedVideo, setDraggedVideo] = useState(null);
  const [dropTargetFolder, setDropTargetFolder] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [hoveredFolder, setHoveredFolder] = useState(null); // Add this state to track the hovered folder
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);

  // Combine them for rendering
  const listFolders = useMemo(() => folders.concat(transcripts), [folders, transcripts]);

  const folderRef = React.useRef(null);
  const navigate = useNavigate();

  // Functions
  const enterFolder = (folder_id) => {
    let path = `/${folder_id}`;
    navigate(path);
  };


  const handleMoveSuccess = (movedVideo, targetFolder) => {
    // Remove the video from the transcripts
    const updatedTranscripts = transcripts.filter(video => video.guid !== movedVideo.guid);
    setTranscripts(updatedTranscripts);
    // Update folders if necessary
  };


  useEffect(() => {
    setFolders(initialFolders);

  }, [initialFolders]); // Add folders as a dependency for this effect



  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDocument(null);
  };
  const handleFolderSelect = () => {
    // Logic for handling folder selection goes here
    handleCloseDialog();
  };

  const [editableTitle, setEditableTitle] = useState("")
  const debouncedEdit = useRef(null);


  const handleDeleteFolder = (folderId) => {
    setFolders((prevFolders) => {
      // Filter out the folder with the specified folderId
      // This keeps all folders except the one we want to delete
      const updatedFolders = prevFolders.filter((folder) => folder.guid !== folderId);
      return updatedFolders;
    });
  }

  return (<>
    {showAlert &&
      <SmallAlert
        message={message}
        progressBar={true}
        onClose={() => setShowAlert(false)}
      />
    }
    <ApproveSelection
      open={openDialog}
      onClose={handleCloseDialog}
      onFolderSelect={handleFolderSelect}
      accessToken={accessToken}
      user={user}
      document={draggedVideo}
      selectedFolder={dropTargetFolder}
      handleMoveSuccess={handleMoveSuccess}
    />

    <div className={classes.gridContainer}>
      <Button
        onClick={handleCreateFolderClick}
        style={{
          display: "flex",
          padding: "4px 12px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
          background: "#E2F1F1",
          textTransform: "none",
          color: "#026277",
          height: "40px",
          width: "150px",
          margin: "auto"
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 20 20"
          fill="none"
          style={{ marginRight: 8 }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 3C10.5523 3 11 3.44772 11 4V9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H11V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V11H4C3.44772 11 3 10.5523 3 10C3 9.44771 3.44772 9 4 9L9 9V4C9 3.44772 9.44772 3 10 3Z"
            fill="#111827"
          />
        </svg>
        {t("nothing_desc")}
      </Button>
      {
        listFolders.map((element, index) => (
          <FolderCard key={index} element={element} accessToken={accessToken} user={user} onDeleteFolder={() => handleDeleteFolder(element.guid)} />
        ))
      }

    </div>

  </>
  );
}

export default TableFolders;