import { makeStyles } from "@material-ui/core/styles";

const useStylesPreguntasDocument = makeStyles((theme) => ({

  scrollableContainer: {
    height: '82vh', // Adjust the height as needed
    overflow: 'auto',
    marginTop: '24px !important',
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    overflowX: 'hidden',
    paddingBottom: '100px !important',
  },
  cardPreguntas: {
    margin: 'auto !important',
    marginTop: '8px !important', // Custom spacing value
    fontFamily: 'Inter, sans-serif !important',
    borderRadius: '22px !important',
    border: '3px solid #E2F1F1 !important'
  },
  headerPreguntas: {
    width: '100%',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    fontFamily: 'Inter, sans-serif !important',
    fontWeight: "bold !important",
    fontSize: "16px !important",
    overflow: 'hidden', // Ensure content overflow is hidden
    textOverflow: 'ellipsis', // Add ellipsis for overflowed text
    whiteSpace: 'nowrap', // Prevent text from wrapping
    // [theme.breakpoints.down('md')]: {
    //   fontSize: "12px !important",
    // },
  },
  headerTextPreguntas: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 'bold !important',
    fontFamily: 'Inter, sans-serif !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
    },
  },
  pointsBox: {
    display: 'flex !important',
    alignItems: 'center !important',
  },
  pointsText: {
    marginRight: '8px !important', // Custom spacing value
    fontWeight: 'bold !important',
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '16px !important',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  pointsValue: {
    fontWeight: 'bold !important',
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '16px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
    },
  },
  memorizedChipPreguntas: {
    marginLeft: '16px !important', // Custom spacing value
    fontFamily: 'Inter, sans-serif !important',
    backgroundColor: '#D9F9D4 !important',
    fontWeight: "bold",
    color: '#00B564 !important',
    fontSize: '16px !important',
    [theme.breakpoints.down(1400)]: {
      fontSize: '12px !important',
      marginLeft: '8px !important',
    },
  },

  learningChipPreguntas: {
    marginLeft: '16px !important', // Custom spacing value
    fontFamily: 'Inter, sans-serif !important',
    backgroundColor: '#FFECCF !important',
    fontWeight: "bold",
    color: '#FCAB32 !important',
    fontSize: '16px !important',
    [theme.breakpoints.down(1400)]: {
      fontSize: '12px !important',
      marginLeft: '8px !important',
    },
  },

  wrongChipPreguntas: {
    marginLeft: '16px !important', // Custom spacing value
    fontFamily: 'Inter, sans-serif !important',
    backgroundColor: '#F49296 !important',
    fontWeight: "bold",
    color: '#AA161D !important',
    fontSize: '16px !important',
    [theme.breakpoints.down(1400)]: {
      fontSize: '12px !important',
      marginLeft: '8px !important',
    },
  },

  sinEmpezarChipPreguntas: {
    marginLeft: '16px !important', // Custom spacing value
    fontFamily: 'Inter, sans-serif !important',
    backgroundColor: '#E4E4E4 !important',
    fontWeight: "bold",
    color: '#A0A0A0 !important',
    fontSize: '16px !important',
    [theme.breakpoints.down(1400)]: {
      fontSize: '12px !important',
      marginLeft: '8px !important',
    },
  },

  questionBox: {
    padding: '16px !important', // Custom spacing value
  },
  questionText: {
    marginLeft: '8px !important',
    fontSize: '14px !important', // Custom spacing value
    fontWeight: '400 !important',
    fontFamily: 'Inter, sans-serif !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
    },
  },
  pageText: {
    marginLeft: 'auto !important',
    fontWeight: '500 !important',
    fontFamily: 'Inter, sans-serif !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
    },
  },
  accordionPreguntas: {
    marginBottom: '16px !important',
    boxShadow: 'none !important',
    '&:before': {
      display: 'none',
    },
  },
  accordionSummaryPreguntas: {
    flexDirection: 'row-reverse !important',
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginRight: '16px !important',
    },
  },
  questionCount: {
    marginLeft: '8px',
    fontSize: '14px',
    color: '#666',
  },
}));

export default useStylesPreguntasDocument;