import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, CircularProgress, Button, Tooltip, Divider } from '@mui/material';
import GettingStarted from '../Onboard/GettingStarted';
import RectanglesHome from '../Home/RectanglesHome';
import NeedCredits from '../NoCreditsModal/NeedCredits';
import { ReactComponent as GreenCard } from '../Images/greenCard.svg';
import { ReactComponent as YellowCard } from '../Images/yellowCard.svg';
import { ReactComponent as RedCard } from '../Images/redCard.svg';
import { ReactComponent as GrayCard } from '../Images/grayCard.svg';
import UploadButton from '../NewComponents/SubirApuntesButton';
import Vacio from '../Images/Vacio.svg';
import { useAuth } from '../Auth/Authhandle';
import * as handle from "../Main/handle";
import { useNavigate } from 'react-router-dom';
import { useSubscription } from '../Context/SubscriptionContext';
import DocumentList from '../Documents/AllListDocument';
import Improvements from '../Documents/Improvements';
import ErrorPreguntes from '../Documents/ErrorPreguntes';
import { useLocation } from 'react-router-dom';
import { WebSocketContext } from '../WebSocketProvider';
import Onboarding from '../Onboard/Onboarding';
// import DeleteDoc from '../PopUpsAlerts/DeleteDoc/DeleteDoc';
import { usePoints } from '../Context/PointsContext';

const Progress = ({ handleUploadFile, setShowBlockedDocument }) => {
  const { accessToken, user } = useAuth(); // Use the hook at the top level
  const { hasSubscription, setHasSubscription, userData, fetchData } = useSubscription();
  const location = useLocation(); // Hook to get the current location
  console.log("user data ", userData)
  const navigate = useNavigate();
  const [listDocuments, setListDocuments] = useState([]);
  const itemColors = [
    { ReactComponent: GreenCard, color: '#00B564', tooltip: 'Preguntas Memorizadas' },
    { ReactComponent: YellowCard, color: '#FFA726', tooltip: 'Preguntas Aprendiendo' },
    { ReactComponent: RedCard, color: '#AA161D', tooltip: 'Preguntas Incorrectas' },
    { ReactComponent: GrayCard, color: '#BDBDBD', tooltip: 'Preguntas Sin empezar' }
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [showImprovements, setShowImprovements] = useState(true);
  const [showErrorPreguntes, setShowErrorPreguntes] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const { messageCallback } = useContext(WebSocketContext);

  useEffect(() => {
    if (accessToken && user) {
      async function fetchDataDocs() {
        setIsLoading(true);
        if (accessToken && user) {
          await handle.fetchAllDocuments(accessToken, user, setListDocuments);
        }
        setIsLoading(false);
      }
      fetchDataDocs();
    }
  }, [accessToken, user]);
  useEffect(() => {
    if (fetchData) {
      fetchData(); // Fetch the latest user data when the component mounts
    }
  }, [location.pathname]);

  const { allPointsData, fetchAllPointsData } = usePoints();
  // useEffect(() => {
  //   console.log("User data changed")
  //   if (userData && !userData.done_onboarding) {
  //     setOpenModal(true);
  //   }
  // }, [userData]);



  useEffect(() => {
    if (fetchAllPointsData) {
      fetchAllPointsData();

    } else {
      console.log("fetchAllPointsData is not defined");
    }
  }, [fetchAllPointsData]);

  useEffect(() => {
    if (messageCallback && messageCallback.type === "documentsUpdate") {
      // Refetch documents when we receive a documentsUpdate message
      async function refetchDocuments() {
        setIsLoading(true);
        if (accessToken && user) {
          await handle.fetchAllDocuments(accessToken, user, setListDocuments);
        }
        setIsLoading(false);
      }
      refetchDocuments();
    }
  }, [messageCallback]);

  return (
    <>
      {openModal && (
        <Onboarding
          onClose={() => setOpenModal(false)}
        />
      )}

      <NeedCredits />
      <Box>
        <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
          <div>
            {/* <Typography
                            variant="h6"
                            style={{
                                color: "#026277",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                letterSpacing: "-0.225px",
                                paddingLeft: 2
                            }}
                        >
                            {t('your_folders', { Nombre: user.given_name })}👋
                        </Typography> */}
          </div>

          {userData && (
            <>

              {(!userData.has_uploaded_file || !userData.has_created_summary || !userData.has_created_exam) && (
                <GettingStarted
                  has_uploaded_file={userData.has_uploaded_file}
                  has_created_summary={userData.has_created_summary}
                  has_created_exam={userData.has_created_exam}
                />
              )}
              <RectanglesHome days={userData.streak_days} allPointsData={allPointsData} />
            </>
          )}

        </div>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            paddingTop="30px"
          >
            <Typography
              style={{
                color: '#6BBDBD',
                fontSize: '32px',
                fontStyle: 'normal',
                fontWeight: "bold",
                letterSpacing: '-0.225px',
                paddingLeft: "32px"
              }}
            >
              🏆 Progreso
            </Typography>
            <UploadButton onClick={handleUploadFile} />
          </Box>
          <Box sx={{
            marginTop: '20px',
            marginBottom: '80px',
            backgroundColor: 'white',
            border: '1px solid #E2F1F1',
            borderRadius: '20px',
            overflowY: 'auto',
            // height: '100vh',
            // fontFamily: "'Inter', sans-serif",
          }}>
            {isLoading ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '32px',
                height: '100%',
              }}>
                {[...Array(3)].map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '16px',
                    }}
                  >
                    <Box
                      sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        backgroundColor: '#E0E0E0',
                        marginRight: '20px',
                      }}
                    />
                    <Box sx={{ flexGrow: 1 }}>
                      <Box
                        sx={{
                          width: '60%',
                          height: '20px',
                          backgroundColor: '#E0E0E0',
                          marginBottom: '8px',
                        }}
                      />
                      <Box
                        sx={{
                          width: '40%',
                          height: '16px',
                          backgroundColor: '#E0E0E0',
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : listDocuments.length > 2 ? (
              <DocumentList
                listDocuments={listDocuments}
                isLoading={isLoading}
                selectable={false}
                box={false}
                setShowBlockedDocument={setShowBlockedDocument}
                accessToken={accessToken}
              />
            ) : listDocuments.length >= 1 ? (
              <>
                <DocumentList
                  listDocuments={listDocuments}
                  isLoading={isLoading}
                  selectable={false}
                  box={false}
                  setShowBlockedDocument={setShowBlockedDocument}
                  accessToken={accessToken}
                />
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '32px',
                  height: '100%',
                }}>
                  <img src={Vacio} alt="No documents" style={{ maxWidth: '100%', height: 'auto' }} />

                  <Typography sx={{ mt: 2, color: '#666', fontFamily: 'inherit' }}>
                    Sube más documentos a estudiar
                  </Typography>
                </Box>
              </>
            ) : (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '32px',
                height: '100%',
              }}>
                <img src={Vacio} alt="No documents" style={{ maxWidth: '100%', height: 'auto' }} />
                <Typography sx={{ mt: 2, color: '#666', fontFamily: 'inherit' }}>
                  Sube tus propios documentos para empezar a estudiar
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box >
    </>
  );
};

export default Progress;