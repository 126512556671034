import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import Tour from 'reactour'; // Import reactour
import { Box, Button, Typography, useTheme, IconButton, Card, CardContent } from '@material-ui/core';
import Sidebar from './Sidebar'; // Your Sidebar component
import { useHistory, Outlet } from "react-router-dom";
import SmallAlert from '../NewComponents/Modal/smallAlert';
import { WebSocketContext } from '../WebSocketProvider';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from "react-router-dom"; // Import useLocation
import { useNavigation } from '../NavigationContext'; // Import the custom hook
import { useTranslation } from 'react-i18next';
import "./style.css"
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { useMediaQuery } from '@material-ui/core';
import { Crisp } from "crisp-sdk-web";
import Onboarding from '../Onboard/Onboarding';
import Improvements from '../Documents/Improvements';
import FirstWeek from '../PopUpsAlerts/GettingStarted/FirstWeek';
import DocumentLocked from '../Documents/DocumentLocked';
import { useCredits } from '../Context/CreditsContext';
import { createTheme } from '@material-ui/core/styles'
import ModalParent from '../AllModalsActions/ParentModal';
import FullScreenLoader from '../NewComponents/LoadingExam';
import { fetchFolderExams } from '../Api/ExamsApi';
import { useAuth } from '../Auth/Authhandle';
import PopupsGettingStarted from '../PopUpsAlerts/GettingStarted/PopupsGettingStarted';
import PopupFinishExam from '../Exam/Styles/PopupFinishExam';
import ErrorPreguntes from '../Documents/ErrorPreguntes';
import PreguntasListas from '../PopUpsAlerts/QuestionsReady/PreguntasListas';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      phone: 500  // Custom breakpoint
    },
  },
});

const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: '30px',
    overflowY: 'scroll',
    overflowX: "hidden",
    // paddingLeft: "30px",
    backgroundColor: "#F8FFFF",
    [theme.breakpoints.down('700')]: {
      paddingLeft: "0px",
      paddingTop: '10px',
      backgroundColor: "white",

    },
    minHeight: "100vh",

    height: "100vh",
    maxHeight: "100vh",
  },

  typedIconImg: {
    position: "absolute",
    top: 10,
    left: 10

  },

  banner: {
    [theme.breakpoints.up('700')]: {
      display: "none"
    },
    backgroundColor: "#026277",
    width: "100%",
    textAlign: "center",
    color: "white",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  fullScreenBanner: {
    [theme.breakpoints.up('700')]: {
      display: "none"
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    backgroundColor: "white",
    textAlign: 'center',
    position: 'relative',
  },
  bannerImage: {
    maxWidth: '100%',
    maxHeight: '70%',
  },
  bannerText: {
    color: "#026277",
    fontSize: "20px",
    marginTop: "20px",
    fontWeight: 900,
    lineHeight: "120%",
    color: "#6BBDBD",
    margin: "20px"
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1300, // Ensure it is above other content
    [theme.breakpoints.up('800')]: {
      display: "none"
    },
  },
  popup: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  continueButton: {
    marginTop: theme.spacing(2),
    textTransform: "none"
  },
  bannerConstant: {
    backgroundColor: "#E2F1F1",
    width: "100%",
    textAlign: "center",
    color: "black",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    paddingLeft: "0px",

  },
  containerBlock: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    scroll: "hidden"
  },
  backAction: {
    position: 'fixed',
    top: '25px',
    zIndex: 1000,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: "10px",
    marginLeft: "250px"
  },
  goBackButton: {

    zIndex: 1000,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    border: '1px solid #6BBDBD',
    backgroundColor: "rgba(107, 189, 189, 0.20)"
  }
}));

const useStylesNavbar = makeStyles((theme) => ({
  notification: {

    position: 'fixed',
    top: '20px',
    right: '20px',
    padding: '10px 20px',
    backgroundColor: '#fff',
    border: '1px solid #6BBDBD',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1400, // Ensure it's above other content
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: '5px',
    width: 5
  },
  dots: {
    display: 'inline-block',
    marginLeft: '10px',
    marginRight: '20px',
    '& span': {
      display: 'inline-block',
      width: '3px',
      height: '3px',
      margin: '0 2px',
      backgroundColor: '#333',
      borderRadius: '50%',
      animation: '$dot-blink 1.4s infinite both',
    },
    '& span:nth-child(1)': {
      animationDelay: '0s',
    },
    '& span:nth-child(2)': {
      animationDelay: '0.2s',
    },
    '& span:nth-child(3)': {
      animationDelay: '0.4s',
    },
  },
  '@keyframes dot-blink': {
    '0%, 80%, 100%': {
      transform: 'scale(1)',
    },
    '40%': {
      transform: 'scale(1.5)',
    },
  },
}));
const PopUpExecutingNotification = ({ isExamExecuting, examName, onClose, message, buttonText }) => {
  const classes = useStylesNavbar();
  const { t } = useTranslation();
  const notificationRef = useRef(null);
  const navigate = useNavigate();

  if (!isExamExecuting) {
    return null;
  }

  return (
    <div className={classes.notification}>
      <Typography style={{ fontSize: "14px", marginRight: "10px" }}>{message}</Typography>
      {!buttonText && (
        <div className={classes.dots}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
      {buttonText && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => { navigate(buttonText); onClose() }}
          style={{
            marginLeft: '10px',
            marginRight: '10px',
            borderRadius: "10px",
            textTransform: "none",
            height: '25px' // Reduced height
          }}
        >
          Ver
        </Button>
      )}
      <IconButton onClick={onClose}>
        <CloseIcon style={{ fontSize: "20px" }} />
      </IconButton>
    </div>
  );
};
const Layout = ({ children }) => {
  const { accessToken, user } = useAuth();
  useEffect(() => {

    Crisp.user.setEmail(user.email);
    Crisp.user.setNickname(user.name);

    Crisp.session.setData({
      user_id: user.guid,
    });
  }, [
    accessToken, user
  ])
  const { updateCredits } = useCredits()

  const theme = useTheme(); // Use theme here
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [linkInMessage, setLinkInMessage] = useState(false);
  const [typeAlert, setTypeAlert] = useState("info"); // Default type
  const { previousPath, currentPath } = useNavigation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { messageCallback } = useContext(WebSocketContext);
  const [showPopup, setShowPopup] = useState(true);  // Initially show the popup
  const [runTour, setRunTour] = useState(false); // Control when the tour runs
  const [openModal, setOpenModal] = useState(false); // State to manage modal visibility
  const [popUpExecuting, setIsPopUpExecuting] = useState(false);
  const [popUpRetry, setPopUpRetry] = useState(false);
  const [examName, setExamName] = useState("");
  const [isExamReady, setIsExamReady] = useState(false);
  const [examRedirectId, setExamRedirectId] = useState("");
  const [messageExecuting, setMessageExecuting] = useState("");

  const [folderId, setFolderId] = useState('');
  const [initPage, setInitPage] = useState(0);
  const [finalPage, setFinalPage] = useState(0);
  const [documentId, setDocumentId] = useState('');
  const [numberQuestions, setNumberQuestions] = useState(0);
  const [typeExamOption, setTypeExamOption] = useState('');
  const [popUpGettingStarted, setPopUpGettingStarted] = useState(false);
  const [popUpGettingStartedMessage, setPopUpGettingStartedMessage] = useState("");
  Crisp.configure("c7fef405-658f-47ac-88e3-b3de23c947f4");

  const [steps, setSteps] = useState([
    {
      selector: '.home-step',
      content: <div style={{ paddingTop: "10px" }}>{t("home_step")}</div>,
    },
    {
      selector: '.sidebar-step-exam',
      content: t("sidebar_exam_step"),
    },
    {
      selector: '.sidebar-step-file',
      content: t("sidebar_summary_step"),
    },
  ]);
  const handleClose = () => {
    setShowPopup(false); // Function to close the popup
  };
  const [showPreguntasListas, setShowPreguntasListas] = useState(false);
  const [documentIdPreguntasListas, setDocumentIdPreguntasListas] = useState("");
  const [folderIdPreguntasListas, setFolderIdPreguntasListas] = useState("");
  const [stepValue, setStepValue] = useState(0);
  const [messageStep, setMessageStep] = useState("");
  const [buttonText, setButtonText] = useState("");
  useEffect(() => {
    if (messageCallback) {
      if (messageCallback.type != "documentsUpdate" && messageCallback.type != "PageSummary" && messageCallback.type != "got_zero_credits" && messageCallback.type != "popups_getting_started" && messageCallback.type != "appear_show_v2" && messageCallback.type != "generating_questions_done") {
        setMessage(messageCallback.messageAlert);
        setTypeAlert(messageCallback.typeAlert);
        setShowAlert(true);
      }
      switch (messageCallback.type) {
        case "ExamExecuting":
          let examName = messageCallback.examName
          setIsPopUpExecuting(true);
          setExamName(messageCallback.examName);
          setMessageExecuting(t('exam_executing', { examName }))
          break;
        case "appear_show_v2":
          setShowPopupShowV2(true);

          break;
        case "documentsUpdate":
          let folderId_callback = messageCallback.folderId
          let documentId_callback = messageCallback.documentId
          setIsPopUpExecuting(true);
          setMessageExecuting("Tu documento se ha subido correctamente")
          setButtonText("/" + folderId_callback + "/" + documentId_callback)

          setShowAlert(false)

          break;
        case "ErrorGenerating":
          setErrorPreguntesGenerate(true);
          setShowAlert(false)

          break;
        case "generating_questions_done":
          if (location.pathname !== `/${messageCallback.folderId}/${messageCallback.documentId}`) {

            setShowPreguntasListas(true)
            setFolderIdPreguntasListas(messageCallback.folderId)
            setDocumentIdPreguntasListas(messageCallback.documentId)
          }
          break;
        case "Exam":
          // setIsPopUpExecuting(false);

          // setIsExamReady(true)
          let exam_id = messageCallback.guid
          setExamRedirectId(exam_id)
          updateCredits()

          break;
        case "SummaryGenerating":
          setMessageExecuting(messageCallback.messageAlert)
          setIsPopUpExecuting(true);
          break;
        case "steps_generating_questions":
          setStepValue(messageCallback.step)
          setMessageStep(messageCallback.messageStep)
          setMessageExecuting(`${messageCallback.step} ${messageCallback.messageStep}`)
          setIsPopUpExecuting(true);

          break;
        case "summary":
          setIsPopUpExecuting(false);
          break;

        case "Error":
          setIsPopUpExecuting(false);
          setShowAlert(false)
          break;

        case "ExamError":
          setIsPopUpExecuting(false);
          setPopUpRetry(true)

          setFolderId(messageCallback.data_rerun["folder_id"])
          setInitPage(messageCallback.data_rerun["init_page"])
          setFinalPage(messageCallback.data_rerun["final_page"])
          setDocumentId(messageCallback.data_rerun["document_id"])
          setNumberQuestions(messageCallback.data_rerun["number_questions"])
          setTypeExamOption(messageCallback.data_rerun["type_exam_option"])

          break;
        case "got_zero_credits":
          setShowFirstWeekPopup(true)
          break;
        case "popups_getting_started":
          setPopUpGettingStarted(true)
          setPopUpGettingStartedMessage(messageCallback.message)
          updateCredits()
          break;
        default:
          console.log("Unhandled message type:", messageCallback.document);
      }

    }
  }, [messageCallback]);

  const location = useLocation(); // Hook to get the location object
  useEffect(() => {

    document.querySelector('.custom-scrollbar-wide').scrollTop = 0;

  }, [location.pathname]);




  const classes = useStyles();
  const goBack = () => {
    // Check the previous path in the browser history
    const previousPath = location.state?.from;
    // If the previous path was onboarding, redirect to home
    if (previousPath === '/onboarding') {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  const handleNotificationClose = () => {
    setIsPopUpExecuting(false);
  };
  const handleExamError = async (folder_id, initPage, finalPage, document_id, number_questions, typeExamOption) => {
    // await handleExamRetry(exam_guid, initPage, finalPage, document_guid)
    setPopUpRetry(false)

    const formData = new FormData();
    formData.append('type[type_exam]', typeExamOption);
    formData.append('type[difficulty]', "difficult");
    formData.append('type[Folder]', folder_id);
    formData.append('type[Document]', document_id);
    formData.append('number_questions', number_questions);
    formData.append('init_page', initPage);
    formData.append('final_page', finalPage);
    let data = await fetchFolderExams(accessToken, "POST", "", user, formData);

  };

  const handleCloseGettingStartedPopup = () => {
    setPopUpGettingStarted(false);
  };

  const matches = useMediaQuery(theme.breakpoints.up(700)); // Check if screen is above 700px
  const [showFirstWeekPopup, setShowFirstWeekPopup] = useState(false);
  const handleCloseFirstWeekPopup = () => {
    setShowFirstWeekPopup(false);
  };

  const [showPopupFinishExam, setShowPopupFinishExam] = useState(false);
  const handleClosePopupFinishExam = () => {
    setShowPopupFinishExam(false);
  };
  const handleConfirmPopupFinishExam = () => {
    setShowPopupFinishExam(false);
  };

  const [showPopupShowV2, setShowPopupShowV2] = useState(false);
  const handleClosePopupShowV2 = () => {
    setShowPopupShowV2(false);
  };
  const [errorPreguntesGenerate, setErrorPreguntesGenerate] = useState(false);
  const handleCloseErrorPreguntesGenerate = () => {
    setErrorPreguntesGenerate(false);
  };
  return (
    <>
      {showPreguntasListas && <PreguntasListas folderId={folderIdPreguntasListas} documentId={documentIdPreguntasListas}
        onClose={() => setShowPreguntasListas(false)}
      />}
      {showPopupFinishExam && (
        <PopupFinishExam open={showPopupFinishExam} onClose={handleClosePopupFinishExam} onConfirm={handleConfirmPopupFinishExam} />
      )}

      {showFirstWeekPopup && (
        <FirstWeek open={showFirstWeekPopup} onClose={handleCloseFirstWeekPopup} />
      )}
      {popUpGettingStarted && (
        <PopupsGettingStarted open={popUpGettingStarted} onClose={handleCloseGettingStartedPopup} action={popUpGettingStartedMessage} />
      )}

      {showPopupShowV2 && <Improvements open={showPopupShowV2} onClose={handleClosePopupShowV2} />}
      {errorPreguntesGenerate && <ErrorPreguntes open={errorPreguntesGenerate} onClose={handleCloseErrorPreguntesGenerate} />}
      <Box display="flex" height="100%">
        {matches && <Sidebar style={{ marginRight: '200px' }} />}

        <Tour
          steps={steps}
          isOpen={runTour}
          onRequestClose={() => setRunTour(false)}
          accentColor="#026277"
          rounded={5}
          className="helper"


        />

        {isExamReady && (
          <FullScreenLoader
            imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/examready.svg"
            title={t('fullScreenLoader.examReadyTitle')}
            message={t('fullScreenLoader.examReadyMessage')}
            buttonText={t('fullScreenLoader.examReadyButton')}
            onClose={() => { setIsExamReady(false); }}
            onButtonClick={() => { window.location.href = `/exam/${examRedirectId}`; }}
          />
        )}
        {popUpRetry && (
          <FullScreenLoader
            // imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/examready.svg"
            title={t('fullScreenLoader.examReadyTitle')}
            message="Lo sentimos, ha habido un error"
            buttonText="Reintentar"
            onClose={() => { setPopUpRetry(false); }}
            onButtonClick={() => { handleExamError(folderId, initPage, finalPage, documentId, numberQuestions, typeExamOption) }}
          />
        )}
        <PopUpExecutingNotification isExamExecuting={popUpExecuting} onClose={handleNotificationClose} message={messageExecuting} buttonText={buttonText} />

        {/* {location.pathname !== '/' && (
        <div className={classes.backAction}>
          <div className={classes.goBackButton} onClick={goBack}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
              <path d="M13 15.5L10 12.5L13 9.5" stroke="#6BBDBD" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <Typography onClick={goBack} style={{ fontSize: "14px" }}>{t("back_volver")}</Typography>
        </div>)} */}
        {/* <Button variant="contained" color="primary" onClick={goBack} className={classes.goBackButton}>

        Go Back
      </Button> */}
        {/* <div flexGrow={1} className={classes.containerBlock}>
        <div className={classes.bannerConstant}>
          <p style={{ marginRight: "5px", marginLeft: "5px" }}> ¿Te está gustando Typed? Compártelo con tus compañeros de clase  </p>
          <div>
            <Button className={classes.shareButton}>Compartir</Button>
          </div>
        </div> */}
        <Box component="main" flexGrow={1} className={`${classes.main} custom-scrollbar-wide`}>
          <div className={classes.fullScreenBanner}>
            <div>

              <img src={require('./images/iconName.svg').default} alt="Typed Icon" className={classes.typedIconImg} />
            </div>
            <Typography className={classes.bannerText}>
              {t("banner_phone")}

            </Typography>
            <img src={require('./images/PhoneImage.svg').default} alt="Typed only available on computers" className={classes.bannerImage} />
          </div>

          <Outlet /> {/* Outlet is where nested routes will render */}
        </Box>
        {/* < /div> */}
        {/* {showPopup && (
        <div className={classes.overlay}>
          <div className={classes.popup}>
            <Typography variant="h6">Please use a laptop for a better user experience</Typography>
            <Button variant="contained" color="primary" onClick={handleClose} className={classes.continueButton}>
              Continue
            </Button>
          </div>
        </div>
      )} */}
        {
          showAlert &&
          <SmallAlert
            message={message}
            progressBar={false}
            onClose={() => setShowAlert(false)}
            // linkInMessage={linkInMessage}
            typeAlert={typeAlert}
          />
        }


      </Box >
    </>
  );
};

export default Layout;
