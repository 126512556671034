import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardContent, Typography, Box, Grid, Container,
  Accordion, AccordionSummary, AccordionDetails, Button, Skeleton, Chip, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';

import { Tooltip } from '@mui/material';
import { useSubscription } from "../Context/SubscriptionContext";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStylesPreguntasDocument from './Styles/useStylePreguntasDocument';
import PreguntasDocumentPopup from './PreguntasDocumentPopup';
import LockIconWhite from '../Images/LockWhite.svg';
import Premium from '../PopUpsAlerts/Premium/Premium';
import GreenBook from '../Images/GreenBook.svg';
import BookMark from '../Images/GreenBookmarkFill.svg';
import GrayBook from '../Images/GrayBookmarkFill.svg';
import YellowBook from '../Images/YellowBookmarkFill.svg';
import RedBook from '../Images/RedBookmarkFill.svg';
import YellowCard from '../Images/yellowCard.svg';
import RedCard from '../Images/redCard.svg';
import GrayCard from '../Images/grayCard.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteTopic } from '../Api/ChaptersApi';

const useStylesDeleteChapter = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
  },
  dialog: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  content: {
    borderRadius: '20px !important',
    fontFamily: 'Inter, sans-serif !important',
  },
  actions: {
    justifyContent: 'space-between !important',
    fontFamily: 'Inter, sans-serif !important',
    marginTop: '15px !important',
    marginBottom: '10px !important',
  },
  dialogTitle: {
    '& .MuiTypography-root': {
      fontFamily: 'Inter, sans-serif !important',
      fontSize: '18px !important',
      fontWeight: 600,
      color: '#333 !important',
    },
  },
  deleteButton: {
    backgroundColor: '#D32F2F !important',
    color: 'white !important',
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '16px !important',
    fontWeight: 600,
    textTransform: 'none !important',
    borderRadius: '20px !important',
    padding: '6px 16px !important',
    '&:hover': {
      backgroundColor: '#B71C1C !important',
    },
  },
  cancelButton: {
    backgroundColor: '#6BBDBD !important',
    color: 'white !important',
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '16px !important',
    fontWeight: 600,
    textTransform: 'none !important',
    borderRadius: '20px !important',
    padding: '6px 16px !important',
    '&:hover': {
      backgroundColor: '#5AACAC !important',
    },
  },
}));

const SkeletonLoader = () => {
  const classes = useStylesPreguntasDocument();

  return (
    <Container className={classes.scrollableContainer}>
      {[1, 2, 3].map((_, index) => (
        <Accordion key={index} className={classes.accordionPreguntas}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
            className={classes.accordionSummaryPreguntas}
          >
            <Skeleton variant="text" width="80%" height={30} />
          </AccordionSummary>
          <AccordionDetails>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="40%" />
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}
const NewPreguntasDocument = ({ loading, chapters, error, setQuestions, user, accessToken }) => {
  const classes = useStylesPreguntasDocument();
  const deleteClasses = useStylesDeleteChapter();
  const { userData } = useSubscription();
  const { document_id, selectedDocumentParam } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [infoQuestion, setInfoQuestion] = useState(null);
  const [chapterTitle, setChapterTitle] = useState(null);
  const [showPremium, setShowPremium] = useState(false);
  const [premiumTitleText, setPremiumTitleText] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [chapterToDelete, setChapterToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [expandedChapter, setExpandedChapter] = useState(null);
  const [visibleQuestions, setVisibleQuestions] = useState(6);

  const getPointsTextColor = (points) => {
    if (points === 0) return classes.sinEmpezarText;
    if (points >= 1 && points <= 39) return classes.wrongText;
    if (points >= 40 && points <= 79) return classes.learningText;
    return classes.memorizedText;
  };

  const getCircleColor = (points) => {
    if (points === 0) return '#A0A0A0';
    if (points >= 5) return '#00B564';
    if (points <= -3) return '#AA161D';
    if (points > -3 && points < 5) return '#FCAB32';
    return '#00B564';
  };

  const handleOpen = (question, chapterTitle) => {
    setOpen(true);
    setInfoQuestion(question);
    setChapterTitle(chapterTitle);
  };

  const handleClose = () => setOpen(false);

  const handleShowPremium = (titleText) => {
    setShowPremium(true);
    setPremiumTitleText(titleText);
  };

  const handleUnlockQuestions = () => {
    handleShowPremium('questions');
  };

  const noShadowStyle = {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  };

  if (error) return <Typography color="error">{error}</Typography>;

  const cardStyle = {
    boxShadow: 'none',
    border: 'none',
    marginBottom: '8px', // Add some space between cards
  };

  const getChipClass = (points, max_points) => {
    if ((points / max_points) * 100 >= 80) return classes.memorizedChipPreguntas;
    if ((points / max_points) * 100 >= 40) return classes.learningChipPreguntas;
    if ((points / max_points) * 100 > 0) return classes.wrongChipPreguntas;
    if (points < 0) return classes.wrongChipPreguntas;
    return classes.sinEmpezarChipPreguntas;
  };

  const getChipLabel = (points, max_points) => {
    if ((points / max_points) * 100 >= 80) return " Memorizado";
    if ((points / max_points) * 100 >= 40) return "Aprendiendo";
    if ((points / max_points) * 100 > 0) return "Incorrectas";
    if (points < 0) return "Incorrectas";
    return "Sin Empezar";
  };

  // const getChapterPoints = (chapter) => {
  //   return chapter.subChapters.reduce((total, subChapter) => total + subChapter.points, 0);
  // };

  // const getChapterMaxPoints = (chapter) => {
  //   return chapter.subChapters.reduce((total, subChapter) => total + subChapter.max_points, 0);
  // };



  const CategoryCount = ({ count, icon, alt }) => {
    const getColor = () => {
      switch (icon) {
        case BookMark:
          return '#00B564';
        case YellowBook:
          return '#FCAB32';
        case RedBook:
          return '#AA161D';
        case GrayBook:
          return '#A0A0A0';
        default:
          return 'inherit';
      }
    };

    const getTooltipText = () => {
      switch (icon) {
        case BookMark:
          return 'Capítulos Memorizados';
        case YellowBook:
          return 'Capítulos Aprendiendo';
        case RedBook:
          return 'Capítulos Preparando';
        case GrayBook:
          return 'Capítulos Sin Empezar';
        default:
          return '';
      }
    };

    return (
      <Tooltip title={getTooltipText()} arrow>
        <Box display="flex" alignItems="center" marginRight={2}>
          <Typography variant="body2" style={{ marginRight: '4px', fontWeight: "bold", fontSize: "16px", color: getColor() }}>{count}</Typography>
          <img src={icon} alt={alt} style={{ width: "24px", height: "24px" }} />
        </Box>
      </Tooltip>
    );
  };

  // Helper function to get point color
  const getPointColor = (points, maxPoints) => {
    const percentage = (points / maxPoints) * 100;
    if (percentage >= 80) return '#00B564';
    if (percentage >= 40) return '#FCAB32';
    if (percentage > 0) return '#AA161D';
    return points < 0 ? '#AA161D' : '#A0A0A0';
  };

  const LoadingMoreChapters = () => (
    <Box display="flex" justifyContent="center" alignItems="center" py={2}>
      <CircularProgress size={24} style={{ marginRight: 16 }} />
      <Typography variant="body2">Loading more chapters...</Typography>
    </Box>
  );

  const handleDeleteClick = (chapter) => {
    setChapterToDelete(chapter);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = () => {
    setIsDeleting(true);
    deleteTopic(accessToken, document_id, chapterToDelete.guid, user)
      .then(() => {
        setQuestions(prevState => ({
          ...prevState,
          topics: prevState.topics.filter(topic => topic.guid !== chapterToDelete.guid)
        }));
        setOpenDeleteDialog(false);
        setChapterToDelete(null);
      })
      .catch(error => {
        console.error('Error deleting topic:', error);
        // Handle error (e.g., show error message to user)
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    setChapterToDelete(null);
  };

  const handleAccordionChange = (chapterIndex) => (event, isExpanded) => {
    setExpandedChapter(isExpanded ? chapterIndex : null);
    setVisibleQuestions(6)
    if (!isExpanded) {
      setVisibleQuestions(6);
    }
  };

  const handleShowMoreQuestions = () => {
    setVisibleQuestions(prev => prev + 6);
  };

  return (
    <>
      {showPremium && (
        <Premium
          onClose={() => setShowPremium(false)}
          titleText={premiumTitleText}
        />
      )}

      {open && <PreguntasDocumentPopup
        open={open}
        onClose={() => setOpen(false)}
        infoQuestion={infoQuestion}
        chapterTitle={chapterTitle}
      />}
      <Container className={`${classes.scrollableContainer} custom-scrollbar`} key={chapters.length} >
        {chapters && chapters.length > 0 ? (
          <>
            {chapters
              .filter(chapter => chapter.questions && chapter.questions.length > 0)
              .map((chapter, chapterIndex) => (
                <Accordion
                  key={chapterIndex}
                  className={classes.accordionPreguntas}
                  style={noShadowStyle}
                  expanded={expandedChapter === chapterIndex}
                  onChange={handleAccordionChange(chapterIndex)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${chapterIndex}a-content`}
                    id={`panel${chapterIndex}a-header`}
                    className={classes.accordionSummaryPreguntas}
                  >
                    <Grid container className={classes.headerPreguntas} spacing={2} alignItems="center">
                      <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={chapter.title} arrow>
                          <Typography
                            className={classes.headerPreguntas}
                            noWrap
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: 'auto',
                              display: 'inline-block',
                              verticalAlign: 'middle',
                            }}
                          >
                            {chapter.title}
                          </Typography>
                        </Tooltip>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{
                            marginLeft: '8px',
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}
                        >
                          ({chapter.questions.length})
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Typography variant="body2" color="textSecondary" className={classes.pointsText} style={{ marginRight: '8px' }}>
                          🧠
                        </Typography>
                        <Typography
                          className={classes.pointsValue}
                          style={{
                            color: getPointColor(chapter.points, chapter.max_points)
                          }}
                        >
                          {chapter.points}/{chapter.max_points}
                        </Typography>
                        <Chip
                          label={getChipLabel(chapter.points, chapter.max_points)}
                          className={getChipClass(chapter.points, chapter.max_points)}
                          style={{ paddingTop: "8px", paddingBottom: "8px", marginRight: '8px' }}
                        />
                        <DeleteIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(chapter);
                          }}
                          style={{ cursor: 'pointer', color: '#AA161D' }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {chapter.questions.slice(0, visibleQuestions).map((question) => (
                      <Card
                        variant="outlined"
                        className={classes.cardPreguntas}
                        key={question.id}
                        style={{ ...cardStyle, position: 'relative' }}
                      >
                        <CardContent style={{
                          filter: !question.questionactive && userData.plan_name === "Basic" ? 'blur(3px)' : 'none',
                          transition: 'filter 0.3s ease-in-out',
                          padding: '16px', // Adjust padding as needed
                        }}>
                          <Box className={classes.questionBox}>
                            <Grid container alignItems="center" justifyContent="space-between" direction="row">
                              <Grid item xs={10}>
                                <Box display="flex" alignItems="center">
                                  <Box
                                    component="span"
                                    sx={{
                                      flexShrink: 0,
                                      width: 16,
                                      height: 16,
                                      borderRadius: '100%',
                                      backgroundColor: getCircleColor(question.points),
                                      marginRight: '16px',
                                    }}
                                  />
                                  <Typography variant="body1" className={classes.questionText}>
                                    {question.text}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => handleOpen(question, chapter.title)}
                                  disabled={!question.questionactive && userData.plan_name === "Basic"}
                                  style={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    fontFamily: "'Inter', sans-serif",
                                    color: 'white',
                                    backgroundColor: '#6BBDBD',
                                    padding: '4px 16px',
                                    borderRadius: '24px',
                                    boxShadow: 'none',
                                    border: '1px solid #6BBDBD',
                                    width: '100%'
                                  }}
                                >
                                  Ver
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                        {userData.plan_name === "Basic" && !question.questionactive && (
                          <Box
                            position="absolute"
                            top="0"
                            left="0"
                            right="0"
                            bottom="0"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            zIndex="1"
                          >
                            <Button
                              onClick={() => handleShowPremium('questions')}
                              style={{
                                textTransform: 'none',
                                fontSize: '14px',
                                fontWeight: '600',
                                fontFamily: "'Inter', sans-serif",
                                color: 'white',
                                backgroundColor: '#AA161D',
                                padding: '4px 16px',
                                borderRadius: '24px',
                                boxShadow: 'none',
                                width: 'auto'
                              }}
                            >
                              <img src={LockIconWhite} alt="Lock" style={{ width: 20, height: 20, stroke: '#6BBDBD', strokeWidth: 2, marginRight: '8px' }} />
                              Desbloquear
                            </Button>
                          </Box>
                        )}
                      </Card>
                    ))}
                    {visibleQuestions < chapter.questions.length && (
                      <Button
                        onClick={handleShowMoreQuestions}
                        style={{
                          textTransform: 'none',
                          fontSize: '14px',
                          fontWeight: '600',
                          fontFamily: "'Inter', sans-serif",
                          color: 'white',
                          backgroundColor: '#6BBDBD',
                          padding: '8px 16px',
                          borderRadius: '24px',
                          boxShadow: 'none',
                          marginTop: '16px',
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto'
                        }}
                      >
                        Mostrar más
                      </Button>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            {loading && <LoadingMoreChapters />}
          </>
        ) : (
          <SkeletonLoader />
        )}
      </Container>

      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        classes={{
          root: deleteClasses.dialog,
          paper: deleteClasses.content,
        }}
        BackdropProps={{
          classes: {
            root: deleteClasses.backdrop,
          },
        }}
      >
        <DialogTitle className={deleteClasses.dialogTitle}>
          🗑 Eliminar capítulo
        </DialogTitle>
        <DialogContent>
          <Typography style={{ marginBottom: '10px' }}>
            ¿Estás seguro que quieres eliminar "<b>{chapterToDelete?.title}</b>"?
          </Typography>
          <Typography>
            Esta acción no se puede deshacer.
          </Typography>
        </DialogContent>
        <DialogActions className={deleteClasses.actions}>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '40px', width: '100%' }}>
            <Button
              onClick={handleDeleteCancel}
              className={deleteClasses.cancelButton}
              disabled={isDeleting}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              className={deleteClasses.deleteButton}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <CircularProgress size={24} style={{ color: 'white' }} />
              ) : (
                'Eliminar'
              )}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewPreguntasDocument;