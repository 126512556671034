import React from 'react';
import { Box, Typography, CircularProgress, Tooltip, Skeleton, Button } from '@mui/material';
import { ReactComponent as GreenCard } from '../Images/greenCard.svg';
import { ReactComponent as YellowCard } from '../Images/yellowCard.svg';
import { ReactComponent as RedCard } from '../Images/redCard.svg';
import { ReactComponent as GrayCard } from '../Images/grayCard.svg';
import { ReactComponent as Vacio } from '../Images/Vacio.svg';
import { useNavigate } from 'react-router-dom';
import { keyframes } from '@mui/system';
import { getCsrfToken } from "../Api/TokenApi";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const DocumentSkeleton = () => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '32px',
            borderBottom: '1px solid #E0E0E0',
            minHeight: '120px',
        }}
    >
        <Skeleton variant="circular" width={80} height={80} sx={{ marginRight: '20px' }} />
        <Box sx={{ flexGrow: 1 }}>
            <Skeleton variant="text" width="60%" height={24} sx={{ marginBottom: '8px' }} />
            <Skeleton variant="text" width="40%" height={20} />
        </Box>
    </Box>
);
// Add this keyframes definition outside of the component
const colorChange = keyframes`
  0% { color: #4ECDC4; }  // Light teal
  33% { color: #45B7A0; } // Seafoam green
  66% { color: #2AB7CA; } // Sky blue
  100% { color: #4ECDC4; } // Back to light teal
`;
const DocumentList = ({
    listDocuments,
    isLoading,
    selectedDocument,
    onDocumentSelect,
    selectable = true,
    box = true,
    setShowBlockedDocument,
    accessToken,

}) => {
    const navigate = useNavigate();
    const itemColors = [
        { ReactComponent: GreenCard, color: '#00B564', label: 'Preguntas Memorizadas' },
        { ReactComponent: YellowCard, color: '#FFA726', label: 'Preguntas Aprendiendo' },
        { ReactComponent: RedCard, color: '#AA161D', label: 'Preguntas Incorrectas' },
        { ReactComponent: GrayCard, color: '#BDBDBD', label: 'Preguntas Sin Empezar' }
    ];

    const getMemorizedChaptersText = (document) => {
        const memorizedChapters = document.main_topics.filter(topic =>
            (topic.points / topic.TotalPossiblePoints * 100) >= 80
        ).length;
        const totalChapters = document.main_topics.length;
        const totalPoints = document.main_topics.reduce((sum, topic) => sum + topic.points, 0);
        const totalMaxPoints = document.main_topics.reduce((sum, topic) => sum + topic.TotalPossiblePoints, 0);
        return `${totalPoints} de ${totalMaxPoints} puntos de memoria🧠`;
    };

    const getProgress = (document) => {
        const totalPoints = document.main_topics.reduce((sum, topic) => sum + topic.points, 0);
        const totalMaxPoints = document.main_topics.reduce((sum, topic) => sum + topic.TotalPossiblePoints, 0);
        const percentageMemorized = Math.max(0, (totalPoints / totalMaxPoints) * 100);
        const result = percentageMemorized.toFixed(0);
        return isNaN(result) || result === 'null' ? '0' : result;

    };

    const getProgressTopic = (document) => {
        return { memorized: document.status.memorized, learning: document.status.learning, notKnown: document.status.notKnown, notStarted: document.status.notStarted };
    };


    const handleBoxClick = (document, event) => {
        if (!box) {
            event.stopPropagation();
            if (!document.is_blocked) {
                navigate(`/${document.folder}/${document.guid}`);
            } else {
                setShowBlockedDocument(true)
            }
        } else if (selectable) {
            onDocumentSelect(document);
        }
    };

    const handleGenerateQuestions = async (folderId, documentGuid) => {
        try {

            const response = await fetch(`${BACKEND_URL}/api_v1/generation_questions/${documentGuid}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                    'X-CSRFToken': getCsrfToken()
                },
                body: JSON.stringify({
                    "needGenerateQuestions": true
                })
            });

            if (response.ok) {
                const data = await response.json();

                navigate(`/${folderId}/${documentGuid}`);
                // You might want to update the questions state here or trigger a re-fetch
                // For example: setQuestions({ ...questions, is_generating: true });
            } else {
                throw new Error('Failed to start question generation');
            }
        } catch (error) {
            console.error("Error generating questions:", error);

        }
    };
    const content = (
        <>
            {
                isLoading ? (
                    Array.from({ length: 3 }).map((_, index) => (
                        <DocumentSkeleton key={index} />
                    ))
                ) : listDocuments.length === 0 ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '32px',
                        minHeight: '300px'
                    }}>
                        <Vacio width="120" height="120" />
                        <Typography sx={{ mt: 2, color: '#666', fontSize: '16px' }}>
                            Debes subir primero un documento
                        </Typography>
                    </Box>
                ) : (
                    listDocuments.map((document, index) => (
                        <Box
                            key={document.guid}
                            onClick={(event) => handleBoxClick(document, event)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '32px',
                                borderBottom: index < listDocuments.length - 1 ? '1px solid #E0E0E0' : 'none',
                                minHeight: '120px',
                                backgroundColor: selectedDocument === document.guid ? '#E2F1F1' : 'white',
                                cursor: box ? (selectable ? 'pointer' : 'default') : 'pointer',
                                transition: 'background-color 0.3s',
                                '&:hover': selectable ? {
                                    backgroundColor: selectedDocument === document.guid ? '#E2F1F1' : '#F5F5F5',
                                } : {},
                            }}
                        >
                            <Box
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                    backgroundColor: '#E2F1F1',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '20px',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        width: '80px',
                                        height: '80px',
                                    }}
                                >
                                    <CircularProgress
                                        variant="determinate"
                                        value={getProgress(document)}
                                        size={80}
                                        thickness={4}
                                        sx={{
                                            color: '#6BBDBD',
                                            '& .MuiCircularProgress-circle': {
                                                strokeLinecap: 'round',
                                            },
                                            '& .MuiCircularProgress-circleBackground': {
                                                stroke: '#E2F1F1',
                                            },
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '60px',
                                            height: '60px',
                                            borderRadius: '50%',
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#6BBDBD',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {getProgress(document)}%
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ flexGrow: 1, textAlign: 'left', marginLeft: '20px' }}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        color: '#333',
                                        marginBottom: '8px',
                                    }}
                                >
                                    {document.title.length > 20 ? (
                                        <Tooltip title={document.title}>
                                            <span>{document.title.substring(0, 20)}...</span>
                                        </Tooltip>
                                    ) : (
                                        document.title
                                    )}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: '#666',
                                    }}
                                >
                                    {getMemorizedChaptersText(document)}
                                </Typography>
                            </Box>
                            {
                                document.is_blocked ? (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#AA161D',
                                            color: 'white',
                                            textTransform: 'none',
                                            fontWeight: '600',
                                            fontFamily: "'Inter', sans-serif",
                                            borderRadius: '24px',
                                            padding: '8px 24px',
                                            fontSize: '14px',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                backgroundColor: '#8B1217',
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // Add your unblock logic here
                                            setShowBlockedDocument(true)
                                        }}
                                    >
                                        Desbloquear
                                    </Button>
                                ) : document.is_error_generating ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start', // Aligns content to the left
                                        flexGrow: 1,
                                        gap: '32px',
                                    }}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#666' }}>
                                            Error generando preguntas
                                        </Typography>
                                    </Box>
                                ) : document.is_generating ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start', // Aligns content to the left
                                        flexGrow: 1,
                                        gap: '32px',
                                    }}>
                                        <Typography sx={{ fontWeight: 'bold', animation: `${colorChange} 2s ease-in-out infinite` }}>
                                            {'Generando preguntas...'.split('').map((char, index) => (
                                                <span
                                                    key={index}
                                                    sx={{  // Change this from 'style' to 'sx'
                                                        display: 'inline-block !important',
                                                        animation: `${colorChange} 2s ease-in-out infinite !important`,
                                                        animationDelay: `${index * 0.1}s !important`,
                                                    }}
                                                >
                                                    {char}
                                                </span>
                                            ))}
                                        </Typography> </Box>
                                ) : !document.questions_generated ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start', // Aligns content to the left
                                        flexGrow: 1,
                                        gap: '32px',
                                    }}>


                                    </Box>
                                ) : (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start', // Aligns content to the left
                                        flexGrow: 1,
                                        gap: '32px',
                                    }}>
                                        {Object.entries(getProgressTopic(document)).map(([key, value], i) => (
                                            <Tooltip
                                                key={key}
                                                title={itemColors[i].label}
                                                arrow
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                            color: itemColors[i].color,
                                                            marginRight: '8px',
                                                        }}
                                                    >
                                                        {value}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            width: '24px',
                                                            height: '24px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        {React.createElement(itemColors[i].ReactComponent, {
                                                            width: '24px',
                                                            height: '24px',
                                                        })}
                                                    </Box>
                                                </Box>
                                            </Tooltip>
                                        ))}
                                    </Box>
                                )}
                            {!box && !document.is_blocked && <Box sx={{ display: 'flex', gap: '8px', marginLeft: "20px" }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#6BBDBD',
                                        color: 'white',
                                        textTransform: 'none',
                                        fontWeight: '600',
                                        fontFamily: "'Inter', sans-serif",
                                        borderRadius: '24px',
                                        padding: '8px 24px',
                                        fontSize: '14px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: '#6BBDBD',
                                        }
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/${document.folder}/${document.guid}`);
                                    }}
                                >
                                    Ver
                                </Button>
                                {!document.is_error_generating && !document.is_generating && (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: 'white',
                                            color: '#6BBDBD',
                                            textTransform: 'none',
                                            fontWeight: '600',
                                            fontFamily: "'Inter', sans-serif",
                                            borderRadius: '24px',
                                            padding: '8px 24px',
                                            fontSize: '14px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                            boxShadow: 'none',
                                            border: '1px solid #6BBDBD',
                                            '&:hover': {
                                                backgroundColor: 'white',
                                            },
                                            cursor: 'pointer'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (!document.questions_generated) {
                                                // Handle the "Generar preguntas" action
                                                handleGenerateQuestions(document.folder, document.guid);
                                            } else {
                                                // Handle the "Estudiar" action
                                                navigate(`/new-exam?document=${document.guid}`);
                                            }
                                        }}
                                    >
                                        {!document.questions_generated ? (
                                            'Generar preguntas'
                                        ) : !document.is_error_generating ? (
                                            'Estudiar'
                                        ) : null}
                                    </Button>)}
                            </Box>}
                        </Box>
                    ))
                )
            }
        </>
    );

    return box ? (
        <Box sx={{ marginTop: '20px', marginBottom: '40px', backgroundColor: 'white', border: '1px solid #6BBDBD', borderRadius: '20px', overflowX: 'hidden' }}>
            <Box className="custom-scrollbar" sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                {content}
            </Box>
        </Box>
    ) : content;
};

export default DocumentList;
