import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, TextField, Menu, MenuItem, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchUserFile } from '../../Api/FilesApi';
import * as handleDoc from "../../Documents/handleDoc";
import { useTranslation } from 'react-i18next';
import DeleteDoc from '../../PopUpsAlerts/DeleteDoc/DeleteDoc';
import { ReactComponent as GreenCard } from '../../Images/greenCard.svg';
import { ReactComponent as YellowCard } from '../../Images/yellowCard.svg';
import { ReactComponent as RedCard } from '../../Images/redCard.svg';
import { ReactComponent as GrayCard } from '../../Images/grayCard.svg';
const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 20,
        backgroundColor: '#FFFFFF',
        boxShadow: 'none',
        border: "1px solid #E2F1F1",
        cursor: "pointer"
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: "10px",
        paddingTop: "20px"
    },
    title: {
        fontWeight: 600,
        fontSize: '14px',

    },
    iconButton: {
        cursor: 'pointer',
        color: '#2D3748',
    },
    content: {
        padding: "10px",

    },
    description: {
        color: '#4A5568',
        fontSize: '14px',
        marginBottom: '8px',
    },
    footer: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: '#E2F1F1',
        padding: '6px 10px',
        borderRadius: '0 0 10px 10px',
        textAlign: 'right',
    },
    footerText: {
        fontSize: '12px',
        color: '#026277',
        margin: "auto"
    },
    documentItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        border: '1px solid #6BBDBD',
        padding: '32px',
        backgroundColor: 'white',
        transition: 'background-color 0.3s',
        marginBottom: '20px',
        '&:hover': {
            backgroundColor: '#F5F5F5',
        },
        width: '100%',
    },
    progressContainer: {
        width: '80px',
        height: '80px',
        position: 'relative',
        marginRight: '20px',
        flexShrink: 0,
    },
    progressText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        backgroundColor: 'white',
        color: '#6BBDBD',
        fontWeight: 'bold',
        fontSize: '16px',
    },
    documentInfo: {
        flexGrow: 1,
        textAlign: 'left',
        marginRight: '20px',
    },
    documentTitle: {
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#333',
        marginBottom: '8px',
    },
    documentDescription: {
        fontSize: '14px',
        color: '#666',
    },
    actionButtons: {
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
    },
    button: {
        textTransform: 'none',
        fontWeight: '600',
        fontFamily: "'Inter', sans-serif",
        borderRadius: '24px',
        padding: '8px 24px',
        fontSize: '14px',
        boxShadow: 'none',
    },
    viewButton: {
        backgroundColor: '#6BBDBD',
        color: 'white',
        '&:hover': {
            backgroundColor: '#6BBDBD',
        },
    },
    learnButton: {
        backgroundColor: 'white',
        color: '#6BBDBD',
        border: '1px solid #6BBDBD',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
}));

const DocumentsGrid = ({ folder_id, document_id, element, token, user, updateListDocuments }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [isEditing, setIsEditing] = useState(false);
    const [editableTitle, setEditableTitle] = useState("");

    const { t } = useTranslation();
    const itemColors = [
        { ReactComponent: GreenCard, color: '#00B564', label: 'Preguntas Memorizadas' },
        { ReactComponent: YellowCard, color: '#FFA726', label: 'Preguntas Aprendiendo' },
        { ReactComponent: RedCard, color: '#AA161D', label: 'Preguntas Incorrectas' },
        { ReactComponent: GrayCard, color: '#BDBDBD', label: 'Preguntas Sin Empezar' }
    ];

    const getMemorizedChaptersText = (document) => {
        const memorizedChapters = document.main_topics.filter(topic =>
            (topic.points / topic.TotalPossiblePoints * 100) >= 80
        ).length;
        const totalChapters = document.main_topics.length;
        const totalPoints = document.main_topics.reduce((sum, topic) => sum + topic.points, 0);
        const totalMaxPoints = document.main_topics.reduce((sum, topic) => sum + topic.TotalPossiblePoints, 0);
        return `${totalPoints} de ${totalMaxPoints} puntos de memoria🧠`;
    };

    const getProgress = (document) => {
        const totalPoints = document.main_topics.reduce((sum, topic) => sum + topic.points, 0);
        const totalMaxPoints = document.main_topics.reduce((sum, topic) => sum + topic.TotalPossiblePoints, 0);
        const percentageMemorized = Math.max(0, (totalPoints / totalMaxPoints) * 100);
        return percentageMemorized.toFixed(0);
    };

    const getProgressTopic = (document) => {
        return document.main_topics.reduce((acc, topic) => {
            acc.learning += topic.status.learning;
            acc.memorized += topic.status.memorized;
            acc.notKnown += topic.status.notKnown;
            acc.notStarted += topic.status.notStarted;
            return acc;
        }, { learning: 0, memorized: 0, notKnown: 0, notStarted: 0 });
    };


    const handleEditClick = (event) => {
        event.stopPropagation();
        setIsEditing(true);

    };

    useEffect(() => {
        setEditableTitle(element.title)
    }, [element])
    const handleTitleBlur = async () => {
        setIsEditing(false);
        try {
            // Fetch folder name based on the first path segment (folder ID)
            await fetchUserFile(token, "PUT", folder_id, document_id, { "docName": editableTitle }, user);

        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteDocument = async () => {
        try {
            await handleDoc.deleteDocument(token, document_id, folder_id, user);
            updateListDocuments(document_id);
        } catch (error) {
            console.error(error);
        }
        setShowDeleteDoc(false);
        handleCloseMenu();
    }

    const handleTitleChange = (event) => {
        setEditableTitle(event.target.value);
    };

    const handleClick = (doc_id) => {

        const currentPath = location.pathname;
        const newSegment = doc_id;
        const newPath = `${currentPath}/${newSegment}`;
        navigate(newPath);
    };
    // try {
    //     // Fetch folder name based on the first path segment (folder ID)
    //     await fetchUserFile(token, "PUT", folder_id, document_id, { "docName": newName }, user);

    // } catch (error) {
    //     console.error(error);
    // }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickMenu = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);

    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const truncatedTitle = editableTitle.length > 20 ? `${editableTitle.substring(0, 20)}...` : editableTitle;
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    const [showDeleteDoc, setShowDeleteDoc] = useState(false);
    return (
        <>

            <DeleteDoc
                open={showDeleteDoc}
                onClose={() => setShowDeleteDoc(false)}
                onDelete={handleDeleteDocument}
                documentName={element.title}
            />

            <Box className={classes.documentItem} onClick={() => handleClick(element.guid)}>
                <Box className={classes.progressContainer}>
                    <CircularProgress
                        variant="determinate"
                        value={element.progress || 0}
                        size={80}
                        thickness={4}
                        sx={{
                            color: '#6BBDBD',
                            '& .MuiCircularProgress-circle': {
                                strokeLinecap: 'round',
                            },
                            '& .MuiCircularProgress-circleBackground': {
                                stroke: '#E2F1F1',
                            },
                        }}
                    />
                    <Box className={classes.progressText}>
                        {element.progress || 0}%
                    </Box>
                </Box>
                <Box className={classes.documentInfo}>
                    {isEditing ? (
                        <TextField
                            value={editableTitle}
                            onChange={handleTitleChange}
                            variant="standard"
                            className={classes.documentTitle}
                            onClick={(event) => event.stopPropagation()}
                            onBlur={handleTitleBlur}
                            autoFocus
                            InputProps={{
                                classes: {
                                    maxLength: 20,
                                    input: classes.titleInput,
                                },
                            }}
                        />
                    ) : (
                        <Box display="flex" alignItems="center" >
                            <Typography className={classes.documentTitle}>
                                {truncatedTitle}
                            </Typography>
                            <EditIcon
                                onClick={handleEditClick}
                                style={{ marginLeft: '8px', marginBottom: '8px', cursor: 'pointer', fontSize: '16px', color: '#C2C2C2' }}
                            />
                        </Box>
                    )}
                    <Typography className={classes.documentDescription}>
                        {element.chaptersMemorized} de {element.totalChapters} {t("capítulos memorizados")}
                    </Typography>
                </Box>
                <Box className={classes.actionButtons}>
                    <Button
                        variant="contained"
                        className={`${classes.button} ${classes.viewButton}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClick(element.guid);
                        }}
                    >
                        Ver
                    </Button>
                    <Button
                        variant="contained"
                        className={`${classes.button} ${classes.learnButton}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/new-exam?document=${element.guid}`);
                        }}
                    >
                        Aprender
                    </Button>
                    <MoreVertIcon
                        onClick={handleClickMenu}
                        className={classes.iconButton}
                        style={{ color: "#026277" }}
                    />
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={(event) => {
                            event.stopPropagation();
                            setShowDeleteDoc(true);
                            handleCloseMenu();
                        }}>Delete</MenuItem>
                    </Menu>
                </Box>
            </Box>
        </>
    );
};

export default DocumentsGrid;
