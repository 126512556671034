import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, AreaChart, Area } from 'recharts';
import { Typography, Box, Grid, Button, Container, Paper, Tooltip as MuiTooltip } from '@mui/material';
import Vacio from '../Images/Vacio.svg';
import Lock from '../Images/Lock.svg'; // <-- Added Import
import greenCard from '../Images/greenCard.svg';
import yellowCard from '../Images/yellowCard.svg';
import redCard from '../Images/redCard.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const COLORS = ['#D9F9D4', '#F49296', '#FFECCF', '#E4E4E4', '#4A90E2'];
const LABELS = ['Memorizadas', 'Incorrectas', 'Aprendiendo', 'Sin Responder', 'Bloqueadas'];

const ProgresoLateral = ({ pieData, examsList }) => {
  const { document_id, selectedDocument } = useParams();
  const navigate = useNavigate();
  const handleStudyClick = () => {
    navigate(`/new-exam?document=${document_id}`);
  };

  const getPointsExamData = () => {
    const graphicData = examsList
      .filter(exam => exam.is_finished === true)
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((exam, index) => {

        return {
          name: exam.title,
          points: (exam.correct_answers * 5) - (exam.incorrect_answers * 3),
          date: exam.date
        };
      });
    return graphicData;
  }

  const isNarrowScreen = useMediaQuery('(max-width:1000px)');

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginTop: '10px' }}>
          <Typography variant="h5" component="h3" style={{ fontSize: '18px', fontWeight: 'bold', fontFamily: "Inter" }} gutterBottom>
            Distribución de Preguntas 📝
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="center" direction={isNarrowScreen ? 'column' : 'row'}>
            {LABELS.slice(0, 4).map((label, index) => (
              <Grid item xs={isNarrowScreen ? 12 : 6} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    backgroundColor: COLORS[index],
                    padding: '10px',
                    textAlign: 'center',
                    borderRadius: '8px',
                    border: '2px solid',
                    borderColor: index === 0 ? '#00B564' :
                      index === 1 ? '#AA161D' :
                        index === 2 ? '#FF9800' :
                          '#9E9E9E',
                    position: 'relative',
                    width: isNarrowScreen ? '100%' : 'auto',
                  }}
                >
                  {index === 2 && (
                    <MuiTooltip title="Preguntas que has respondido algunas veces bien y otras mal">
                      <HelpOutlineIcon
                        sx={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          fontSize: '16px',
                          color: '#FF9800',
                        }}
                      />
                    </MuiTooltip>
                  )}
                  <Typography variant="h6" style={{ fontSize: '24px', fontWeight: 'bold', fontFamily: 'Inter', color: index === 0 ? '#00B564' : index === 1 ? '#AA161D' : index === 2 ? '#FF9800' : '#9E9E9E' }}>
                    {pieData[index] ? pieData[index].value : 0}
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Inter', color: index === 0 ? '#00B564' : index === 1 ? '#AA161D' : index === 2 ? '#FF9800' : '#9E9E9E' }}>
                    {label}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
          {/* Conditionally render the message for "Bloqueadas" */}
          {pieData[4] && pieData[4].value > 0 && (
            <Box mt={2} display="flex" justifyContent="flex-start" alignItems="center">
              <img src={Lock} alt="Lock" style={{ marginRight: '8px', width: '20px', height: '20px' }} />
              <Typography variant="body1" style={{ fontWeight: '600', fontFamily: 'Inter', color: '#9E9E9E' }}>
                Hay {pieData[4].value} preguntas bloqueadas
              </Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} style={{ marginTop: '10px' }}>
          <Typography variant="h5" component="h3" style={{ fontSize: '18px', fontWeight: 'bold', fontFamily: "Inter" }} gutterBottom>
            Evolución de puntos 🧠
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer width="100%" height={300}>
            {getPointsExamData().length === 0 || pieData.length === 0 ? (
              pieData.length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  <CircularProgress />
                </Box>
              ) : (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                  <img src={Vacio} alt="No hay datos" style={{ maxWidth: '50%', height: 'auto' }} />
                  <Typography variant="body1" style={{ marginTop: '10px' }}>Aún no has hecho ningún test</Typography>
                  <Button
                    onClick={handleStudyClick}
                    style={{
                      backgroundColor: '#6BBDBD',
                      color: 'white',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '16px',
                      fontWeight: 600,
                      textTransform: 'none',
                      marginTop: '10px',
                      borderRadius: '20px',
                      padding: '6px 16px',
                      '&:hover': {
                        backgroundColor: '#5AACAC', // Slightly darker shade for hover effect
                      },
                    }}
                  >
                    Estudiar
                  </Button>
                </Box>
              )
            ) : (
              <AreaChart
                data={getPointsExamData()}
                margin={{
                  top: 10, right: 30, left: 0, bottom: 0,
                }}
              >
                <YAxis
                  domain={[0, pieData.reduce((sum, item) => sum + item.value, 0) * 5]}
                  ticks={[0, Math.round(pieData.reduce((sum, item) => sum + item.value, 0) * 5 * 0.4), Math.round(pieData.reduce((sum, item) => sum + item.value, 0) * 5 * 0.8), pieData.reduce((sum, item) => sum + item.value, 0) * 5]}
                  tickFormatter={(value, index, ticks) => {
                    const totalSum = pieData.reduce((sum, item) => sum + item.value, 0) * 5;
                    const fortyPercent = Math.round(totalSum * 0.4);
                    const eightyPercent = Math.round(totalSum * 0.8);

                    if (index === 0) return '0';
                    if (value === fortyPercent) return fortyPercent;
                    if (value === eightyPercent) return '🏆' + eightyPercent;
                    if (value === totalSum) return totalSum;
                    return value; // Return the actual value for any other tick
                  }}
                />
                <XAxis tick={false} />
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}>
                          <p>{`Points: ${payload[0].value}`}</p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                <Area type="monotone" dataKey="points" stroke="#6BBDBD" fill="#6BBDBD" />
              </AreaChart>
            )}
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '10px' }}>

          <Typography variant="h5" component="h3" style={{ fontSize: '18px', fontWeight: 'bold', fontFamily: "Inter" }}>
            Tus exámenes 📚
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className="custom-scrollbar" sx={{ height: '160px', overflowY: 'auto' }}>
            {examsList.length > 0 ? (
              <Grid container spacing={2}>
                {examsList.map((exam, index) => (
                  <Grid item xs={12} key={index}>
                    <Box
                      display="flex"
                      alignItems="center"
                      onClick={() => navigate(`/exam/${exam.guid}`)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Grid container alignItems="center" style={{ width: '100%' }}>
                        <Grid item xs={4} style={{ maxWidth: '28vw', overflow: 'hidden' }}>
                          <MuiTooltip title={exam.title}>
                            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Inter', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                              {/* {exam.title.length > 30 ? `${exam.title.substring(0, 30)}...` : exam.title} */}
                              {exam.title}
                            </Typography>
                          </MuiTooltip>
                        </Grid>
                        <Grid item xs={4}>
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <Box display="flex" alignItems="center" mr={2}>
                              <Typography style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Inter', marginRight: '5px', color: '#00B564' }}>{exam.correct_answers}</Typography>
                              <img src={greenCard} alt="Correct" style={{ width: '20px', height: '20px' }} />
                            </Box>
                            <Box display="flex" alignItems="center" ml={2}>
                              <Typography style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Inter', marginRight: '5px', color: '#AA161D' }}>{exam.incorrect_answers}</Typography>
                              <img src={redCard} alt="Incorrect" style={{ width: '20px', height: '20px' }} />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Inter', textAlign: 'right' }}>
                            {exam.is_finished ? `${exam.score} / 100` : "Sin responder"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Button
                  onClick={handleStudyClick}
                  style={{
                    backgroundColor: '#6BBDBD',
                    color: 'white',
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '16px',
                    fontWeight: 600,
                    textTransform: 'none',
                    borderRadius: '20px',
                    padding: '8px 36px',
                    '&:hover': {
                      backgroundColor: '#5AACAC',
                    },
                  }}
                >
                  Hacer Examen
                </Button>
              </Box>
            )}
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
}

export default ProgresoLateral;