import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Button, IconButton, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as TickIcon } from '../Images/tick.svg';
import { ReactComponent as DocsIcon } from '../Images/DOCS.svg';
import { ReactComponent as CreditosLimiteIcon } from '../Images/CreditosLimite.svg';
import { ReactComponent as Preguntas } from '../Images/Preguntas.svg';

const useStyles = makeStyles((theme) => ({
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Light semi-transparent background

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1300, // Ensure it's above other content
  },
  modalContent: {
    backgroundColor: 'white',
    padding: theme.spacing(3),
    borderRadius: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '750px',
    maxHeight: '90%',
    overflow: 'auto',
  },
  onboardingOverlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  onboardingContainer: {
    width: '800px',
    padding: '24px',
    backgroundColor: 'white',
    borderRadius: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  onboardingTitle: {
    fontWeight: 'bold',
    fontSize: '22px',
  },
  onboardingList: {
    paddingLeft: 0,
    width: '100%',
  },
  onboardingListItem: {
    paddingLeft: 0,
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  onboardingListItemMain: {
    paddingBottom: '8px',
  },
  onboardingListItemText: {
    '& .MuiTypography-body1': {
      fontSize: '16px',
      lineHeight: 1.6,
    },
  },
  imageBox: {
    width: '100%',
    height: '300px',
    borderRadius: '8px',
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '10px',
  },
  backButton: {
    color: '#6BBDBD',
    borderColor: '#6BBDBD',
    fontSize: '15px',
    backgroundColor: 'transparent',
    fontWeight: 600,
    padding: '8px 20px',
    borderRadius: '20px',
    textTransform: 'none',
    cursor: 'pointer !important',
    '&:hover': {
      cursor: 'pointer !important',

    },
  },
  nextButton: {
    backgroundColor: '#6BBDBD',
    color: 'white',
    fontSize: '15px',
    fontWeight: 600,
    padding: '8px 20px',
    borderRadius: '20px',
    textTransform: 'none',
    boxShadow: 'none',
    cursor: 'pointer !important',
    '&:hover': {
      cursor: 'pointer !important',
      backgroundColor: '#5AACAC', // Slightly darker shade on hover

    },
  },
  tickIcon: {
    marginRight: "10px",
    flexShrink: 0,
  },
}));

const Onboarding = ({ onClose }) => {
  const classes = useStyles();
  const [step, setStep] = useState(1);

  const contentStep1 = [
    "En Typed puedes personalizar tu estudio al organizar y gestionar los documentos que más te interesan.",
    "Puedes subir artículos, leyes del BOE, PDFs y documentos escaneados.",
    "Nos adaptamos a tu temario sin dificultades."  
      
  ];

  const contentStep2 = [
    "Haz tests cada día para memorizar más rápido.",
    "Estudia por temas y mejora tus puntos débiles.",
    "Ahorra horas con resúmenes hechos por nuestra IA.",
    "Pregunta a nuestra IA y resuelve tus dudas.",
  ];

  const contentStep3 = [
    "Gana puntos y consigue memorizar el temario de una forma más dinámica.",
    "Revisa las preguntas que más te han costado y asegúrate de que las tienes claras.",
    "Mantén tu motivación viendo tu progreso y las estadísticas.",
  ];

  const handleNext = () => {
    if (step < 3) {
      setStep(step + 1);
    } else {
      onClose();
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const getStepContent = () => {
    switch (step) {
      case 1:
        return { 
          title: "📚 Sube el material que necesites estudiar", 
          content: contentStep1,
          image: <DocsIcon width="100%" height="100%" />
        };
      case 2:
        return { 
          title: "📝 Tests, resúmenes y resuelve tus dudas", 
          content: contentStep2,
          image: <Preguntas width="100%" height="100%" />
        };
      case 3:
        return { 
          title: "📈 Mide tu progreso y mejora cada día", 
          content: contentStep3,
          image: <CreditosLimiteIcon width="100%" height="100%" />
          
        };
      default:
        return { title: "", content: [], image: null };
    }
  };

  const { title, content, image } = getStepContent();

  return (
    <div className={classes.modalOverlay}>
      <Box className={classes.modalContent}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography className={classes.onboardingTitle}>
            ⚡ Sácale el máximo partido a Typed en tan solo 3 pasos
          </Typography>
          <IconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={7}>
            <List className={classes.onboardingList}>
              <ListItem className={`${classes.onboardingListItem} ${classes.onboardingListItemMain}`}>
                <ListItemText
                  primary={
                    <Typography variant="body1" style={{ fontWeight: 600, fontSize: '18px' }}>
                      {title}
                    </Typography>
                  }
                  className={classes.onboardingListItemText}
                />
              </ListItem>
              {content.map((text, index) => (
                <ListItem key={index} className={classes.onboardingListItem}>
                  <TickIcon className={classes.tickIcon} />
                  <ListItemText
                    primary={text}
                    className={classes.onboardingListItemText}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'top' }}>
            <Box className={classes.imageBox}>
              {image}
            </Box>
            <div className={classes.buttonContainer}>
              <Button
                variant="outlined"
                className={classes.backButton}
                onClick={handleBack}
                disabled={step === 1}
              >
                Atrás
              </Button>
              <Button
                // variant="contained"
                // disableElevation
                onClick={step === 3 ? onClose : handleNext}
                className={classes.nextButton}
                style={{ cursor: 'pointer !important' }}
              >
                {step === 3 ? "Ir a Typed" : "Siguiente"}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Onboarding;
