import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import QuillEditor from "../NewComponents/TextEditor";
import useChapterStyles from "./Styles/useChapterStyles";
import { getCsrfToken } from '../Api/TokenApi';
import { fetchGenerateSummary } from "../Api/FilesApi";
import { WebSocketContext } from "../WebSocketProvider";
import { useCredits } from "../Context/CreditsContext";
import NeedCredits from "../NoCreditsModal/NeedCredits";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const PAGE_CREDIT_PRICE = 0.375
function Summary({ docInfo, setSummary, accessToken, user, summary, isGeneratingSummary, setIsGeneratingSummary }) {
    const { document_id, selectedTranscript } = useParams();
    const { folder_id, selectedFolder } = useParams();
    const { t } = useTranslation();
    const classes = useChapterStyles();
    const { messageCallback } = useContext(WebSocketContext);

    const [editorValue, setEditorValue] = useState('');
    const { updateCredits, credits } = useCredits();

    const [creditsNeeded, setCreditsNeeded] = useState(0);
    const [generatingSummary, setGeneratingSummary] = useState('');
    const [currentLoadingPage, setCurrentLoadingPage] = useState('0');
    const [showUpgrade, setShowUpgrade] = useState(false)
    useEffect(() => {
        if (messageCallback) {

            switch (messageCallback.type) {

                case "PageSummary":
                    setCurrentLoadingPage(messageCallback.page);
            }

        }
    }, [messageCallback]);

    useEffect(() => {
        if (summary) {
            setEditorValue(summary);
        }
    }, [summary, document_id, folder_id]);
    useEffect(() => {
        if (docInfo) {
            let _creditsPage = Math.max(1, Math.round(docInfo.info_doc.pages * PAGE_CREDIT_PRICE));

            setCreditsNeeded(_creditsPage)
        }
    }, [document_id, docInfo])
    useEffect(() => {
        const adjustEditorHeight = () => {
            const editorElement = document.getElementById('editor-container');
            if (editorElement) {
                const viewportHeight = window.innerHeight;
                const editorOffsetTop = editorElement.getBoundingClientRect().top;
                const distanceToBottom = 20;
                const editorHeight = viewportHeight - editorOffsetTop - distanceToBottom;

                editorElement.style.height = `${editorHeight}px`;
            }
        };

        adjustEditorHeight();
        window.addEventListener('resize', adjustEditorHeight);

        return () => window.removeEventListener('resize', adjustEditorHeight);
    }, []);

    const saveData = async (content) => {
        try {
            const csrfToken = getCsrfToken();
            const response = await fetch(`${BACKEND_URL}/api_v1/file/${folder_id}/${document_id}/?user_id=${user['sub']}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({ "summary": content }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const jsonResponse = await response.json();
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const debounceSaveData = useCallback(debounce((content) => {
        if (content) {
            saveData(content);
        }
    }, 2000), []);


    const handleEditorChange = (value) => {
        debounceSaveData(value);
        setEditorValue(value);
        setSummary(value)
    };
    const handleGenerateSummary = async () => {
        if (credits >= creditsNeeded) {


            setIsGeneratingSummary(true)
            await fetchGenerateSummary(accessToken, "POST", document_id, null, user)
            updateCredits()
        } else {
            console.log("HELLO")

            setShowUpgrade(true)
        }
    };
    return (<>
        {
            showUpgrade && <NeedCredits initialOpen={true}
                onClose={() => setShowUpgrade(false)} />
        }
        <Grid
            container
            style={{ display: "flex", flexDirection: "column", height: "79vh", width: "100%", overflowY: "scroll" }}
        >
            <Grid container className={classes.grid} style={{ flex: 1 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {!summary || summary === null ? (
                        <div style={{ textAlign: 'center', marginTop: "50px" }}>
                            <img src={require("./img/generatingSummary.svg").default} />
                            {isGeneratingSummary ? (<>
                                <Typography style={{ fontSize: "12px", color: "gray" }}>{t("summary_ready")}</Typography>
                                <Typography style={{ fontSize: "12px", color: "gray", paddingBottom: "15px" }}>
                                    Páginas resumidas: {currentLoadingPage}
                                </Typography>
                                <CircularProgress style={{ color: "#026277" }} />
                            </>
                            ) : (
                                <>
                                    <Button className={classes.generateSummary} onClick={handleGenerateSummary}>{t("generate_summary")}</Button>
                                    {docInfo && <Typography style={{ fontSize: "12px", color: "gray", marginTop: "10px" }}>
                                        {t("cost_action_credits", { number_credits: creditsNeeded })}
                                    </Typography>}
                                </>

                            )}
                        </div>
                    ) : (
                        <div id="editor-container" style={{ height: '100%' }} className="notranslate">
                            <QuillEditor
                                value={editorValue}
                                onChange={handleEditorChange}
                                theme="snow"

                            />
                        </div>
                    )}
                </Grid>
            </Grid>
        </Grid>
    </>
    );
}

export default Summary;
