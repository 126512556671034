import React, { useState, useEffect } from "react";
import { Button, TextField, Typography, Box, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import DragAndDrop from "../../../NewComponents/Upload/UploadFileFolder";
import ShowOptionsInline from "../../../Welcome/ShowOptionsInline";
import ShowFolders from "../../../NewComponents/Upload/ShowFolders";
import { fetchUserFolder, fetchUserFolders } from '../../../Api/FolderApi';
import { fetchUserFiles } from '../../../Api/FilesApi';
import { fetchFolderExams } from "../../../Api/ExamsApi";
import { ClipLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useCredits } from "../../../Context/CreditsContext";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        marginTop: "12px",
    },
    formControl: {
        minWidth: 120,
        width: '90%',
        marginBottom: "0px",
        marginTop: "10px",
    },
    title: {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '26px'
    },
    menuItem: {
        fontSize: '10px',
        borderRadius: '8px',

    },
    select: {
        height: '30px', // Set the desired height for the Select component
        '& .MuiSelect-selectMenu': {
            fontSize: "10px"
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
        },
    },
    inputLabel: {
        fontSize: '10px', // Set the font size for the label
        transform: 'translate(10px, 10px) scale(1)', // Adjust the position of the label
    },
    shrinkLabel: {
        transform: 'translate(10px, -6px) scale(0.75)', // Position of the label when shrunk
    },
    buttonUpload: {
        backgroundColor: "#026277",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "10px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "30px",

        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#026277', // Gray color for the disabled state
            color: "white",


        }
    },
}));

function ModalLayoutExam2({ onClose, accessToken, user, onExamCreated, updateListDocuments, setCredits, setIsPreparingExam,
    currentCredits, setShowUpgrade, setShowAlert, setMessage, setProgressBar, setFolderSelectedGuid, setDocumentSelectedGuid }) {
    const { folder_id, selectedFolderParam } = useParams();
    const { document_id, selectedDocumentParam } = useParams();
    const [step, setStep] = useState(1);
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [selectedOption, setSelectedOption] = React.useState('');
    const [firstOption, setFirstOption] = React.useState('');
    const [newFolderName, setNewFolderName] = useState("");
    const [typeExamOption, setTypeExamOption] = useState('');
    const [difficultyExamOption, setDifficultyExamOption] = useState('difficult');
    const [selectedFolder, setSelectedFolder] = useState(folder_id || "");
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [requiredCredits, setRequiredCredits] = useState(40); // State to store required credits
    const { credits } = useCredits()
    const [initPage, setInitPage] = useState(1); // State to store required credits
    const [finalPage, setFinalPage] = useState(20); // State to store required credits
    const [errorPages, setErrorPages] = useState(false); // State to store required credits
    const [errorMessagePages, setErrorMessagePages] = useState(false); // State to store required credits

    const [pages, setPages] = useState('')
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        // let creditsNeeded = Math.max(1, Math.round(pages * 0.375));
        let creditsNeeded = 40 // Credits needede without counting pages

        setCredits(creditsNeeded);
        setFinalPage(pages)

    }, [pages]);

    // useEffect(() => {


    //     let pagesTotal = finalPage - initPage;
    //     // let creditsNeeded = Math.max(1, Math.round(pagesTotal * 0.375));
    //     let creditsNeeded = 40 // Credits needede without counting pages

    //     setRequiredCredits(creditsNeeded); // Set required credits

    //     setCredits(creditsNeeded);



    // }, [finalPage, initPage]);

    const handleSetValue = (value) => {
        setSelectedFolder(value);
        // Perform action based on selected folder
    };
    const handleKeyPress = (event) => {
        // Allow only numbers
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    };

    const handleStartPageChange = (event) => {
        setInitPage(event.target.value);
        validatePages(event.target.value, finalPage);
    };

    const handleEndPageChange = (event) => {
        setFinalPage(event.target.value);
        validatePages(initPage, event.target.value);
    };
    const validatePages = (start, end) => {
        const startPageNum = parseInt(start, 10);
        const endPageNum = parseInt(end, 10);
        const totalPages = parseInt(pages, 10);

        if (isNaN(startPageNum) || isNaN(endPageNum)) {
            setErrorPages(true);
            setErrorMessagePages("Los números de página deben ser números válidos.");
        } else if (startPageNum === 0) {
            setErrorPages(true);
            setErrorMessagePages("El número de la página de inicio no puede ser 0.");
        } else if (startPageNum > endPageNum) {
            setErrorPages(true);
            setErrorMessagePages("El número de la página de inicio debe ser menor que el número de la página final.");
        } else if (endPageNum > totalPages) {
            setFinalPage(totalPages); // Adjust final page to total pages
            setErrorPages(false);
            setErrorMessagePages("");
        } else {
            setErrorPages(false);
            setErrorMessagePages("");
        }
    };
    const handleNewFolder = (folderName) => {
        setNewFolderName(folderName);
        // Perform action based on new folder name
    };

    const handleFirstChange = (event) => {
        setFirstOption(event.target.value);
    };
    const handleOptionFormatChange = (optionValue) => {
        setTypeExamOption(optionValue);
    };
    const handleOptionDifficultyChange = (optionValue) => {
        setDifficultyExamOption(optionValue);
    };
    const handleNext = async () => {
        if (credits >= requiredCredits) {

            if (folder_id) {
                await handleEnd();
                setStep(step + 1);
            } else {
                setStep(step + 1);
            }
        } else {
            setShowUpgrade(true)
            onClose();
        }
    };
    const handlePrevious = () => {
        setStep(step - 1);
    };
    const handleOptionChange = (optionValue) => {
        setSelectedOption(optionValue);
    };
    const options = [
        { value: '10', label: `10 ${t("preguntas")}` },
        { value: '15', label: `15 ${t("preguntas")}` },
        { value: '20', label: `20 ${t("preguntas")}` },
        // Add more options as needed
    ];
    async function handleFiles(accessToken, folderId, formDataDocument, user, formDataExam) {
        try {
            // Fetch user files and wait for the result
            const data = await fetchUserFiles(accessToken, "POST", folderId, formDataDocument, user);

            if (updateListDocuments) {
                updateListDocuments();
            }

            // Get the key from the data
            const key = Object.keys(data['message'])[0];

            if (key) {
                setShowAlert(false)
                setProgressBar(false);
                // Get the document ID
                const documentId = data['message'][key][0];
                const folder_id_return = data['folder']['folder_id'];
                setIsPreparingExam(true)


                formDataExam.append('type[Document]', documentId);
                formDataExam.append('type[Folder]', folder_id_return);
                // Fetch folder exams and wait for the result

                // Navigate to the route using `folder_id_return`
                navigate(`/${folder_id_return}/${documentId}`); // Replace '/somepath/' with your actual path


                const uploadExam = await fetchFolderExams(accessToken, "POST", documentId, user, formDataExam);
                if (onExamCreated) {
                    onExamCreated(); // Invoke the callback to refresh the list of exams
                }
                // You can handle the result of `uploadExam` here if needed
            } else {
                console.error('No key found in the message.');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }


    const handleEnd = async () => {
        // Filter subjects to only include those with non-empty names
        if (credits >= requiredCredits) {

            // setIsLoading(true); // Start loading

            const executeAsyncOperations = async () => {
                try {
                    setShowAlert(true)
                    setProgressBar(true);
                    setMessage(t("uploading_file"))
                    // setIsPreparingExam(true);
                    // setIsLoading(false); // Start loading

                    const formDataDocument = new FormData();
                    let filesToUpload;

                    if (files && files.length > 0) {
                        filesToUpload = files.map((fileItem) => fileItem.file); // Extracting File objects

                        // Function to load metadata for a single file and append to FormData
                        const loadMetadata = (file, index) => {
                            return new Promise((resolve) => {
                                formDataDocument.append(`file_${index}`, file);
                                formDataDocument.append(`file_${index}_size`, file.size);
                                formDataDocument.append(`file_${index}_duration`, 0);
                                resolve();
                            });
                        };



                        // Wait for all files to have their metadata loaded
                        const metadataPromises = filesToUpload.map((file, index) => loadMetadata(file, index));
                        await Promise.all(metadataPromises);
                    }

                    let folderId;
                    let redirectId;

                    if (!folder_id) {
                        folderId = selectedFolder ? selectedFolder.guid : newFolderName;
                        redirectId = selectedFolder ? selectedFolder.guid : "";
                    } else {
                        folderId = folder_id;
                        redirectId = folder_id;
                    }
                    const formDataExam = new FormData()

                    formDataExam.append('type[difficulty]', "difficult");
                    formDataExam.append('type[type_exam]', typeExamOption);
                    formDataExam.append('number_questions', selectedOption);
                    formDataExam.append('init_page', initPage);
                    formDataExam.append('final_page', finalPage);


                    // Call the function with appropriate parameters
                    handleFiles(accessToken, folderId, formDataDocument, user, formDataExam);






                    // setIsLoading(false); // Stop loading
                } catch (error) {
                    console.error('Error fetching folders:', error);
                }
            };

            // Close the modal first
            onClose();

            // Execute the rest of the operations
            executeAsyncOperations();
        } else {
            setShowUpgrade(true)
            onClose();
        }
    };

    const optionsDificulty = [
        { value: 'easy', label: `${t("dificultad_facil")}` },
        { value: 'medium', label: `${t("dificultad_media")}` },
        { value: 'hard', label: `${t("dificultad_dificil")}` },
    ]

    const optionsFormat = [
        { value: 'bullet_points', label: `${t("multiple_choice")}` },
        { value: 'open_question', label: `${t("open_question")}` },
    ]


    return (
        <>
            {step === 1 && (
                <div>
                    <Typography className={classes.title} style={{ marginBottom: "10px", marginTop: "10px" }}>{t("load_your_notes_desc")}</Typography>
                    <DragAndDrop files={files} setFiles={setFiles} is_multiple={false} limit={1} setPages={setPages} />
                    <div style={{ marginTop: "0px" }}>
                        <Typography className={classes.title}>{t("number_questions")}</Typography>
                        <div style={{ marginTop: "12px" }}>
                            <ShowOptionsInline
                                options={options}
                                value={selectedOption}
                                handleChange={handleOptionChange} // This now expects a value directly
                            />
                        </div>
                        {/* <Typography className={classes.title} style={{ marginTop: "5px" }}>{t("choose_difficulty")}</Typography>

                        <div style={{ marginTop: "12px" }}>
                            <ShowOptionsInline
                                options={optionsDificulty}
                                value={difficultyExamOption}
                                handleChange={handleOptionDifficultyChange}
                            />
                        </div> */}
                        <Typography className={classes.title} style={{ marginTop: "5px" }}>{t("format_exam")}</Typography>
                        <div style={{ marginTop: "12px" }}>
                            <ShowOptionsInline
                                options={optionsFormat}
                                value={typeExamOption}
                                handleChange={handleOptionFormatChange}
                            />
                        </div>
                    </div>
                    <Typography style={{ textAlign: "left", fontSize: 12, fontWeight: "bold", marginTop: "15px" }} >{t("choose_study")}</Typography>

                    <Box display="flex" flexDirection="row" style={{ gap: "20px", marginTop: "10px" }} >
                        <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
                            <Typography style={{ textAlign: "left", fontSize: 12 }}>{t("initial_page")}</Typography>
                            <TextField

                                value={initPage}
                                onChange={handleStartPageChange}
                                onKeyPress={handleKeyPress}
                                error={errorPages}

                                // helperText={error ? 'El rango no puede ser mayor a 20 páginas' : ''}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { padding: '5px', width: '30px', fontSize: 12 } }}
                                variant="outlined"
                                size="small"
                                style={{ marginTop: '5px' }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
                            <Typography style={{ textAlign: "left", fontSize: 12 }}>{t("final_page")}</Typography>

                            <TextField
                                value={finalPage}
                                onChange={handleEndPageChange}
                                onKeyPress={handleKeyPress}
                                error={errorPages}

                                // helperText={error ? 'El rango no puede ser mayor a 20 páginas' : ''}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { padding: '5px', width: '30px', fontSize: 12 } }}
                                variant="outlined"
                                size="small"
                                style={{ marginTop: '5px' }}
                            />
                        </div>
                    </Box>
                    {errorPages && (<Typography style={{ color: "red", fontSize: 12 }}>{errorMessagePages}</Typography>)}

                    <Box display="flex" justifyContent="flex-end" marginTop="10px">
                        <Button disabled={
                            selectedOption == "" || files.length == 0 || typeExamOption === ""} className={classes.buttonUpload} onClick={handleNext}>
                            {folder_id ? t("!estudiar!") : t("next")}
                        </Button>
                    </Box>
                </div>
            )}
            {step === 2 && !folder_id && (
                <div>
                    {/* <Typography className={classes.title}>¿En qué asignatura los guardamos?</Typography> */}
                    <div style={{ marginTop: "14px" }}>

                        <FormControl required error={error}>
                            {/* Call api to show list of folders */}

                            <ShowFolders
                                value={selectedFolder}
                                setValue={handleSetValue}
                                handleNewFolder={handleNewFolder}
                                margin="dense"
                            />
                        </FormControl>


                    </div>

                    <Box display="flex" justifyContent="flex-end" marginTop="10px">
                        <Button disabled={isLoading || (selectedFolder == "" && newFolderName == "")} className={classes.buttonUpload} onClick={handleEnd}>{isLoading ? <ClipLoader size={12} color={"#026277"} /> : t("!estudiar!")}</Button>
                    </Box>
                </div >
            )
            }
        </>
    );
}

export default ModalLayoutExam2;
