import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, Checkbox, IconButton } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import useStylesGettingStarted from './useStyleGettingStarted';

// Define the styled Checkbox component
const CircleCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  '& .MuiSvgIcon-root': {
    fontSize: 24,
    borderRadius: '50%',
    border: '2px solid #6BBDBD',
    color: 'transparent',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.short,
    }),
  },
  '&.Mui-checked .MuiSvgIcon-root': {
    color: '#6BBDBD',
    border: 'none',
  },
}));

const ActionItem = ({ text, subtext, checkCompletion }) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const classes = useStylesGettingStarted();

  useEffect(() => {
    const checkStatus = async () => {
      const completed = await checkCompletion();
      setIsCompleted(completed);
    };
    checkStatus();
  }, [checkCompletion]);

  return (
    <Paper elevation={0} className={classes.actionItemStarted}>
      <CircleCheckbox
        checked={isCompleted}
        icon={<CircleIcon />}
        checkedIcon={<CheckCircleIcon />}
      />
      <Box className={classes.actionTextStarted}>
        <Typography className={classes.actionTitleStarted}>{text}</Typography>
        <Typography className={classes.actionSubtextStarted}>
          {subtext}
        </Typography>
      </Box>
    </Paper>
  );
};

const GettingStarted = ({ onClose, has_uploaded_file, has_created_summary, has_created_exam }) => {
  const classes = useStylesGettingStarted();
  const [isVisible, setIsVisible] = useState(true);

  // Placeholder functions - replace these with actual checks
  const checkIfDocumentUploaded = async () => has_uploaded_file;
  const checkIfSummaryCreated = async () => has_created_summary;
  const checkIfTestTaken = async () => has_created_exam;

  const actions = [
    { key: 'uploadDocument', text: '📚 Sube un documento', subtext: 'Sube los documentos que quieres aprender', check: checkIfDocumentUploaded },
    { key: 'createSummary', text: '📝 Haz un resumen', subtext: 'Obtén resúmenes completos en segundos', check: checkIfSummaryCreated },
    { key: 'takeTest', text: '👩‍🏫 Aprende', subtext: 'Memoriza más rápido respondiendo tests', check: checkIfTestTaken },
  ];

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) {
      onClose();
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Box className={classes.containerStarted}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" className={classes.titleStarted} fontWeight="bold">
            ⚡ Empezando con Typed
          </Typography>
        </Box>
        <IconButton
          onClick={handleClose}
          aria-label="close"
          sx={{
            padding: 0,
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        {actions.map((item) => (
          <Grid item xs={12} sm={4} key={item.key} style={{ marginTop: '16px' }}>
            <ActionItem
              text={<Typography fontWeight="bold" color="#333">{item.text}</Typography>}
              subtext={item.subtext}
              checkCompletion={item.check}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GettingStarted;
