import React, { useState } from 'react';
import { Box, Typography, Button, Paper, Stack, List, ListItem, ListItemText, ListItemIcon, Grid, Tooltip as ToolTipMaterial } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FireIcon from './static/Fire.svg'; // Adjust the path if necessary
import Vacio from './static/Vacio.svg';
import { BarChart, XAxis, YAxis, Bar, Cell, Tooltip } from 'recharts';
import { useEffect } from 'react';
import { usePoints } from '../Context/PointsContext';
import { useAuth } from '../Auth/Authhandle';
import { fetchFolderExams } from '../Api/ExamsApi';
import { useNavigate } from 'react-router-dom';

const CARD_HEIGHT = 275;
const CARD_STYLE = {
  height: CARD_HEIGHT,
  borderRadius: '20px',
  border: '1px solid #E2F1F1',
  boxShadow: 'none',
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Inter, sans-serif',
  width: '100%', // Add this to ensure full width
};

const StudyStreakCard = ({ days }) => {
  const navigate = useNavigate();

  return (
    <Paper sx={CARD_STYLE}>
      <Typography variant="h6" gutterBottom sx={{ color: '#6BBDBD', fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }}>
        Días seguidos estudiando
      </Typography>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img src={FireIcon} alt="Fire icon" style={{ width: 58, height: 82, marginBottom: '8px' }} />
        <Typography variant="h4" sx={{ fontSize: '30px', fontWeight: 'bold', color: '#6BBDBD' }}>
          {days} {days === 1 ? 'día' : 'días'}
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={() => navigate('/new-exam')}
        style={{
          backgroundColor: '#6BBDBD',
          color: 'white',
          textTransform: 'none',
          width: '80%',
          fontWeight: '600',
          fontFamily: "'Inter', sans-serif",
          borderRadius: '24px',
          padding: '6px 24px',
          fontSize: '16px',
          marginBottom: "5px",
          display: 'block',
          margin: 'auto',
          boxShadow: 'none'
        }}
      >
        Estudiar
      </Button>
    </Paper>
  );
};

const ProductivityChart = ({ allPointsData }) => {
  if (!allPointsData || allPointsData.length === 0) {
    allPointsData = [];
  }
  // Calculate the maximum value for the Y-axis domain
  const maxValue = Math.max(...allPointsData.map(item => item.points));

  return (
    <Paper sx={CARD_STYLE}>
      <Typography variant="h6" gutterBottom sx={{ color: '#6BBDBD', fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }}>
        Tu rendimiento diario
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <BarChart width={300} height={220} data={allPointsData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fill: '#6BBDBD' }} />
          <YAxis hide={true} domain={[0, maxValue]} />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return (
                  <div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}>
                    <p>{`${label} : ${payload[0].payload.points} Preguntas`}</p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Bar dataKey="points" radius={[10, 10, 0, 0]}>
            {allPointsData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={(entry.points < 0 ? '#AA161D' : '#B8E3E3')}
              />
            ))}
          </Bar>
        </BarChart>
      </Box>
    </Paper>
  );
};

const RecentExams = () => {

  const { accessToken, user } = useAuth();
  const [exams, setExams] = useState([]);

  useEffect(() => {

    const fetchExams = async () => {
      try {
        const examsData = await fetchFolderExams(accessToken, "GET", null, user, null, 3);
        setExams(Object.entries(examsData).map(([guid, exam]) => ({
          name: exam.title,
          displayName: exam.title.length > 25 ? exam.title.substring(0, 25) + '...' : exam.title,
          points: Math.abs(Math.round(exam.total_points)),
          improved: exam.total_points > 0
        })));
      } catch (error) {
        console.error('Error fetching exams:', error);
      }
    };
    if (accessToken && user) {
      fetchExams();
    }
  }, [accessToken, user]);
  return (
    <Paper sx={CARD_STYLE}>
      <Typography variant="h6" gutterBottom sx={{ color: '#6BBDBD', fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }}>
        Exámenes recientes
      </Typography>
      {exams.length > 0 ? (
        <List sx={{ flex: 1, overflowY: 'auto' }}>
          {exams.map((exam, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={
                  <ToolTipMaterial title={exam.name.length > 25 ? exam.name : ""} arrow>
                    <Typography variant="body1" style={{ fontSize: "14px", fontWeight: '600' }}>
                      {exam.displayName}
                    </Typography>
                  </ToolTipMaterial>
                }
                secondary={
                  <Typography variant="body2" color="text.secondary" style={{ fontSize: "14px", display: "flex", alignItems: "center" }}>
                    🧠 Puntos <span style={{ paddingLeft: "5px", paddingRight: "5px", fontWeight: 'bold', color: exam.improved ? '#00B564' : '#AA161D' }}>{exam.points}</span>
                    {exam.improved ? (
                      <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 15.5C11.6421 15.5 15 12.1421 15 8C15 3.85786 11.6421 0.5 7.5 0.5C3.35786 0.5 0 3.85786 0 8C0 12.1421 3.35786 15.5 7.5 15.5Z" fill="#00B564" />
                        <path d="M11.5 7.575L7.5 3.75L3.5 7.575H6.15V12.25H8.725V7.575H11.5Z" fill="white" />
                      </svg>
                    ) : (
                      <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 0.5C3.358 0.5 0 3.858 0 8C0 12.142 3.358 15.5 7.5 15.5C11.642 15.5 15 12.142 15 8C15 3.858 11.642 0.5 7.5 0.5ZM7.5 12.25L3.5 8.42375H6.21425V3.75H8.7855V8.42375H11.5L7.5 12.25Z" fill="#AA161D" />
                      </svg>

                    )}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <img src={Vacio} alt="Vacio" style={{ marginBottom: '5px' }} />
          <Typography variant="body1" sx={{ mt: 2, color: 'gray', textAlign: 'center', fontSize: '14px' }}>
            Aún no has hecho ningún examen
          </Typography>
        </Box>
      )}
    </Paper>
  );
};


const StudyStreak = ({ days, allPointsData }) => {
  const theme = createTheme({
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={1} sx={{ maxWidth: 1200 }}>
        <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
          <ProductivityChart allPointsData={allPointsData} />
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
          <StudyStreakCard days={days} />
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
          <RecentExams />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default StudyStreak;
