import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    buttonUpload: {
        backgroundColor: "#E2F1F1",
        color: "#026277",
        textTransform: "none",
        borderRadius: "20px",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        padding: "6px 22px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#E2F1F1', // Gray color for the disabled state
            color: "#026277",


        }
    },
}));

const UploadButton = ({ onClick, message, svg_icon }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (

        <div>
            <Button className={classes.buttonUpload} onClick={onClick}>
                {!svg_icon ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: "6px" }}>
                        <path d="M3.33301 13.3332L3.33301 14.1665C3.33301 15.5472 4.4523 16.6665 5.83301 16.6665L14.1663 16.6665C15.5471 16.6665 16.6663 15.5472 16.6663 14.1665L16.6663 13.3332M13.333 6.6665L9.99967 3.33317M9.99967 3.33317L6.66634 6.6665M9.99967 3.33317L9.99967 13.3332" stroke="#026277" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                ) : svg_icon !== "_" ? (
                    svg_icon
                ) : null}
                {message || t("upload_notes")}
            </Button>
        </div>

    );
};

export default UploadButton;
