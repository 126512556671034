import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useAuth } from "../Auth/Authhandle";

const PointsContext = createContext();

export const usePoints = () => useContext(PointsContext);

export const PointsProvider = ({ children }) => {
    const [pointsData, setPointsData] = useState([]);
    const [allPointsData, setAllPointsData] = useState(null);
    const { accessToken, user } = useAuth();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    const fetchAllPointsData = useCallback(async () => {
        if (accessToken && user) {
            try {
                const response = await fetch(`${BACKEND_URL}/api_v1/exams/?user_id=${user['sub']}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const days = ["Dm", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
                    const resultData = [];
                    const startDate = new Date();
                    startDate.setDate(startDate.getDate() - 6); // Start from 6 days ago
                    const answeredExams = Object.values(data).filter(exam => exam.answered === true);

                    for (let i = 0; i < 7; i++) {
                        const currentDate = new Date(startDate);
                        currentDate.setDate(startDate.getDate() + i);
                        const points = Object.values(answeredExams)
                            .filter(exam => new Date(exam.date).toDateString() === currentDate.toDateString())
                            .reduce((sum, exam) => sum + exam.num_questions, 0);

                        const dayName = currentDate.toDateString() === new Date().toDateString() ? "Hoy" : days[currentDate.getDay()];

                        resultData.push({
                            date: dayName,
                            points: points,
                        });
                    }
                    setAllPointsData(resultData);
                } else {
                    console.error('Failed to fetch exams data');
                }
            } catch (error) {
                console.error('Error fetching exams data:', error);
            }
        }
    }, [accessToken, user, BACKEND_URL]);

    return (
        <PointsContext.Provider value={{ allPointsData, fetchAllPointsData }}>
            {children}
        </PointsContext.Provider>
    );
};