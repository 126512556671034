import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import Lottie from 'lottie-react';
import animationData from '../Images/singing-contract.json';
import ErrorDocs from '../Images/ERRORDOCS.svg';


const ErrorPreguntesDoc = () => {
  return (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
            <img
              src={ErrorDocs}
              alt="Error"
              style={{ width: 300, height: 300, marginTop: "100px" }}
            />
      </Box>

      <Typography variant="h6" align="center" sx={{ fontFamily: 'Inter, sans-serif' }}>
        Error al generar las preguntas. Estamos trabajando en ello. Disculpa las molestias.
      </Typography>
    </Container>
  );
};

export default ErrorPreguntesDoc;
