import {
    Box,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
    Button,
    Container,
    LinearProgress,
    Chip,


} from "@material-ui/core";
import React, { useEffect, useState, useCallback } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Chat from "../NewComponents/Chat";
import RefreshIcon from '@mui/icons-material/Refresh';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'; // Add these imports for arrow icons
import PopupFinishExam from '../Exam/Styles/PopupFinishExam';
import useStyles from '../Documents/Styles/useStylePreguntasDocument';

import Skeleton from '@mui/material/Skeleton';
import EditIcon from '@material-ui/icons/Edit';
import { useParams } from "react-router-dom";
import { useAuth } from "../Auth/Authhandle";
import SmallAlert from "../NewComponents/Modal/smallAlert";
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { fetchAllCorrectAIRemarks, fetchExamId, fetchRestartExam } from "../Api/ExamsApi";
import Document from "../Documents/Document";
import Questions from "./Questions/QuestionsView";
import "./Styles/style.css";
import useExamViewStyles from "./Styles/useExamViewStyles";
import CorrectInfo from "./CorrectInfo";
import { fetchQuestionAnswer, fetchQuestionAnswerId } from "../Api/QuestionsApi";
import ModalParent from "../AllModalsActions/ParentModal";
import * as handle from "../Main/handle";
import FullScreenLoader from "../NewComponents/LoadingExam";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import PopUpCorrection from "./PopUpCorrection";
import animationData from '../Images/singing-contract.json';
import Lottie from 'lottie-react';
import { set } from "date-fns";
import { useCredits } from "../Context/CreditsContext";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;




function ExamView() {
    const classes = useExamViewStyles();
    const classes2 = useStyles();


    const { updateCredits, credits } = useCredits();

    const { accessToken, user } = useAuth(); // Use the hook at the top level

    const [messages, setMessages] = useState([]); // Move messages state up

    //Alert
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [folderSelectedGuid, setFolderSelectedGuid] = useState("");
    const [documentSelectedGuid, setDocumentSelectedGuid] = useState("");
    const { t } = useTranslation();
    const [examData, setExamData] = useState({ questions: [] });
    const [pdfData, setPDFData] = useState({ questions: [] });
    const [answers, setAnswers] = useState([]);
    const [isPdfExam, setIsPdfExam] = useState(false)
    const { exam_id } = useParams();
    const [corrected, setCorrected] = useState(false)
    const [allCorrected, setAllCorrected] = useState(false)
    const [needReset, setNeedReset] = useState(false)
    const [improve, setImprove] = useState([])
    const [finalScore, setFinalScore] = useState("")
    const [listScore, setListScore] = useState([])
    // Managing state of quesiton 
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Add this state

    const [validatedQuestions, setValidatedQuestions] = useState({});
    const navigate = useNavigate();

    const [showResultsPopUp, setShowResultsPopUp] = useState(false);
    const [isLoadingResults, setIsLoadingResults] = useState(false);
    // Assuming modalOpenUpload is a boolean to control the visibility of a modal
    const [modalOpenUpload, setModalOpenUpload] = useState(false);
    const [titleOptionUpload, setTitleOptionUpload] = useState('');
    const [descriptionOptionUpload, setDescriptionOptionUpload] = useState('');
    const [buttonOptionUpload, setButtonOptionUpload] = useState('');
    const [placeholderMessageUpload, setPlaceholderMessageUpload] = useState('');
    const [typeUpload, setTypeUpload] = useState('');
    const [showSpinnerUpload, setShowSpinnerUpload] = useState(false);
    const [showAlertUpload, setShowAlertUpload] = useState(false);
    const [progressBar, setProgressBar] = useState(false);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [messageUpload, setMessageUpload] = useState('');
    const [correctingExam, setCorrectingExam] = useState(false);
    const [width, setWidthModal] = useState("");
    const [isPreparingExam, setIsPreparingExam] = useState(false);
    const [graphData, setGraphData] = useState([]);
    const calculateGraphData = (examDataFetch) => {
        const graphData = examDataFetch.questions.reduce((acc, question) => {
            const mainTopic = question.main_topic || 'Unknown';
            if (question.question_answers && question.question_answers[0].punctuation_active) {
                const punctuation = question.question_answers[0].punctuation;
                const points_answered = punctuation > 0 ? 5 : (punctuation <= 0 ? -3 : 0);

                let current_question_points = question.main_topic.total_points;
                let current_points = current_question_points - points_answered;

                const existingTopic = acc.find(item => item.name === mainTopic.main_topic);
                if (existingTopic) {
                    existingTopic.Cambio += points_answered;
                    existingTopic.Puntos = question.main_topic.total_points - punctuation;
                } else {
                    acc.push({ name: mainTopic.main_topic, Puntos: current_points, Cambio: points_answered });
                }
            }

            return acc;
        }, []);

        // Calculate the Cambio (change) for each topic
        graphData.forEach(topic => {
            topic.Cambio = topic.Cambio;
            topic.Puntos = topic.Puntos;
        });

        return graphData;
    };


    async function fetchExam() {
        if (accessToken && user) {
            try {

                const examDataFetch = await fetchExamId(accessToken, "GET", exam_id, user);
                if (examDataFetch['is_pdf_exam']) {

                    setIsPdfExam(examDataFetch['is_pdf_exam'])

                }
                setImprove(examDataFetch['notes'])
                setPDFData(examDataFetch)
                setEditedTitle(examDataFetch['title'])
                const scores_ = examDataFetch.question_answers.map(question => question.punctuation);
                const totalScore = scores_.reduce((acc, score) => acc + score, 0);

                setListScore(scores_)
                setExamData(examDataFetch)
                setFinalScore(Math.round(examDataFetch['final_score']))
                const initialAnswers = {};
                const initialValidated = {};
                examDataFetch.questions.forEach(question => {
                    initialAnswers[question.guid] = {
                        answer: question.question_answers[0].question_answer || '',
                    };
                    initialValidated[question.guid] = question.question_answers[0].punctuation_active || false;
                });

                setAnswers(initialAnswers);
                setValidatedQuestions(initialValidated);
                // Calculate graph data based on examDataFetch
                setGraphData(calculateGraphData(examDataFetch));

                const graphDataInitialData = examDataFetch.questions.reduce((acc, question) => {
                    const mainTopic = question.main_topic || 'Unknown';
                    if (question.question_answers) {
                        const punctuation = question.question_answers[0].punctuation;
                        const points_answered = 0;

                        let current_question_points = question.main_topic.total_points;
                        let current_points = current_question_points - points_answered;

                        const existingTopic = acc.find(item => item.name === mainTopic.main_topic);
                        if (existingTopic) {
                            existingTopic.Cambio += points_answered;
                            existingTopic.Puntos = question.main_topic.total_points - punctuation;
                        } else {
                            acc.push({ name: mainTopic.main_topic, Puntos: current_points, Cambio: points_answered });
                        }
                    }

                    return acc;
                }, []);

                // Calculate the Cambio (change) for each topic
                graphDataInitialData.forEach(topic => {
                    topic.Cambio = topic.Cambio;
                    topic.Puntos = topic.Puntos;
                });

                // setGraphData(examDataFetch.main_topics)

            } catch (error) {
                console.error(error);
            }
        }
    }
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            await fetchExam();
            setIsLoading(false);
        }
        fetchData();
    }, [exam_id, accessToken, user]);

    const handleCorrectCurrentQuestion = async () => {

        const question = examData.questions[currentQuestionIndex];
        const answer = answers.find(a => a.hasOwnProperty(question.guid)) || {};
        const payload = {
            answer: answer[question.guid]
        };

        try {
            // Make your API call with the payload containing all question IDs and answers
            let corrected_answer = await fetchQuestionAnswerId(accessToken, "POST", question.guid, user, payload);

        } catch (error) {
            console.error('Failed to submit answers:', error);
            // Handle error
        }

    };

    const handleQuestionCorrection = async (questionId) => {
        // Update the state to mark the question as validated
        setValidatedQuestions((prevValidated) => {
            return { ...prevValidated, [questionId]: true };
        });

        // Check if all questions are validated after the state update
        const allQuestionsValidated = examData.questions.every(question =>
            validatedQuestions[question.guid] || question.guid === questionId
        );

        if (allQuestionsValidated) {
            // All questions are validated, set allCorrected to true
            setCorrectingExam(true)

            // Call the API since all questions are now validated
            try {
                let improveCorrection = await fetchAllCorrectAIRemarks(accessToken, "POST", exam_id, user);
                setImprove(improveCorrection['notes']);
                setFinalScore(Math.round(improveCorrection['final_score']));
                const examDataFetch = await fetchExamId(accessToken, "GET", exam_id, user);
                const scores_ = examDataFetch.question_answers.map(question => question.punctuation);
                setGraphData(calculateGraphData(examDataFetch));

                setCorrectingExam(false)
                setListScore(scores_)

                setAllCorrected(true);
                setShowResultsPopUp(true)
                // setIsLoadingResults(true)
                setListScore(scores_)

            } catch (error) {
                console.error("Failed to fetch AI correction remarks:", error);
            }
        }
    };


    useEffect(() => {
        const allQuestionsValidated = examData.questions && examData.questions.length > 0
            ? examData.questions.every(question => validatedQuestions[question.guid])
            : false;

        setAllCorrected(allQuestionsValidated);
    }, [validatedQuestions, examData.questions]);


    // const handleAnswerChange = (questionId, answer_enter) => {
    //     console.log("answer enter ", answer_enter)
    //     console.log("answer ", answers)
    //     setAnswers((prevAnswers) => ({
    //         ...prevAnswers,
    //         [questionId]: answer_enter,
    //     }));
    // };
    const handleAnswerChange = (questionId, answer_enter) => {
        setNeedReset(false)
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: {
                ...prevAnswers[questionId],
                answer: answer_enter,
            },
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Submit logic goes here
    };


    //TODO, QUE SURTI EL POP UP QUAN NO TENIM RESPOSTES

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleAnswers = async () => {
        const processedAnswers = Object.keys(answers).map(questionId => {
            return { [questionId]: answers[questionId].answer || '' };
        });

        const hasEmptyAnswer = processedAnswers.some(answer =>
            Object.values(answer)[0] === ''
        );

        if (hasEmptyAnswer) {
            setIsPopupOpen(true);
        } else {
            proceedWithCorrection(processedAnswers);
        }
    };



    const proceedWithCorrection = async (processedAnswers) => {
        setCorrectingExam(true);
        setNeedReset(false);

        const payload = {
            questions_answers: processedAnswers,
        };

        try {
            // Make your API call with the payload containing all question IDs and answers
            const examCorrection = await fetchQuestionAnswer(accessToken, "POST", exam_id, user, payload);

            const examDataFetchProcessed = examCorrection['data_exam']
            setExamData(examDataFetchProcessed)

            setImprove(examCorrection['correction_notes'])
            const scores_ = examDataFetchProcessed.question_answers.map(question => question.punctuation);
            const totalScore = scores_.reduce((acc, score) => acc + score, 0);
            // Recalculate points based on scores
            const recalculatedPoints = scores_.reduce((total, score) => {
                if (score > 0) {
                    return total + 5;
                } else if (score === 0) {
                    return total - 3;
                }
                return total;
            }, 0);

            setPoints(recalculatedPoints);
            setListScore(scores_)
            setFinalScore(Math.round(totalScore / scores_.length * 10));

            const correctedData = examDataFetchProcessed.questions.reduce((acc, question) => {
                acc[question.guid] = {
                    correctAnswer: question.question_answer,
                    punctuation: question.question_answers[0].punctuation
                };
                return acc;
            }, {});
            fetchExam(); // Call the fetchData function
            setAllCorrected(true);
            setCorrectingExam(false);
            setShowResultsPopUp(true)


        } catch (error) {
            console.error('Failed to submit answers:', error);
            // Handle error
        }
    };



    const handleConfirmCorrection = () => {
        setIsPopupOpen(false);
        proceedWithCorrection(Object.keys(answers).map(questionId => {
            return { [questionId]: answers[questionId].answer || '' };
        }));
    };

    const handleNextQuestion = () => {
        setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, examData.questions.length - 1));
    };

    const handlePreviousQuestion = () => {
        setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    // Title editing
    const [editMode, setEditMode] = useState(false);
    const [editedTitle, setEditedTitle] = useState(""); // Local state for edited title

    useEffect(() => {
        setEditedTitle(examData.title); // Update local state when examData changes
    }, [exam_id]);

    const handleTitleChange = (event) => {
        setEditedTitle(event.target.value);
    };

    const handleBlur = async () => {
        setEditMode(false);
        // Placeholder for update logic
        // Update examData.title or perform API call to save the updated title
        let payload = { "title": editedTitle }
        const response = fetchExamId(accessToken, "PUT", exam_id, user, payload)
        setExamData(prevExamData => ({
            ...prevExamData,
            title: editedTitle
        }));
        // Assuming updateExamTitle is a function to update the title
        // updateExamTitle(editedTitle);
    };
    const [isLoadingReset, setIsLoadingReset] = useState(false);
    const [showCredits, setShowCredits] = useState(false);
    const resetExam = async () => {
        if (credits >= 40) {
            try {
                setIsLoadingReset(true);

                const data = await fetchRestartExam(accessToken, "POST", exam_id, user)

                window.location.href = `/exam/${data["new_exam_id"]}`;
                // setIsLoadingReset(false) is no longer needed as the page will reload
            } catch (error) {
                console.error('Failed to reset exam:', error);
                // Handle error
                setIsLoadingReset(false);
            }
        } else {
            console.log("GOT HERE?")
            setShowCredits(true)
        }
    };
    const docInfo = React.useMemo(() => ({
        url_stored: pdfData['pdf_link'] // or any dynamic value
    }), [pdfData['pdf_link']]);
    const handleCreateExam = () => {
        navigate('/new-exam');
    };
    const truncateText = (text, maxLength) => {
        if (!text) {
            return ""; // Return an empty string if text is null, undefined, or empty
        }
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };
    useEffect(() => {
        if (isPreparingExam) {
            const timer = setTimeout(() => {
                setIsPreparingExam(false);
            }, 4000);
            return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or isPreparingExam changes
        }
    }, [isPreparingExam]);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (correctingExam) {



            const interval = setInterval(() => {
                setProgress((oldProgress) => {
                    const increment = Math.random() * 4; // This determines the speed and variability of the progress
                    const newProgress = oldProgress + increment;
                    if (newProgress >= 100) {
                        // When progress exceeds 100, restart from 0 immediately
                        return 0;
                    } else {
                        return newProgress;
                    }
                });
            }, 300);

            return () => clearInterval(interval); // Clean up the interval when the component unmounts or dependencies change

        }

    }, [correctingExam]);

    const updateQuestionData = (questionGuid, updatedData) => {
        setExamData((prevExamData) => {
            const updatedQuestions = prevExamData.questions.map((question) => {
                if (question.guid === questionGuid) {
                    // Update the question_answers array
                    const updatedQuestionAnswers = question.question_answers.map((answer, index) => {
                        if (index === 0) { // Assuming we're always updating the first answer
                            return {
                                ...answer,
                                punctuation: updatedData.punctuation,
                                punctuation_active: updatedData.punctuation_active,
                            };
                        }
                        return answer;
                    });

                    // Return the updated question object
                    return {
                        ...question,
                        question_answers: updatedQuestionAnswers,
                        ot_good: updatedData.ot_good,
                        ot_bad: updatedData.ot_bad,
                    };
                }
                return question;
            });

            // Return the updated examData object
            return {
                ...prevExamData,
                questions: updatedQuestions,
            };
        });
    };

    const getChipClass = (points) => {
        if (points === 0) return classes.sinEmpezarChip;
        if (points >= 1 && points <= 39) return classes.wrongChip;
        if (points >= 40 && points <= 79) return classes.learningChip;
        return classes.memorizedChip;
    };

    // Calculate progress


    const [points, setPoints] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const [initialPointsCalculated, setInitialPointsCalculated] = useState(false);

    const calculateInitialPoints = useCallback(() => {
        if (!initialPointsCalculated && examData.questions && examData.questions.length > 0) {
            let calculatedPoints = 0;
            examData.questions.forEach(question => {
                if (question.question_answers && question.question_answers[0].question_answer != "") {
                    const punctuation = question.question_answers[0].punctuation || 0;
                    calculatedPoints += punctuation > 0 ? 5 : -3;
                }
            });
            setPoints(calculatedPoints);
            setInitialPointsCalculated(true);
        }
    }, [examData, initialPointsCalculated]);

    const calculatePoints = useCallback((punctuation) => {
        setPoints(prevPoints => {
            const pointChange = punctuation > 0 ? 5 : -3;
            const newPoints = prevPoints + pointChange;
            return newPoints;
        });
    }, []);

    useEffect(() => {
        const totalPoints = examData.questions ? examData.questions.length * 5 : 0;
        setTotalPoints(totalPoints);
        calculateInitialPoints();
    }, [examData, calculateInitialPoints]);

    useEffect(() => {
    }, [examData]);
    if (isLoadingReset) {
        return (
            <div className={classes.loadingContainer}>
                <Lottie
                    animationData={animationData}
                    style={{ width: 300, height: 300 }}
                />
                <Typography sx={{ fontFamily: "Inter" }}>Estamos preparando tu examen</Typography>
            </div>
        );
    }
    const ExamSkeleton = () => (
        <Container className={classes.gridContainer}>
            <Grid item className={classes.leftPart}>
                <Paper elevation={3} className={classes.paperLeft} style={{ height: "100%" }}>
                    <Box margin="20px 0px">
                        <Skeleton variant="text" width="60%" height={40} />
                    </Box>
                    <Skeleton variant="rectangular" width="80%" height={20} style={{ marginBottom: '20px' }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: '20px' }} />
                    <Skeleton variant="text" width="40%" height={30} style={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" width="100%" height={200} style={{ marginBottom: '20px' }} />
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Skeleton variant="circular" width={40} height={40} style={{ marginRight: '10px' }} />
                        <Skeleton variant="text" width={100} height={30} />
                        <Skeleton variant="circular" width={40} height={40} style={{ marginLeft: '10px' }} />
                    </Box>
                </Paper>
            </Grid>
            <Grid item className={classes.rightPart}>
                <Paper elevation={3} className={classes.paperright}>
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                </Paper>
            </Grid>
        </Container>
    );
    if (isLoading) {
        return <ExamSkeleton />;
    }


    return (


        <>

            {showAlert &&
                <SmallAlert
                    message={message}
                    onClose={() => setShowAlert(false)}
                />
            }
            {
                correctingExam && (
                    <div className={classes.step3Container}>
                        <div className={classes.step3Content}>

                            <Lottie
                                animationData={animationData}
                                style={{ width: 300, height: 300 }}
                            />
                            <Typography style={{ fontWeight: "bold", fontSize: "16px", color: "#026277" }}>Estamos analizando tus respuestas</Typography>
                            <Box sx={{ width: '100%', my: 2 }}>
                                <Box sx={{
                                    width: '100%',
                                    height: '10px',
                                    bgcolor: '#8db8c2',
                                    borderRadius: '6px',
                                    overflow: 'hidden' // Ensure the inner box doesn't overflow the rounded corners
                                }}>
                                    <Box sx={{
                                        width: `${progress}%`, // This represents the progress
                                        height: '100%',
                                        bgcolor: '#026277', // Color of the progress indicator
                                    }} />
                                </Box>
                                <Typography style={{ fontWeight: 500, color: "gray", fontSize: "12px" }}>{t("process_take_time")}</Typography>
                            </Box>
                        </div>
                    </div>
                )
            }
            {
                showUpgrade || showCredits && (
                    <NeedCredits initialOpen={true} message={message}

                        onClose={() => { setShowUpgrade(false); setShowCredits(false) }} />
                )
            }
            {
                modalOpenUpload && (
                    <ModalParent
                        open={modalOpenUpload}
                        title={titleOptionUpload}
                        description={descriptionOptionUpload}
                        sendButton={buttonOptionUpload}
                        placeholdMessage={placeholderMessageUpload}
                        type={typeUpload}
                        onClose={() => setModalOpenUpload(false)}
                        setShowSpinner={setShowSpinnerUpload}
                        setShowAlert={setShowAlertUpload}
                        setMessage={setMessageUpload}
                        setProgressBar={setProgressBar}
                        setShowUpgrade={setShowUpgrade}
                        width={width}
                        setIsPreparingExam={setIsPreparingExam}
                        setFolderSelectedGuid={setFolderSelectedGuid}
                        setDocumentSelectedGuid={setDocumentSelectedGuid}


                    />
                )
            }
            {isPreparingExam && <FullScreenLoader
                imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/ExamGettingReady.svg"
                title={t('fullScreenLoader.examPreparingTitle')}
                message={t('fullScreenLoader.examPreparingMessage')}
                buttonText={t('fullScreenLoader.examPreparingButton')}
                onClose={() => { setIsPreparingExam(false); }}
                onButtonClick={() => { setIsPreparingExam(false); if (folderSelectedGuid && documentSelectedGuid) { navigate(`/${folderSelectedGuid}/${documentSelectedGuid}`) } }}
            />}




            <Container
                container
                spacing={3}
                className={classes.gridContainer}
            >
                <>
                    <Grid item className={classes.leftPart}>
                        {/* Adjust grid sizes for responsiveness */}



                        <Paper elevation={3} className={classes.paperLeft} style={{ height: "100%" }}>

                            {/* Add LinearProgress here */}


                            <div style={{
                                // display: 'flex',
                                // alignItems: 'center',
                                // justifyContent: 'space-between',
                                // width: "100%",
                                // margin: "0 auto",
                                // marginTop: '40px', // Adjust top margin of container instead of using 'top' property
                                // overflowX: "hidden",

                            }}>
                                {/* Title with fixed position */}
                                {editMode ? (
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Enter title here"
                                        value={editedTitle}
                                        onChange={handleTitleChange}
                                        onBlur={handleBlur}
                                        style={{
                                            flexGrow: 1, // Allow TextField to take up available space
                                        }}
                                    />
                                ) : (
                                    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="space-between" margin="20px 0px">

                                        <div style={{ display: 'flex', flexDirection: "row", }}>
                                            <Typography
                                                style={{
                                                    fontWeight: 600,
                                                    marginRight: 20, // Space between text and icon
                                                    fontSize: "18px",
                                                    marginLeft: "0px"
                                                }}
                                            >
                                                {truncateText(examData.title, 30)}

                                            </Typography>
                                            <IconButton style={{ padding: "0px" }} onClick={() => setEditMode(true)}>
                                                <EditIcon />
                                            </IconButton>
                                        </div>
                                        <div style={{ display: "flex", gap: "10px" }}>
                                            <Button className={classes.buttonNewExam} onClick={handleCreateExam}>
                                                &nbsp;{t("new_exam")}</Button>
                                            {allCorrected ? (
                                                <>

                                                    <RefreshIcon onClick={resetExam} style={{ cursor: "pointer", color: "#026277", marginTop: "3px" }} />
                                                </>
                                            ) : (
                                                <>
                                                    <Button className={classes.buttonUpload} onClick={handleAnswers}>{t("correct_exam")}</Button>
                                                </>

                                            )}
                                        </div>
                                    </Box>
                                )}
                                {/* Image with left margin to push it away from the title */}
                                {/* <img src={icon_pdf} style={{ marginLeft: '850px' }} /> Adjust the marginLeft value as needed */}
                            </div>

                            {/* Scrollable form container */}
                            <div style={{
                                top: "115px",
                                bottom: 0,
                                overflowY: 'auto', // Enable vertical scrolling
                                paddingTop: '15px', // Adjust padding as needed


                            }}
                            >


                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>

                                    <div style={{ width: "80%" }}>

                                        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '0px', textAlign: 'right', fontWeight: "700" }}>
                                            {points}/{totalPoints}🧠
                                        </Typography>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <svg width="36" height="36" viewBox="0 0 204 291" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '10px' }}>
                                                <rect x="95.0005" y="153.839" width="13.0851" height="46.1421" fill="url(#paint0_linear_8918_3722)" />
                                                <path d="M45.3564 56.4326H5C5.00001 87.7279 7.4 105.73 51.9887 105.73" stroke="#FFDD66" stroke-width="9" />
                                                <path d="M160.553 97.4213C160.553 130.154 134.018 156.688 101.286 156.688C68.5534 156.688 42.0186 130.154 42.0186 97.4213C42.0186 64.6889 68.5534 38.1541 101.286 38.1541C134.018 38.1541 160.553 64.6889 160.553 97.4213Z" fill="#FFDD66" />
                                                <path d="M101.286 197.677C77.1188 197.677 57.5277 204.745 57.5277 213.463H145.044C145.044 204.745 125.453 197.677 101.286 197.677Z" fill="#FFDD66" />
                                                <path d="M42.0186 26.7637H160.553V94.6518H42.0186V26.7637Z" fill="#FFDD66" />
                                                <path d="M145.044 216.509H57.5277V213.186H145.044V216.509Z" fill="#FFDD66" />
                                                <path d="M158.877 56.4326H199.233C199.233 87.7279 196.833 105.73 152.245 105.73" stroke="#DE9300" stroke-width="9" />
                                                <path d="M160.553 97.4217C160.553 130.154 134.018 156.689 101.286 156.689C101.286 156.689 101.286 130.154 101.286 97.4217C101.286 64.6893 101.286 38.1546 101.286 38.1546C134.018 38.1546 160.553 64.6893 160.553 97.4217Z" fill="url(#paint1_linear_8918_3722)" />
                                                <path d="M101.286 197.677C101.286 197.677 101.286 204.745 101.286 213.463H145.044C145.044 204.745 125.453 197.677 101.286 197.677Z" fill="url(#paint2_linear_8918_3722)" />
                                                <path d="M101.286 26.7686H160.553V94.6522H101.286V26.7686Z" fill="url(#paint3_linear_8918_3722)" />
                                                <path d="M145.044 216.51H101.286C101.286 216.51 101.188 214.481 101.286 213.186C102.58 196.147 145.044 213.186 145.044 213.186V216.51Z" fill="url(#paint4_linear_8918_3722)" />
                                                <circle cx="102.449" cy="90.8238" r="35.4674" fill="#DCAE0C" />
                                                <circle cx="102.378" cy="90.3871" r="42.2857" fill="#DE9300" stroke="#FFE176" stroke-width="4.55437" />
                                                <path d="M102.378 61.2285L111.364 79.2004L129.336 81.4469L116.995 95.2852L120.35 115.144L102.378 106.158L84.4064 115.144L87.7911 95.2852L75.4204 81.4469L93.3923 79.2004L102.378 61.2285Z" fill="#FFF4BC" />
                                                <path d="M103.273 60.7813L102.378 58.9924L101.484 60.7813L92.7372 78.2745L75.2964 80.4546L73.4036 80.6912L74.6749 82.1133L86.7243 95.5924L83.4206 114.976L83.0891 116.921L84.8536 116.039L102.378 107.276L119.903 116.039L121.664 116.919L121.336 114.978L118.061 95.5923L130.082 82.1124L131.35 80.6909L129.46 80.4546L112.019 78.2745L103.273 60.7813Z" stroke="#C98500" stroke-opacity="0.7" stroke-width="2" />
                                                <path d="M29.0463 41.262L35.2958 27.508L29.0463 13.754L42.8003 20.0035L56.5543 13.754L50.3048 27.508L56.5543 41.262L42.8003 35.0125L29.0463 41.262Z" fill="white" />
                                                <path d="M39.5918 229.029C39.5918 220.645 46.3887 213.848 54.773 213.848H148.553C156.937 213.848 163.734 220.645 163.734 229.029V277.439H39.5918V229.029Z" fill="#243B6D" />
                                                <rect x="58.6736" y="231.536" width="88.5093" height="30.1924" fill="#F9B50E" stroke="#FFEC86" stroke-width="3.91749" />
                                                <path d="M63.3169 229.577H105.129L138.139 263.687H96.3265L63.3169 229.577Z" fill="#FFEC86" />
                                                <rect x="32.6948" y="277.439" width="137.936" height="13.2038" fill="#DE9300" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_8918_3722" x1="101.543" y1="153.839" x2="101.543" y2="199.981" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#C07F00" />
                                                        <stop offset="1" stop-color="#DE9300" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_8918_3722" x1="160.145" y1="86.5362" x2="100.728" y2="86.5362" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#DE9300" />
                                                        <stop offset="1" stop-color="#FFBC11" />
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_8918_3722" x1="160.145" y1="86.5362" x2="100.728" y2="86.5362" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#DE9300" />
                                                        <stop offset="1" stop-color="#FFBC11" />
                                                    </linearGradient>
                                                    <linearGradient id="paint3_linear_8918_3722" x1="160.145" y1="86.5362" x2="100.728" y2="86.5362" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#DE9300" />
                                                        <stop offset="1" stop-color="#FFBC11" />
                                                    </linearGradient>
                                                    <linearGradient id="paint4_linear_8918_3722" x1="160.145" y1="86.5362" x2="100.728" y2="86.5362" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#DE9300" />
                                                        <stop offset="1" stop-color="#FFBC11" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <LinearProgress
                                                variant="determinate"
                                                value={(points / totalPoints) * 100}
                                                max={100}
                                                style={{
                                                    height: '15px',
                                                    borderRadius: '24px',
                                                    backgroundColor: '#E2F1F1',
                                                    flexGrow: 1,
                                                }}
                                                classes={{
                                                    bar: {
                                                        backgroundColor: '#00796B'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ marginBottom: "10px", marginTop: "30px" }}>
                                    <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
                                        {examData.questions[currentQuestionIndex]?.main_topic?.main_topic || "No se ha encontrado el capítulo"}
                                    </Typography>
                                    {/* {examData.questions[currentQuestionIndex]?.main_topic?.total_points !== undefined && (
                                        <Chip
                                            label={examData.questions[currentQuestionIndex].main_topic.total_points >= 80 ? "Memorizado" :
                                                examData.questions[currentQuestionIndex].main_topic.total_points >= 40 ? "Aprendiendo" :
                                                    examData.questions[currentQuestionIndex].main_topic.total_points >= 1 ? "Preparando" :
                                                        "Sin Empezar"}

                                            className={getChipClass(examData.questions[currentQuestionIndex].main_topic.total_points)}
                                        />
                                    )} */}


                                </Box>

                                <form >

                                    {examData.questions.length > 0 && (
                                        <Questions

                                            calculatePoints={calculatePoints}
                                            key={examData.questions[currentQuestionIndex].guid}
                                            questionNumber={currentQuestionIndex + 1}
                                            question={examData.questions[currentQuestionIndex]}
                                            answer={answers[examData.questions[currentQuestionIndex].guid]?.answer || ""}
                                            onAnswerChange={handleAnswerChange}
                                            onQuestionCorrection={handleQuestionCorrection}

                                            token={accessToken}
                                            user={user}
                                            corrected={corrected}
                                            setCorrected={setCorrected}
                                            valueAnswer={examData.questions[currentQuestionIndex].punctuation}
                                            reset={needReset}
                                            validated={validatedQuestions[examData.questions[currentQuestionIndex].guid] || false}
                                            updateQuestionData={updateQuestionData} // Add this line
                                            exam_id={exam_id}
                                            points={points} // Pass points as a prop
                                        />
                                    )}

                                </form>
                            </div>

                            <Box display="flex" justifyContent="center" alignItems="center" >
                                <IconButton
                                    onClick={handlePreviousQuestion}
                                    disabled={currentQuestionIndex === 0}
                                    style={{ color: '#6BBDBD' }}
                                >
                                    <ArrowBackIos style={{ fontSize: 'small' }} />
                                </IconButton>
                                <Typography variant="body1" style={{ color: '#6BBDBD' }}>
                                    {currentQuestionIndex + 1} de {examData.questions.length}
                                </Typography>
                                <IconButton
                                    onClick={handleNextQuestion}
                                    disabled={currentQuestionIndex === examData.questions.length - 1}
                                    style={{ color: '#6BBDBD' }}
                                >
                                    <ArrowForwardIos style={{ fontSize: 'small' }} />
                                </IconButton>
                            </Box>

                        </Paper>

                    </Grid >

                    <Grid item className={classes.rightPart}>
                        {/* Adjust grid sizes for responsiveness */}
                        <Paper elevation={3} className={classes.paperright}


                        >
                            {allCorrected ? (

                                <><CorrectInfo listScores={listScore} final_score={finalScore} items={improve} isLoading={isLoadingResults} /></>
                            ) : (<Chat
                                height={"100%"}
                                messages={messages}
                                setMessages={setMessages}
                                accessToken={accessToken} // Passing accessToken as a prop
                                user={user}
                                folder={examData.document?.folder?.guid ?? ""}
                                document={examData.document?.guid ?? ""}

                            />)}



                        </Paper>
                    </Grid>
                </>
            </Container >

            <PopupFinishExam
                open={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onConfirm={handleConfirmCorrection}
            />
            <PopUpCorrection
                open={showResultsPopUp}
                onClose={() => setShowResultsPopUp(false)}
                dataGraph={graphData}
                listScore={listScore}

            />



        </>
    );
}

export default ExamView;
