import {
    Button,
    Grid,
    Paper,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from "@material-ui/core";
import { useSubscription } from "../Context/SubscriptionContext";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import ModalFolder from "../Folders/ModalFolders";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import PotatoIcon from "@mui/icons-material/EmojiNature"; // Use a potato icon
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, Box } from '@mui/material';
import ProgresoLateral from "../Documents/ProgresoLateral";

import { useParams, useLocation } from "react-router-dom";
import { fetchUserFile } from '../Api/FilesApi'; // Import the fetchUserInfo function
import Document from "../Documents/Document";
import FolderContent from "../Folders/FolderContent"; // Adjust the path based on your folder structure
import BreadCrumb from "../NewComponents/BreadCrumb"; // Adjust the path based on your folder structure
import Chat from "../NewComponents/Chat";
import * as handle from "./handle";
import { Container } from "@material-ui/core"
import { useAuth } from "../Auth/Authhandle";
import SmallAlert from "../NewComponents/Modal/smallAlert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useTranslation } from 'react-i18next';
import Summary from "../Documents/SummaryChapters";
import { WebSocketContext } from '../WebSocketProvider';
import CustomList from "./CustomList";
import useDocumentStyles from "./Styles/useDocumentStyles";
import UploadButton from "../NewComponents/SubirApuntesButton";
import ModalParent from "../AllModalsActions/ParentModal";
import { fetchExamsbyDocumentId, fetchFolderExams } from "../Api/ExamsApi";
import FullScreenLoader from "../NewComponents/LoadingExam";
import DropdownMenu from "../NewComponents/DropDownComponent";
import { fetchQuestions } from "../Api/ChaptersApi";
import { usePoints } from '../Context/PointsContext';
import Premium from '../PopUpsAlerts/Premium/Premium';
import LockIcon from '../Images/Lock.svg';
import { useGenerateQuestions } from "../Context/GenerateQuestionsContext";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;



function DocumentStructure({ active_folder }) {
    const { userData } = useSubscription();
    const location = useLocation();

    const { state } = location;

    const shouldCreateExam = state?.shouldCreateExam || false;
    const shouldCreateSummary = state?.shouldCreateSummary || false;

    const { accessToken, user } = useAuth(); // Use the hook at the top level
    const [listDocuments, setListDocuments] = useState([]);
    const [listExams, setListExams] = useState([]); // Define setListExams state

    const [messages, setMessages] = useState([]); // Move messages state up
    const [isVideo, setIsVideo] = useState(true);
    const [isOpenVideo, setIsOpenVideo] = useState(false);
    const [valueVideo, setValueVideo] = useState("Ver video");
    const [videoUrl, setVideoUrl] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [titleOption, setTitle] = useState("");
    const [descriptionOption, setDescription] = useState("");
    const [buttonOption, setButton] = useState("");
    const [placeholderMessage, setPlaceholderMessage] = useState("");
    const [typeModal, setTypeModal] = useState("");
    const [openMenu, setOpenMenu] = useState(false);
    // Folder info 
    const [folder, setFolder] = useState(null)
    // Single Doc info
    const [docInfo, setDocInfo] = useState(null)

    //Alert
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");

    const { t } = useTranslation();

    const { chapter_id, selectedChapter } = useParams();

    const { document_id, selectedDocument } = useParams();
    const initialIsDocument = document_id ? true : false;

    const { folder_id, selectedFolder } = useParams();
    const [summary, setSummary] = useState("")
    const { needGenerateQuestions, setNeedGenerateQuestions } = useGenerateQuestions();

    // For upload file pop up
    // Assuming modalOpenUpload is a boolean to control the visibility of a modal
    const [modalOpenUpload, setModalOpenUpload] = useState(false);
    const [titleOptionUpload, setTitleOptionUpload] = useState('');
    const [descriptionOptionUpload, setDescriptionOptionUpload] = useState('');
    const [buttonOptionUpload, setButtonOptionUpload] = useState('');
    const [placeholderMessageUpload, setPlaceholderMessageUpload] = useState('');
    const [typeUpload, setTypeUpload] = useState('');
    const [showSpinnerUpload, setShowSpinnerUpload] = useState(false);
    const [showAlertUpload, setShowAlertUpload] = useState(false);
    const [progressBar, setProgressBar] = useState(false);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [messageUpload, setMessageUpload] = useState('');
    const [chaptersTitle, setChaptersTitle] = useState([]);
    const [chapterTitle, setChapterTitle] = useState([]);
    const [loadingStates, setLoadingStates] = useState({});
    const [width, setWidthModal] = useState("");
    const [isDocument, setIsDocument] = useState(true);
    const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);

    const classes = useDocumentStyles(isDocument);

    const [questions, setQuestions] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [loadedChapters, setLoadedChapters] = useState([]);
    const [currentChapterIndex, setCurrentChapterIndex] = useState(0);



    const handleUploadFile = () => {
        handle.handleUploadFile(
            t,
            setModalOpenUpload,
            setTitleOptionUpload,
            setDescriptionOptionUpload,
            setButtonOptionUpload,
            setPlaceholderMessageUpload,
            setTypeUpload,
            setWidthModal
        );
    };
    const handleCreateExam = () => {
        navigate(`/new-exam?document=${document_id}`)
    };
    useEffect(() => {
        async function fetchData() {
            if (accessToken && user) {
                handle.fetchData(accessToken, setFolder, folder_id, user); // Call the fetchData function
                handle.fetchDocuments(accessToken, setListDocuments, folder_id, user);
            }
        }


        fetchData();
    }, [accessToken, user]);
    useEffect(() => {
        fetchExams()
    }, [accessToken, user, folder])


    // const generateAllSummaries = async () => {
    //   setAllChaptersLoading();  // Set all to loading before starting

    //   for (const chapter of chaptersTitle) {
    //     await generateSummary(chapter.guid);
    //   }
    // };

    useEffect(() => {

        if (shouldCreateExam) {
            handleCreateExam()
        }
    }, [shouldCreateExam])


    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchQuestions(accessToken, document_id, user);
            setQuestions(data)
        };
        if (accessToken && document_id && user) {
            fetchData();
        }
    }, [accessToken, needGenerateQuestions, document_id, user]);



    useEffect(() => {
        const fetchDecryptedVideoUrl = async (url_stored) => {
            try {
                const response = await fetch(`${BACKEND_URL}/api_v1/decrypt-pdf/`, {
                    method: 'POST',
                    body: JSON.stringify({ fileUrl: url_stored }),
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` }
                });
                if (response.ok) {
                    const blob = await response.blob(); // Handle binary data for video
                    const url = URL.createObjectURL(blob); // Create a URL for the blob object
                    setVideoUrl(url);
                } else {
                    console.error('Failed to fetch video URL');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        async function fetchData() {
            if (accessToken && user) {
                try {

                    const docSeri = await fetchUserFile(accessToken, "GET", folder_id, document_id, null, user);

                    setDocInfo(docSeri);

                    setSummary(docSeri.summary)
                    setIsGeneratingSummary(docSeri.is_creating_summary)
                    if (docSeri.type_dc === '.mp4' || docSeri.type_dc === '.mp3') {
                        setIsVideo(true)
                        fetchDecryptedVideoUrl(docSeri.url_stored);

                    } else {
                        setIsVideo(false)
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }

        if (document_id) {

            fetchData(); // Call the fetchData function
        }

    }, [document_id, accessToken, user, needGenerateQuestions])

    const fetchExams = async () => {
        if (accessToken && user && folder) {
            try {
                const listExamsFetched = await fetchExamsbyDocumentId(accessToken, "GET", folder.guid, document_id, user,);

                // const listExamsFetched = await fetchFolderExams(accessToken, "GET", folder.guid, user);
                setListExams(listExamsFetched);
            } catch (error) {
                console.error(error);
            }
        }
    };
    const updateListDocuments = async () => {

        handle.fetchDocuments(accessToken, setListDocuments, folder_id, user);

    };


    // const shouldShowGenerateButton = useMemo(() => {
    //   // Check if any chapter does not have a summary generated
    //   return chaptersTitle.some(chapter => !chapter.is_active);
    // }, [chaptersTitle]);  // Recompute when chaptersTitle changes

    // const setAllChaptersLoading = () => {
    //   const newLoadingStates = chaptersTitle.reduce((acc, chapter) => {
    //     acc[chapter.guid] = true;  // Set loading to true for each chapter
    //     return acc;
    //   }, {});
    //   setLoadingStates(newLoadingStates);
    // };


    const [typeAlert, setTypeAlert] = useState("");
    const [linkinMessage, setLinkinMessage] = useState("");
    const [isPreparingExam, setIsPreparingExam] = useState(false);

    const backendHost = BACKEND_URL.replace(/^https?:\/\//, '');
    const url = "";
    const { messageCallback } = useContext(WebSocketContext);
    const [errorGeneratingQuestions, setErrorGeneratingQuestions] = useState(false);
    const [stepValue, setStepValue] = useState("");
    const [messageStep, setMessageStep] = useState("");
    useEffect(() => {
        if (messageCallback) {
            switch (messageCallback.type) {
                case "summary":

                    setSummary(messageCallback.summary);
                    break;
                case "documentsUpdate":
                    setShowAlert(false)
                    setListDocuments(prevDocuments => [...prevDocuments, messageCallback.document]);
                    break;
                case "Exam":

                    fetchExams()
                    break;
                case "ErrorGenerating":
                    setErrorGeneratingQuestions(true)
                    break;
                case "generating_questions":
                    setNeedGenerateQuestions(true)
                    break;
                case "generating_questions_done":
                    setNeedGenerateQuestions(false)
                    fetchQuestionsData();


                    break;
                case "steps_generating_questions":
                    setStepValue(messageCallback.step)
                    setMessageStep(messageCallback.messageStep)
                    break;


                    break;

                default:
                    console.log("Unhandled message type:", messageCallback.document);
            }
        }
    }, [messageCallback]);
    const fetchQuestionsData = async () => {
        try {
            const data = await fetchQuestions(accessToken, document_id, user);
            setQuestions(data);
        } catch (error) {
            console.error("Error fetching questions:", error);
            // Handle the error appropriately
        }
    };
    // const handleWebSocketMessage = useCallback((message) => {
    //   console.log('Received chapters:', message);
    //   switch (message.type) {
    //     case "chapterUpdate":
    //       setChaptersTitle(message.chapters);
    //       break;

    //     default:
    //       console.log("Unhandled message type:", message);
    //   }
    // }, []);
    // const [sendMessage] = useWebSocketComponent(url, handleWebSocketMessage);
    const handleExamCreated = () => {
        fetchExams();
    };
    const truncateText = (text, length) => {
        if (text.length <= length) {
            return text;
        }
        return text.substring(0, length) + '...';
    };

    useEffect(() => {
        if (isPreparingExam) {
            const timer = setTimeout(() => {
                setIsPreparingExam(false);
            }, 4000);
            return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or isPreparingExam changes
        }
    }, [isPreparingExam]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (option) => {
        setAnchorEl(null);
        if (option) {
            alert(`You selected ${option}`);
        }
    };
    const [folderSelectedGuid, setFolderSelectedGuid] = useState("");
    const [documentSelectedGuid, setDocumentSelectedGuid] = useState("");
    const navigate = useNavigate();

    const [tabValue, setTabValue] = useState(0); // Add this line to define tabValue state
    const [pieData, setPieData] = useState([]);
    const
        calculatePieData = (questions, userData) => {
            let correct = 0;
            let incorrect = 0;
            let learning = 0;
            let notAnswered = 0;
            let blocked = 0;

            if (questions.topics.length > 0 && questions.topics) {
                questions.topics.forEach(topic => {
                    topic.questions.forEach(question => {
                        if (question.points >= 5) {
                            correct++;
                        } else if (question.points >= 1 && question.points <= 4) {
                            learning++;
                        } else if (question.points < 1 && question.question_answered) {
                            incorrect++;
                        } else if (!question.questionactive && userData.plan_name === "Basic") {
                            blocked++;
                        } else {
                            notAnswered++;
                        }
                    });
                });
            }

            return [
                { name: 'Correctas ✅', value: correct },
                { name: 'Incorrectas ❌', value: incorrect },
                { name: 'Aprendiendo 🧠', value: learning },
                { name: 'Sin responder 👩‍🏫', value: notAnswered },
                { name: 'Bloqueadas 🔒', value: blocked },
            ];
        };
    useEffect(() => {
        if (questions && userData && questions.topics && questions.topics.length > 0) {
            const newPieData = calculatePieData(questions, userData);
            setPieData(newPieData);
        }

    }, [document_id, questions]);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue); // Add this function to handle tab changes
    };

    const [showPremium, setShowPremium] = useState(false);

    const handleDesbloquear = () => {
        setShowPremium(true);
    };

    return (
        <>
            {showAlert &&
                <SmallAlert
                    message={message}
                    progressBar={false}
                    onClose={() => setShowAlert(false)}
                    linkinMessage={linkinMessage}
                    typeAlert={typeAlert}
                />
            }
            {showAlertUpload &&
                <SmallAlert
                    message={messageUpload}
                    progressBar={progressBar}
                    onClose={() => setShowAlertUpload(false)}
                />
            }

            {
                <NeedCredits open={showUpgrade} message={message}

                    onClose={() => setShowUpgrade(false)} />
            }

            {isPreparingExam && <FullScreenLoader
                imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/ExamGettingReady.svg"
                title={t('fullScreenLoader.examPreparingTitle')}
                message={t('fullScreenLoader.examPreparingMessage')}
                buttonText={t('fullScreenLoader.examPreparingButton')}
                onClose={() => { setIsPreparingExam(false); }}
                onButtonClick={() => { setIsPreparingExam(false); if (folderSelectedGuid && documentSelectedGuid) { navigate(`/${folderSelectedGuid}/${documentSelectedGuid}`) } }}
            />}


            {/* <Grid

                spacing={3}
                className={classes.gridContainer}
            > */}
            <Container style={{
                display: "flex", flexDirection: "row", gap: "20px", backgroundColor: "#F8FFFF", maxWidth: "none"

            }}>
                <Grid
                    container
                    spacing={0}

                    className={classes.leftPart}

                >


                    <Paper elevation={3} className={classes.paperLeft} >
                        <div style={{
                            position: 'relative', justifyContent: "space-between", display: "flex",
                        }}>
                            {/* <BreadCrumb className={classes.breadcrumbContainer} root={t("home")} docInfo={docInfo} folderInfo={folder} accessToken={accessToken} chapterTitle={chapterTitle} /> */}
                            {!docInfo ? (
                                <Typography style={{
                                    color: "#026277",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    letterSpacing: "-0.225px",
                                    paddingLeft: 2
                                }}>Document</Typography>
                            ) : (
                                <div className={classes.fullWidthContainer}>
                                    <div>
                                        <Tooltip title={docInfo.title} arrow placement="top">
                                            <Typography
                                                style={{
                                                    color: "#026277",
                                                    fontSize: "20px",
                                                    fontStyle: "normal",
                                                    fontWeight: 600,
                                                    letterSpacing: "-0.225px",
                                                    paddingLeft: 2,
                                                }}
                                            >
                                                {truncateText(docInfo.title, 30)}
                                            </Typography>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Box style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                            {userData.plan_name === "Basic" && (
                                                <Button style={{
                                                    backgroundColor: 'white',
                                                    color: '#6BBDBD',
                                                    textTransform: 'none',
                                                    fontWeight: '600',
                                                    fontFamily: "'Inter', sans-serif",
                                                    borderRadius: '24px',
                                                    border: '1px solid #6BBDBD',
                                                    fontSize: '14px',
                                                    display: 'flex',
                                                    padding: '6px 24px',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: '8px',
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        backgroundColor: '#6BBDBD',
                                                    },
                                                    width: '100%',
                                                    maxWidth: 'none',
                                                }} onClick={handleDesbloquear}>
                                                    <img src={LockIcon} alt="Lock" style={{ width: '20px', height: '20px', marginRight: '4px', stroke: '#6BBDBD' }} />
                                                    Desbloquear
                                                </Button>
                                            )}
                                            {questions && questions.topics && questions.topics.length > 0 && (
                                                <Button style={{
                                                    backgroundColor: '#6BBDBD',
                                                    color: 'white',
                                                    textTransform: 'none',
                                                    fontWeight: '600',
                                                    fontFamily: "'Inter', sans-serif",
                                                    borderRadius: '24px',
                                                    fontSize: '14px',
                                                    display: 'flex',
                                                    padding: '6px 32px',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: '8px',
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        backgroundColor: '#6BBDBD',
                                                    },
                                                    width: '100%',
                                                    maxWidth: 'none',
                                                }} onClick={handleCreateExam}>
                                                    Estudiar
                                                </Button>
                                            )}

                                        </Box>
                                        {/* <DropdownMenu document_id={document_id} /> */}
                                    </div>
                                </div>

                            )}
                            {folder !== null && active_folder && (
                                <div>
                                    {/* <UploadButton onClick={handleUploadFile} endIcon={<ArrowDropDownIcon />}
                                    /> */}
                                </div>
                            )}




                        </div>


                        {docInfo && <Document stepValue={stepValue} messageStep={messageStep} setQuestions={setQuestions} questions={questions} loading={loading} error={error} isGeneratingSummary={isGeneratingSummary} setIsGeneratingSummary={setIsGeneratingSummary} docInfo={docInfo} summary={summary} setSummary={setSummary} accessToken={accessToken} user={user} setMessage={setMessage} setShowAlert={setShowAlert} handleCreateExam={handleCreateExam} listExams={listExams} setListExams={setListExams} shouldCreateSummary={shouldCreateSummary} setNeedGenerateQuestions={setNeedGenerateQuestions} needGenerateQuestions={needGenerateQuestions} errorGeneratingQuestions={errorGeneratingQuestions} />}


                    </Paper>

                </Grid>

                <Grid
                    item
                    className={classes.rightPart}


                >



                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="simple tabs example"
                        style={{ marginBottom: "20px", textTransform: "none" }}
                        TabIndicatorProps={{ style: { backgroundColor: "#6BBDBD" } }} // Change the color of the selected tab indicator
                    >
                        <Tab
                            style={{
                                textTransform: "none",
                                fontSize: "16px",
                                fontWeight: tabValue === 0 ? "bold" : "normal",
                                color: tabValue === 0 ? "#6BBDBD" : "#A0A0A0" // Change the color of the selected tab text
                            }}
                            label="🏆 Progreso"
                        />
                        <Tab
                            style={{
                                textTransform: "none",
                                fontSize: "16px",
                                fontWeight: tabValue === 1 ? "bold" : "normal",
                                color: tabValue === 1 ? "#6BBDBD" : "#A0A0A0" // Change the color of the selected tab text
                            }}
                            label="💬 Chat"
                        />
                    </Tabs>
                    {tabValue === 0 && (
                        <div>
                            <ProgresoLateral pieData={pieData} examsList={listExams} />
                        </div>
                    )}
                    {tabValue === 1 && (
                        <Chat
                            height={"95%"}
                            messages={messages}
                            setMessages={setMessages}
                            accessToken={accessToken}
                            user={user}
                            isPdf={true}
                        />
                    )}



                </Grid>

                {/* </Grid > */}
            </Container >


            {modalOpen && (
                <ModalFolder
                    open={true}
                    title={titleOption}
                    description={descriptionOption}
                    sendButton={buttonOption}
                    placeholdMessage={placeholderMessage}
                    type={typeModal}
                    onClose={() => {
                        setModalOpen(false); setOpenMenu(false); // Refresh folders list
                    }}
                    folder_id={folder.guid}
                />
            )
            }
            {
                modalOpenUpload && (
                    <ModalParent
                        open={modalOpenUpload}
                        title={titleOptionUpload}
                        description={descriptionOptionUpload}
                        sendButton={buttonOptionUpload}
                        placeholdMessage={placeholderMessageUpload}
                        type={typeUpload}
                        onClose={() => setModalOpenUpload(false)}
                        setShowSpinner={setShowSpinnerUpload}
                        setShowAlert={setShowAlertUpload}
                        setMessage={setMessageUpload}
                        setProgressBar={setProgressBar}
                        setShowUpgrade={setShowUpgrade}
                        width={width}
                        onExamCreated={handleExamCreated} // Pass the callback to handle exam creation
                        updateListDocuments={updateListDocuments}
                        setIsPreparingExam={setIsPreparingExam}
                        setFolderSelectedGuid={setFolderSelectedGuid}
                        setDocumentSelectedGuid={setDocumentSelectedGuid}


                    />
                )
            }

            {
                showPremium && (
                    <Premium
                        onClose={() => setShowPremium(false)}
                        titleText="questions"
                    />
                )
            }
        </>
    );
}

export default DocumentStructure;