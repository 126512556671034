import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid, LinearProgress, Select, MenuItem, Button, Tooltip as MuiTooltip, Skeleton } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Lock from '../Images/Lock.svg';
import greenCard from '../Images/greenCard.svg';
import yellowCard from '../Images/yellowCard.svg';
import redCard from '../Images/redCard.svg';
import emptyImage from '../Images/Vacio.svg'; // Import the empty state image
import { useNavigate } from 'react-router-dom';

const evolutionData = [

];



const COLORS = ['#D9F9D4', '#F49296', '#FFECCF', '#E4E4E4', '#4A90E2'];
const LABELS = ['Memorizadas', 'Incorrectas', 'Aprendiendo', 'Sin Responder', 'Bloqueadas'];

// Add this dummy data
const dummyPointsData = [
  { try: 1, points: 50 },
  { try: 2, points: 120 },
  { try: 3, points: 180 },
  { try: 4, points: 210 },
  { try: 5, points: 250 },
  { try: 6, points: 220 },
  { try: 7, points: 350 },
];

const Stats = ({ listDocuments, listExams }) => {
  const [selectedDocumentGuid, setSelectedDocumentGuid] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (listDocuments.length > 0 && !selectedDocumentGuid) {
      setSelectedDocumentGuid(listDocuments[0].guid);
    }
  }, [listDocuments, selectedDocumentGuid]);

  const handleDocumentChange = (event) => {
    setSelectedDocumentGuid(event.target.value);
  };

  // Update this function to filter exams based on selectedDocumentGuid
  const getPointsExamData = () => {
    const filteredExams = listExams.filter(exam => exam.document_guid === selectedDocumentGuid);
    const graphicData = filteredExams
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .reduce((acc, exam, index) => {
        const points = index === 0 ? exam.correct_answers * 5 - exam.incorrect_answers * 3 : acc[index - 1].points + (exam.correct_answers * 5 - exam.incorrect_answers * 3);
        acc.push({
          name: exam.title,
          points: points,
          date: exam.date
        });
        return acc;
      }, []);
    return graphicData;
  }

  // Update this function to generate evolutionData for the selected document
  const generateEvolutionData = () => {
    const filteredExams = listExams.filter(exam => exam.document_guid === selectedDocumentGuid);
    const pointsPerDay = filteredExams.reduce((acc, exam) => {
      const date = new Date(exam.date).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit' });
      const points = exam.correct_answers * 5 - exam.incorrect_answers * 3;

      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date] += points;
      return acc;
    }, {});

    return Object.entries(pointsPerDay)
      .map(([date, puntos]) => ({ date, puntos }))
      .sort((a, b) => new Date(a.date.split('/').reverse().join('-')) - new Date(b.date.split('/').reverse().join('-')));
  };

  const getProgressTopic = (document) => {
    console.log("DOCUMENT ", document)
    return { memorized: document.status.memorized, learning: document.status.learning, notKnown: document.status.notKnown, notStarted: document.status.notStarted };
  };

  // Calculate progress for the selected document
  let selectedDocumentObj;
  let progress;
  if (listDocuments.length > 0 && selectedDocumentGuid !== null) {
    selectedDocumentObj = listDocuments.find(doc => doc.guid === selectedDocumentGuid);

    progress = getProgressTopic(selectedDocumentObj);
  }


  const handleStudyClick = () => {
    navigate('/new-exam');
  };

  // Generate evolutionData for the selected document
  const evolutionData = generateEvolutionData();

  // Filter exams for the selected document
  const filteredExams = listExams.filter(exam => exam.document_guid === selectedDocumentGuid);

  if (listDocuments.length === 0 || selectedDocumentGuid === null) {
    return (
      <Box marginTop="40px">
        <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="20px">
          <Skeleton variant="rectangular" width={200} height={40} sx={{ borderRadius: '20px' }} />
          <Box display="flex" alignItems="center" width="60%">
            <Skeleton variant="circular" width={36} height={36} sx={{ marginRight: '10px' }} />
            <Skeleton variant="rectangular" width="100%" height={20} />
          </Box>
        </Box>

        <Grid container spacing={3} justifyContent="center" marginTop={2}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
            <Skeleton variant="text" width={200} height={30} />
            <Box sx={{ width: '100%', maxWidth: '500px', marginTop: '20px' }}>
              <Grid container spacing={2} justifyContent="center">
                {[...Array(4)].map((_, index) => (
                  <Grid item xs={6} sm={6} key={index}>
                    <Skeleton variant="rectangular" height={100} sx={{ borderRadius: '8px' }} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
            <Skeleton variant="text" width={200} height={30} />
            <Box sx={{ height: '220px' }}>
              {[...Array(3)].map((_, index) => (
                <Box key={index} display="flex" alignItems="center" justifyContent="space-between" marginTop="20px">
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={50} />
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>

        <Skeleton variant="text" width={200} height={30} sx={{ marginTop: "50px", mb: 2 }} />
        <Skeleton variant="rectangular" width="100%" height={300} />
      </Box>
    );
  }

  return (
    <Box marginTop="40px">
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="20px">
        <Select
          value={selectedDocumentGuid}
          onChange={handleDocumentChange}
          sx={{
            color: "#6BBDBD",
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '20px',
              border: '2px solid #6BBDBD',
            },
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: "600",
            width: "200px", // Adjust width as needed
          }}
        >
          {listDocuments && listDocuments.map((document) => (
            <MenuItem key={document.guid} value={document.guid}>{document.title}</MenuItem>
          ))}
        </Select>

        <Box display="flex" alignItems="center" width="60%">
          <svg width="36" height="36" viewBox="0 0 204 291" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '10px' }}>
            {/* SVG path data here */}
          </svg>
          <Box width="100%">

          </Box>
        </Box>
      </Box>

      <Grid container spacing={3} justifyContent="center" marginTop={2}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 'bold', fontFamily: "Inter" }} gutterBottom>
            Distribución de Preguntas 📝
          </Typography>
          <Box sx={{ width: '100%', maxWidth: '500px', marginTop: '20px' }}>
            <Grid container spacing={2} justifyContent="center">
              {LABELS.slice(0, 4).map((label, index) => (
                <Grid item xs={6} sm={6} key={index}>
                  <Paper
                    elevation={3}
                    sx={{
                      backgroundColor: COLORS[index],
                      padding: '10px',
                      textAlign: 'center',
                      borderRadius: '8px',
                      border: '2px solid',
                      borderColor: index === 0 ? '#00B564' :
                        index === 1 ? '#AA161D' :
                          index === 2 ? '#FF9800' :
                            '#9E9E9E',
                      position: 'relative', // Add this for positioning the icon
                    }}
                  >
                    {index === 2 && ( // Add help icon for "Aprendiendo"
                      <MuiTooltip title="Preguntas que has respondido algunas veces bien y otras mal">
                        <HelpOutlineIcon
                          sx={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            fontSize: '16px',
                            color: '#FF9800',
                          }}
                        />
                      </MuiTooltip>
                    )}
                    <Typography variant="h6" style={{ fontSize: '24px', fontWeight: 'bold', fontFamily: 'Inter', color: index === 0 ? '#00B564' : index === 1 ? '#AA161D' : index === 2 ? '#FF9800' : '#9E9E9E' }}>
                      {index === 0 ? progress.memorized :
                        index === 1 ? progress.notKnown :
                          index === 2 ? progress.learning :
                            progress.notStarted}
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Inter', color: index === 0 ? '#00B564' : index === 1 ? '#AA161D' : index === 2 ? '#FF9800' : '#9E9E9E' }}>
                      {label}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 'bold', fontFamily: "Inter" }}>
            Tus exámenes 📚
          </Typography>
          <Box className="custom-scrollbar" sx={{ maxHeight: '220px', overflowY: 'auto' }}>
            {filteredExams.length > 0 ? (
              filteredExams.map((exam, index) => (
                <Box key={index} display="flex" alignItems="center" justifyContent="space-between" marginTop="20px">
                  <Typography variant="body1" sx={{ flex: 1, fontSize: '16px', fontWeight: '600', fontFamily: 'Inter' }}>
                    <MuiTooltip title={exam.title}>
                      <span>
                        {exam.title.length > 15 ? `${exam.title.substring(0, 15)}...` : exam.title}
                      </span>
                    </MuiTooltip>
                  </Typography>
                  <Box display="flex" alignItems="center" sx={{ flex: 1, justifyContent: 'center', marginRight: '20px' }}>
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                      <Box display="flex" alignItems="center" mr={2}>
                        <Typography style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Inter', marginRight: '5px', color: '#00B564' }}>
                          {exam.correct_answers}
                        </Typography>
                        <img src={greenCard} alt="Correct" style={{ width: '20px', height: '20px' }} />
                      </Box>
                      <Box display="flex" alignItems="center" ml={2}>
                        <Typography style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Inter', marginRight: '5px', color: '#AA161D' }}>
                          {exam.incorrect_answers}
                        </Typography>
                        <img src={redCard} alt="Incorrect" style={{ width: '20px', height: '20px' }} />
                      </Box>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Inter', marginRight: '10px' }}>
                    {`${exam.score}/100`}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="220px">
                <Button
                  variant="contained"
                  onClick={handleStudyClick}
                  sx={{
                    backgroundColor: '#6BBDBD',
                    color: 'white',
                    textTransform: 'none',
                    fontWeight: '600',
                    fontFamily: "'Inter', sans-serif",
                    borderRadius: '24px',
                    padding: ' 8px 36px',
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    boxShadow: 'none',

                    '&:hover': {
                      backgroundColor: '#6BBDBD',
                    },

                    maxWidth: 'none',
                  }}

                >
                  Hacer Examen
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ marginTop: "50px", mb: 2 }} style={{ fontSize: '18px', fontWeight: 'bold', fontFamily: "Inter" }}>Evolución de puntos🧠</Typography>
      <Box sx={{ width: '100%', margin: '0 auto' }}>
        {getPointsExamData().length > 0 ? (
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              data={getPointsExamData()}
              margin={{
                top: 10, right: 30, left: 0, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis
                domain={[0, 1000]}
                ticks={[0, 500, 1000, 1500]}
                tickFormatter={(value) => value === 1000 ? '🏆' : `${value}`}
              />
              <XAxis dataKey="date" tick={false} />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
                        <p>{`Fecha: ${payload[0].payload.date}`}</p>
                        <p>{`Puntos: ${payload[0].value}`}</p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Area type="monotone" dataKey="points" stroke="#6BBDBD" fill="#6BBDBD" />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img src={emptyImage} alt="No data available" style={{ maxWidth: '100%', maxHeight: '100%', marginTop: '40px' }} />
            <Typography variant="body2" style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Inter', marginTop: '15px' }}>Aún no has hecho un examen</Typography>
          </Box>
        )}
      </Box>
    </Box >
  );
};

export default Stats;