import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from 'recharts';
import useStylePopUpCorrection from './Styles/useStylePopUpCorrection';



// const dataGraph = [
//   { name: 'Cap 1', Puntos: 80, Cambio: 30 },
//   { name: 'Cap 2', Puntos: 35, Cambio: 10 },
//   { name: 'Cap 3', Puntos: 20, Cambio: -6 },
//   { name: 'Cap 4', Puntos: 50, Cambio: 15 },
//   { name: 'Cap 5', Puntos: 15, Cambio: 20 },
//   { name: 'Cap 6', Puntos: 4, Cambio: 5 },
//   { name: 'Cap 7', Puntos: 30, Cambio: 15 },
// ];



const PopUpCorrection = ({ open, onClose, dataGraph, listScore }) => {
  const styles = useStylePopUpCorrection();

  // Calculate counts based on listScore
  const counts = listScore.reduce((acc, score) => {
    if (score > 0) {
      acc.correct++;
    } else {
      acc.incorrect++;
    }
    return acc;
  }, { correct: 0, incorrect: 0 });

  // Calculate unanswered questions

  // console.log("dataGraph pop up ", dataGraph)
  // Dummy dataGraph for points
  const points = dataGraph.reduce((sum, item) => sum + item.Cambio, 0);
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={styles.modal}
      aria-labelledby="popup-correccio-title"
      aria-describedby="popup-correccio-description"
    >
      <Box sx={styles.paper}>
        <Typography sx={styles.title}>
          🎯 Resultado
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={styles.subtitle2}>
            Has conseguido
          </Typography>
          <Typography sx={{ ...styles.subtitle, display: 'flex', alignItems: 'center', fontWeight: 'bold', color: points > 0 ? '#00B564' : '#AA161D' }}>
            {points > 0 ? (
              <img src={require('../Images/emoji_top.svg').default} alt="Top Emoji" style={{ marginRight: '4px', width: '24px', height: '24px' }} />
            ) : (
              <img src={require('../Images/emoji_down.svg').default} alt="Down Emoji" style={{ marginRight: '4px', width: '24px', height: '24px' }} />
            )}
            {Math.abs(points)}
          </Typography>
        </Box>

        {/* New section for response summary */}
        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '16px', color: '#333', fontFamily: "'Inter', sans-serif" }}>
          📝Tus respuestas
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '32px', marginTop: '10px', marginBottom: '10px' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" sx={{ color: '#4CAF50', fontWeight: 'bold', marginRight: '4px' }}>{counts.correct}</Typography>
              <img src={require('../Images/greenCard.svg').default} alt="Green Card" style={{ width: '20px', height: '20px' }} />
            </Box>
            <Typography variant="body2" sx={{ color: '#4CAF50', fontWeight: 'bold', fontSize: '16px' }}>Correctas</Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" sx={{ color: '#AA161D', fontWeight: 'bold', marginRight: '4px' }}>{counts.incorrect}</Typography>
              <img src={require('../Images/redCard.svg').default} alt="Red Card" style={{ width: '20px', height: '20px' }} />
            </Box>
            <Typography variant="body2" sx={{ color: '#AA161D', fontWeight: 'bold', fontSize: '16px' }}>Incorrectas</Typography>
          </Box>
        </Box>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onClose}
          sx={styles.button}
        >
          Ver Corrección
        </Button>
      </Box>
    </Modal>
  );
};

export default PopUpCorrection;
