
import { getCsrfToken } from './TokenApi'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export async function fetchUserAllFiles(token, method = "GET", user) {
    const user_id = user['sub'];
    const apiUrl = `${BACKEND_URL}/api_v1/all_files/?user_id=${user_id}`;
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        const requestOptions = {
            method: method,
            headers: headers,
        };


        const response = await fetch(apiUrl, requestOptions);


        return await response.json();
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}

export async function fetchUserFiles(token, method = "GET", folder_id, formData, user, detailed = true) {
    const user_id = user['sub'];
    const apiUrl = `${BACKEND_URL}/api_v1/files/${folder_id}/?detail_view=${detailed}&user_id=${user_id}`;
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        // If formData is provided for a POST request, use it as the request body
        if (method === "POST" && formData instanceof FormData) {
            requestOptions.body = formData;
        } else {
            if (formData) {

                requestOptions.body = JSON.stringify(formData);
            }
        }

        const response = await fetch(apiUrl, requestOptions);


        return await response.json();
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}




export async function fetchUserFile(token, method = "GET", folder_id, document_id, requestBody = null, user) {
    // Replace with your Django API endpoint URL
    const user_id = user['sub'];
    const apiUrl = `${BACKEND_URL}/api_v1/file/${folder_id}/${document_id}/?user_id=${user_id}`; // Update with your actual API endpoint
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if (method === "POST" || method === "PUT" && requestBody !== null) {
            requestOptions.body = JSON.stringify(requestBody);
        }


        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        if (method === "DELETE") {
            return true
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}



export async function fetchGenerateSummary(token, method = "POST", document_id, requestBody = null, user) {
    // Replace with your Django API endpoint URL
    const user_id = user['sub'];
    const apiUrl = `${BACKEND_URL}/api_v1/summary/${document_id}/?user_id=${user_id}`; // Update with your actual API endpoint
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if (method === "POST" || method === "PUT" && requestBody !== null) {
            requestOptions.body = JSON.stringify(requestBody);
        }


        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        if (method === "DELETE") {
            return true
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}

