import React, { useState, useEffect } from 'react';
import { Container, Title, Subtitle, TemasEmpty, Points } from './useStyleNewExam';
import { Box, Button, Typography, Grid, Slider, CircularProgress, Paper, Skeleton, Backdrop, Tooltip } from '@mui/material';
import StyledCheckbox from '../Exam/Questions/Styles/StyleRadio';
import Premium from '../PopUpsAlerts/Premium/Premium';
import { makeStyles } from '@material-ui/core/styles';
import { useCredits } from '../Context/CreditsContext';
import NeedCredits from '../NoCreditsModal/NeedCredits';
import animationData from '../Images/singing-contract.json';
import Lottie from 'lottie-react';

import { ReactComponent as Vacio } from '../Images/Vacio.svg';
import { useSubscription } from "../Context/SubscriptionContext";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth/Authhandle';
import * as handle from "../Main/handle";
import { fetchFolderExams } from '../Api/ExamsApi';
import '../Main/style.css';
import { fetchUserFile } from '../Api/FilesApi';
import DocumentList from '../Documents/AllListDocument';



const useStyles = makeStyles((theme) => ({
  paperLeft: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    flexGrow: 1,
    height: "100%",  // Ensure it takes the full height of its parent
    overflowY: "auto",  // Enable vertical scrolling
    backgroundColor: '#F8FFFF !important',

  },
  containerParent: {
    display: "flex",
    flexDirection: "column",
    // Removed: border: "1px solid #6BBDBD",
    // Removed: borderRadius: "20px",
    // Removed: backgroundColor: "white",
    gap: "10px"
  },
  paperLeftContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 2, // This will make the paper fill the height of its container
    minHeight: '100%',
    height: "auto",
    overflowY: 'auto',
    backgroundColor: '#F8FFFF !important',

  },


}));
const NewExam = () => {
  const { accessToken, user } = useAuth(); // Use the hook at the top level
  const { credits } = useCredits();
  const [showNeedCredits, setShowNeedCredits] = useState(false);


  const handleCloseNeedCredits = () => {
    setShowNeedCredits(false);
  };



  const [showPremium, setShowPremium] = useState(false);
  const [premiumTitleText, setPremiumTitleText] = useState('');
  const [sliderValue, setSliderValue] = useState(15);
  const [documentSelected, setDocumentSelected] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentObject, setSelectedDocumentObject] = useState(null);
  const { userData } = useSubscription();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [listDocuments, setListDocuments] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExam, setIsLoadingExam] = useState(false);
  const [requiredCredits, setRequiredCredits] = useState(40); // State to store required credits
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState(['Todos']);

  useEffect(() => {
    if (accessToken && user) {
      async function fetchData() {
        setIsLoading(true);
        if (accessToken && user) {
          await handle.fetchAllDocuments(accessToken, user, setListDocuments);
          // Filter out documents where is_generating or is_blocked is true
          setListDocuments(prevDocuments => {
            if (prevDocuments && prevDocuments.length > 0) {
              return prevDocuments.filter(doc => !doc.is_generating && !doc.is_blocked && !doc.is_error_generating && doc.questions_generated);
            }
            return prevDocuments; // Return original array if it's empty or undefined
          });
          const params = new URLSearchParams(location.search);
          const documentParam = params.get('document');
          if (documentParam) {
            setSelectedDocument(documentParam);
            setDocumentSelected(true);
          }
        }
        setIsLoading(false);
      }
      fetchData();
    }

  }, [accessToken, user, location]);

  useEffect(() => {
    if (selectedDocument && listDocuments.length > 0) {
      const foundDocument = listDocuments.find(doc => doc.guid === selectedDocument);
      setSelectedDocumentObject(foundDocument);
    } else {
      setSelectedDocumentObject(null);
    }
  }, [selectedDocument, listDocuments]);

  // Dummy data for topics
  // const topics = [
  //   { id: 'todos', name: 'Todos', points: 100 },
  //   { id: 'tema1', name: 'Tema 1. Derechos y libertades', points: 30 },
  //   { id: 'tema2', name: 'Tema 2. Garantías constitucionales', points: 25 },
  //   { id: 'tema3', name: 'Tema 3. Organización territorial', points: 20 },
  //   { id: 'tema4', name: 'Tema 4. Poder judicial', points: 15 },
  //   { id: 'tema5', name: 'Tema 5. Cortes Generales', points: 10 },
  //   { id: 'tema6', name: 'Tema 6. Gobierno y Administración', points: 5 },
  // ];

  // Dummy data for documents
  useEffect(() => {
    if (selectedDocument) {
      const fetchTopics = () => {
        const selectedDoc = listDocuments.find(doc => doc.guid === selectedDocument);
        if (selectedDoc && selectedDoc.main_topics) {
          const validTopics = selectedDoc.main_topics.filter(topic => topic.TotalPossiblePoints > 0);
          const totalPoints = validTopics.reduce((sum, topic) => sum + topic.points, 0);
          const totalPossiblePoints = validTopics.reduce((sum, topic) => sum + topic.TotalPossiblePoints, 0);
          const formattedTopics = [
            { name: 'Todos', points: totalPoints, totalPossiblePoints: totalPossiblePoints },
            ...validTopics.map(topic => ({
              name: topic.topic,
              points: topic.points,
              totalPossiblePoints: topic.TotalPossiblePoints
            }))
          ];
          // Sort topics by points (ascending order), keeping 'Todos' at the top
          formattedTopics.sort((a, b) => {
            if (a.name === 'Todos') return -1;
            if (b.name === 'Todos') return 1;
            return a.points - b.points;
          });
          setTopics(formattedTopics);
        } else {
          setTopics([]);
        }
      };

      fetchTopics();
    } else {
      setTopics([]);
    }
  }, [selectedDocument, accessToken, user]);

  // Fixed colors for items

  const handleShowPremium = (titleText) => {
    setShowPremium(true);
    setPremiumTitleText(titleText);
  };

  const handleSliderChange = (event, newValue) => {
    if (newValue > 15 && userData.plan_name === "Basic") {
      handleShowPremium('tests');
    } else {
      setSliderValue(newValue);
    }
  };

  const handleDocumentSelect = (document) => {
    setSelectedDocument(document.guid);
    setSelectedDocumentObject(document);
    setDocumentSelected(true);
  };

  const handleTopicSelect = (topicName) => {
    if (userData.plan_name === "Basic" && topicName !== 'Todos') {
      handleShowPremium('topics');
    } else {
      setSelectedTopics(prevSelected => {
        if (topicName === 'Todos') {
          return ['Todos'];
        } else {
          const newSelected = prevSelected.filter(name => name !== 'Todos');
          if (newSelected.includes(topicName)) {
            return newSelected.filter(name => name !== topicName);
          } else {
            return [...newSelected, topicName];
          }
        }
      });
    }
  };
  const handleGenerateExam = async () => {
    // Function to execute asynchronous operations
    if (credits >= 40) {
      setIsLoadingExam(true)

      const result = await handleExamUpload(selectedTopics);
      setIsLoadingExam(false)
      navigate(`/exam/${result.path}`);

    } else {
      setShowNeedCredits(true)
    }

  }
  const handleExamUpload = async (selectedTopics) => {
    const documentId = selectedDocument;
    const formDataExam = new FormData()

    formDataExam.append('type[type_exam]', "bullet_points");
    formDataExam.append('number_questions', sliderValue);
    formDataExam.append('init_page', "0");
    formDataExam.append('final_page', "20");
    formDataExam.append('type[Document]', documentId);
    formDataExam.append('Topics', selectedTopics);
    const uploadExam = await fetchFolderExams(accessToken, "POST", documentId, user, formDataExam);
    return uploadExam;
  };


  const getPointColor = (points, totalPossiblePoints) => {
    const percentage = (points / totalPossiblePoints) * 100;
    if (percentage >= 80) return '#4CAF50'; // Memorizado (Green)
    if (percentage >= 50) return '#FFA726'; // Aprendiendo (Orange)
    if (percentage > 0) return '#EF5350';
    if (percentage < 0) return '#EF5350'; // Incorrecto (Red)
    return '#9E9E9E';                       // Sin Empezar (Gray)
  };

  return (
    <Container style={{
      display: "flex",
      flexDirection: "row",
      maxWidth: "1000px",
      width: "100%",
      position: 'relative',  // Add this
      zIndex: 1  // Add this
      , margin: "0 auto",
      paddingBottom: "0px"
    }}>

      <Grid
        container
        spacing={0}
        className={classes.paperLeftContainer}
      >

        <Grid item style={{ width: "100%" }}>
          <Paper elevation={3} className={classes.paperLeft}>

            <Title>Elige que quieres estudiar</Title>
            <Subtitle sx={{ marginTop: '80px' }}>Paso 1. Selecciona el documento a estudiar</Subtitle>
            {listDocuments != null && listDocuments.length == 0 ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '32px',
                minHeight: '300px'
              }}>
                <Vacio width="120" height="120" />
                <Typography sx={{ mt: 2, color: '#666', fontSize: '16px' }}>
                  Debes generar preguntas  para hacer un test
                </Typography>
              </Box>
            ) : (<DocumentList
              listDocuments={listDocuments}
              isLoading={isLoading}
              selectedDocument={selectedDocument}
              onDocumentSelect={handleDocumentSelect}
              selectable={true}
            />)}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
              <Subtitle sx={{ margin: 0 }}>Paso 2. Elige los temas a estudiar</Subtitle>
              {userData.plan_name === "Basic" && (
                <Button
                  variant="contained"
                  onClick={() => handleShowPremium('topics')}
                  sx={{
                    backgroundColor: '#6BBDBD',
                    color: 'white',
                    textTransform: 'none',
                    fontWeight: '600',
                    fontFamily: "'Inter', sans-serif",
                    borderRadius: '24px',
                    padding: '8px 24px',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    '&:hover': {
                      backgroundColor: '#6BBDBD',
                    },
                  }}
                >
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.99967 7.5H6.49984C5.56642 7.5 5.09936 7.5 4.74284 7.68166C4.42924 7.84145 4.17445 8.09623 4.01466 8.40983C3.83301 8.76635 3.83301 9.23341 3.83301 10.1668V14.8335C3.83301 15.7669 3.83301 16.2334 4.01466 16.5899C4.17445 16.9035 4.42924 17.1587 4.74284 17.3185C5.09901 17.5 5.56551 17.5 6.49713 17.5L14.5023 17.5C15.4338 17.5 15.8997 17.5 16.2558 17.3185C16.5694 17.1587 16.8251 16.9035 16.9849 16.5899C17.1663 16.2337 17.1663 15.7679 17.1663 14.8363V10.1641C17.1663 9.23249 17.1663 8.766 16.9849 8.40983C16.8251 8.09623 16.5694 7.84144 16.2558 7.68166C15.8993 7.5 15.4333 7.5 14.4998 7.5H7.99967ZM7.99967 7.5V5.1001C7.99967 3.66416 9.08303 2.5 10.4194 2.5C11.1061 2.5 11.7253 2.80732 12.1657 3.30094" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  Desbloquear temas
                </Button>
              )}
            </Box>

            {!selectedDocument ? (
              <TemasEmpty>
                Elige primero el documento a estudiar
              </TemasEmpty>

            ) : (
              <Box className="custom-scrollbar" sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                <Grid container spacing={3}>
                  {topics.map((topic) => (
                    <Grid item xs={12} sm={6} md={4} key={topic.name} sx={{ padding: '8px' }}>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #6BBDBD',
                        borderRadius: '8px',
                        padding: '16px',
                        height: '72px', // Fixed height for each topic box
                        backgroundColor: selectedTopics.includes(topic.name) ? '#B8E0E0' : '#E2F1F1',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        cursor: 'pointer',
                      }}
                        onClick={() => handleTopicSelect(topic.name)}
                      >
                        <StyledCheckbox
                          checked={selectedTopics.includes(topic.name)}
                          onChange={() => {/* Handle checkbox change */ }}
                          sx={{ marginRight: '12px' }}
                        />
                        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                          <Tooltip title={topic.name} arrow>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: topic.name === 'Todos' ? 700 : 500,
                                fontSize: '14px'
                              }}
                            >
                              {topic.name.length > 32 ? `${topic.name.slice(0, 32)}...` : topic.name}
                            </Typography>
                          </Tooltip>
                          <Points sx={{
                            color: getPointColor(topic.points, topic.totalPossiblePoints),
                            fontSize: '14px'
                          }}>
                            {topic.points} de {topic.totalPossiblePoints} 🧠
                          </Points>
                        </Box>
                        {topic.name !== 'Todos' && userData.plan_name === "Basic" && (
                          <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.69173 8H5.99984C5.06642 8 4.59936 8 4.24284 8.18166C3.92924 8.34144 3.67445 8.59623 3.51466 8.90983C3.33301 9.26635 3.33301 9.73341 3.33301 10.6668V15.3335C3.33301 16.2669 3.33301 16.7334 3.51466 17.0899C3.67445 17.4035 3.92924 17.6587 4.24284 17.8185C4.59901 18 5.06552 18 5.99712 18H14.0023C14.9338 18 15.3997 18 15.7558 17.8185C16.0694 17.6587 16.3251 17.4035 16.4849 17.0899C16.6663 16.7337 16.6663 16.2679 16.6663 15.3363V10.6641C16.6663 9.73249 16.6663 9.266 16.4849 8.90983C16.3251 8.59623 16.0694 8.34144 15.7558 8.18166C15.3993 8 14.9333 8 13.9998 8H12.3071M7.69173 8H12.3071M7.69173 8C7.58552 8 7.49967 7.9139 7.49967 7.80769V5.5C7.49967 4.11929 8.61896 3 9.99967 3C11.3804 3 12.4997 4.11929 12.4997 5.5V7.80769C12.4997 7.9139 12.4133 8 12.3071 8" stroke="#A0A0A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        )}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
              <Subtitle>Paso 3. Elige el número de preguntas</Subtitle>
              {userData.plan_name === "Basic" && (
                <Button
                  variant="contained"
                  onClick={() => handleShowPremium('tests')}
                  sx={{
                    backgroundColor: '#6BBDBD',
                    color: 'white',
                    textTransform: 'none',
                    fontWeight: '600',
                    fontFamily: "'Inter', sans-serif",
                    borderRadius: '24px',
                    padding: '8px 24px',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    '&:hover': {
                      backgroundColor: '#6BBDBD',
                    },
                  }}
                >
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.99967 7.5H6.49984C5.56642 7.5 5.09936 7.5 4.74284 7.68166C4.42924 7.84145 4.17445 8.09623 4.01466 8.40983C3.83301 8.76635 3.83301 9.23341 3.83301 10.1668V14.8335C3.83301 15.7669 3.83301 16.2334 4.01466 16.5899C4.17445 16.9035 4.42924 17.1587 4.74284 17.3185C5.09901 17.5 5.56551 17.5 6.49713 17.5L14.5023 17.5C15.4338 17.5 15.8997 17.5 16.2558 17.3185C16.5694 17.1587 16.8251 16.9035 16.9849 16.5899C17.1663 16.2337 17.1663 15.7679 17.1663 14.8363V10.1641C17.1663 9.23249 17.1663 8.766 16.9849 8.40983C16.8251 8.09623 16.5694 7.84144 16.2558 7.68166C15.8993 7.5 15.4333 7.5 14.4998 7.5H7.99967ZM7.99967 7.5V5.1001C7.99967 3.66416 9.08303 2.5 10.4194 2.5C11.1061 2.5 11.7253 2.80732 12.1657 3.30094" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  Desbloquear límite
                </Button>
              )}
            </Box>

            <Box sx={{ width: '100%', mt: 2, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ width: '60%', marginTop: '50px' }}>
                <Slider
                  aria-label="Number of questions"
                  value={sliderValue}
                  onChange={handleSliderChange}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={50}
                  sx={{
                    color: '#6BBDBD',
                    height: 8,
                    '& .MuiSlider-track': {
                      border: 'none',
                    },
                    '& .MuiSlider-thumb': {
                      height: 24,
                      width: 24,
                      backgroundColor: '#fff',
                      border: '2px solid currentColor',
                      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                        boxShadow: 'inherit',
                      },
                    },
                    '& .MuiSlider-valueLabel': {
                      lineHeight: 1.2,
                      fontSize: 12,
                      background: 'unset',
                      padding: 0,
                      width: 32,
                      height: 32,
                      borderRadius: '50% 50% 50% 0',
                      backgroundColor: '#6BBDBD',
                      transformOrigin: 'bottom left',
                      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
                      '&:before': { display: 'none' },
                      '&.MuiSlider-valueLabelOpen': {
                        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
                      },
                      '& > *': {
                        transform: 'rotate(45deg)',
                      },
                    },
                  }}
                />
              </Box>
              <Button
                variant="contained"
                onClick={handleGenerateExam}
                disabled={!selectedDocument}
                sx={{
                  backgroundColor: selectedDocument ? '#6BBDBD' : '#E0E0E0',
                  color: selectedDocument ? 'white' : '#9E9E9E',
                  textTransform: 'none',
                  fontWeight: '600',
                  fontFamily: "'Inter', sans-serif",
                  borderRadius: '24px',
                  padding: '8px 24px',
                  fontSize: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginTop: '30px',
                  marginBottom: '50px',
                  '&:hover': {
                    backgroundColor: selectedDocument ? '#5AACAC' : '#E0E0E0',
                  },
                  '&:disabled': {
                    cursor: 'not-allowed',
                  },
                }}
              >
                ¡Empezar!
              </Button>
            </Box>
            {showPremium && (
              <Premium
                onClose={() => setShowPremium(false)}
                titleText={premiumTitleText}
              />
            )}
            {showNeedCredits && (
              <NeedCredits
                initialOpen={showNeedCredits}
                onClose={handleCloseNeedCredits}
              />
            )}
          </Paper>
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingExam}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Lottie
            animationData={animationData}
            style={{ width: 300, height: 300 }}
          />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Estamos preparando tu examen...
          </Typography>
        </Box>
      </Backdrop>


    </Container>
  );
};

export default NewExam;

