import React, { useState, useEffect, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // You can replace this with your own SVGs
import { styled } from '@mui/system';

import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
    makeStyles
} from "@material-ui/core";
import { SvgIcon } from '@mui/material';
import { useSubscription } from '../Context/SubscriptionContext';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit'; // Import the Edit icon

const CustomCheckCircleIcon = (props) => (
    <SvgIcon {...props}>
        <circle cx="12" cy="12" r="12" fill="rgba(107, 189, 189, 0.10)" />
        <path
            d="M10 15.172L7.414 12.586L6 14L10 18L18 10L16.586 8.586L10 15.172Z"
            fill="#6BBDBD"
        />
    </SvgIcon>
);

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '30px',
        height: '450px',

        overflow: 'hidden',
        position: 'relative',
        padding: 0
    },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: '10px',
    top: '10px',
    zIndex: 1,
}));

const CustomListItem = styled(ListItem)({
    alignItems: 'center',
    padding: '4px 0', // Reduced padding from 8px to 4px
});

const CustomListItemIcon = styled(ListItemIcon)({
    minWidth: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: 'rgba(107, 189, 189, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0, // Remove padding
});

const useStyles = makeStyles((theme) => ({
    currentPlan: {
        padding: 10,
        display: "flex",
        justifyContent: 'space-between',
        border: '1px solid #6BBDBD',
        color: "#6BBDBD",
        borderRadius: "20px",

    },
    buttonManage: {
        backgroundColor: "#6BBDBD !important",
        color: "white !important",
        textTransform: "none !important",
        borderRadius: "6px !important",
        fontSize: "14px !important",
        display: "flex !important",
        alignItems: "flex-end !important",
        gap: "2px !important",
        borderRadius: "20px !important",
        display: "flex !important"
        , '&:disabled': {
            backgroundColor: '#B0B0B0 !important', // Gray color for the disabled state
            color: '#FFFFFF !important'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD !important', // Gray color for the disabled state
            color: "white !important",
            // padding: "8px 14px", // Added padding for better appearance

        },
        paddingLeft: "20px !important",
        paddingRight: "20px !important",
        fontWeight: "bold !important"
    },
    textDesc: {
        fontSize: "14px"
    }
}));

// Remove the TypeScript type definition
// type ItemList = {
//   icon: string;
//   text: string;
//   score: number;
// };

// Dummy data for testing (now in JavaScript)
const dummyData = [
    { icon: '✏️', text: 'Estudia sin límite de créditos diario' },
    { icon: '📚', text: 'Sube tantos documentos y apuntes como quieras' },
    { icon: '🧠', text: 'Desbloquea todas las preguntas de exámenes.' },
];

const PositiveScoreIcon = () => (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 15.5C11.6421 15.5 15 12.1421 15 8C15 3.85786 11.6421 0.5 7.5 0.5C3.35786 0.5 0 3.85786 0 8C0 12.1421 3.35786 15.5 7.5 15.5Z" fill="#00B564" />
        <path d="M11.5 7.575L7.5 3.75L3.5 7.575H6.15V12.25H8.725V7.575H11.5Z" fill="white" />
    </svg>
);

const NegativeScoreIcon = () => (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 0.5C3.358 0.5 0 3.858 0 8C0 12.142 3.358 15.5 7.5 15.5C11.642 15.5 15 12.142 15 8C15 3.858 11.642 0.5 7.5 0.5ZM7.5 12.25L3.5 8.42375H6.21425V3.75H8.7855V8.42375H11.5L7.5 12.25Z" fill="#AA161D" />
    </svg>
);

function NeedCredits({ initialOpen = false, onClose, ItemList = dummyData }) {
    const [isOpen, setIsOpen] = useState(initialOpen);
    const { t } = useTranslation();
    const classes = useStyles();
    const { userData } = useSubscription();
    const [currentPlan, setCurrentPlan] = useState("")
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        setIsOpen(false);
        if (onClose) onClose();
    }, [onClose]);

    useEffect(() => {
        if (userData && userData.plan_id) {
            setCurrentPlan(userData.plan_credits);
        }
    }, [userData]);

    const renderListItem = (item) => (
        <CustomListItem key={item.text}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <CustomListItemIcon style={{ backgroundColor: 'white' }}>
                        <Typography style={{ padding: 0, backgroundColor: 'white' }}>{item.icon}</Typography>
                    </CustomListItemIcon>
                </Grid>
                <Grid item xs>
                    <Typography variant="body1" style={{ fontSize: '14px', fontWeight: '500', textAlign: 'left' }} >
                        {item.text}
                    </Typography>
                </Grid>
                <Grid item>
                    <Box display="flex" alignItems="center">
                        {item.score >= 0 ? <PositiveScoreIcon /> : <NegativeScoreIcon />}
                        <Typography variant="body2" style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            marginLeft: '4px',
                            color: item.score >= 0 ? '#00B564' : '#AA161D'
                        }}>
                            {Math.abs(item.score)}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </CustomListItem>
    );

    return (
        <CustomDialog
            open={isOpen}
            onClose={handleClose}
        >
            <DialogContent style={{ padding: 0, border: 0 }}>
                <Grid container spacing={0} >
                    <Grid item xs={12} md={5} style={{ padding: '0' }}>
                        <img src={require('./img/paywall.png')} alt="Illustration" style={{ width: '255px', height: '100%', objectFit: 'cover', borderRadius: '20px 0 0 20px' }} />
                    </Grid>
                    <Grid item xs={12} md={7} style={{
                        padding: '30px', // Increased padding
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <div>
                            <Typography fontSize="20px" fontWeight="900" lineHeight="24px" gutterBottom style={{ fontFamily: 'Inter, sans-serif' }}>
                                😢 Has alcanzado el límite diario de créditos...
                            </Typography>
                            <Typography style={{ fontSize: "16px", color: "#333", textAlign: "left", marginTop: "16px" }}>
                                ¿Por qué deberías mejorar tu cuenta?
                            </Typography>
                        </div>
                        <List>
                            {ItemList.map((item, index) => (
                                <ListItem key={index} alignItems="flex-start" style={{ padding: '8px 0' }}>
                                    <ListItemIcon style={{ minWidth: '24px', height: '24px', marginRight: '16px' }}>
                                        <Typography style={{ fontSize: '24px' }}>{item.icon}</Typography>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1" style={{ fontSize: '16px', fontWeight: '500' }}>
                                                {item.text}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>

                        <div>

                            <Button style={{ display: "block", margin: "16px auto 0" }} className={classes.buttonManage} onClick={() => navigate("/plans")}>
                                ¡Desbloquear!
                            </Button>
                            <Box display="flex" justifyContent="center" width="100%">
                                <Button
                                    style={{
                                        fontSize: "12px",
                                        marginTop: "4px",
                                        marginBottom: "10px",
                                        textTransform: "none",
                                        color: "gray",
                                        textAlign: "center",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",

                                    }}
                                    onClick={handleClose}
                                >
                                    Seguir estudiando mañana
                                </Button>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>

        </CustomDialog >
    );
}

export default NeedCredits;
