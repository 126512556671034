const useStylePopupCorreccio = () => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    padding: '24px',
    borderRadius: '20px',
    maxWidth: 500,
    width: '100%',
    maxHeight: '90vh',
    overflow: 'auto',
    fontFamily: "'Inter', sans-serif",
  },
  title: {
    marginBottom: '16px',
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#333',
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    fontSize: '42px',
  },
  subtitle2: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  chart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '24px',
    width: '100%',
  },
  responseSection: {
    marginTop: '24px',
  },
  responseSectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '16px',
  },
  responseGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  responseItem: {
    textAlign: 'center',
  },
  responseValue: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
  },
  responseIcon: {
    fontSize: '32px',
    marginBottom: '8px',
  },
  responseLabel: {
    fontSize: '14px',
    color: '#666',
  },
  button: {
    backgroundColor: '#6BBDBD',
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
    fontFamily: "'Inter', sans-serif",
    borderRadius: '24px',
    padding: '8px 24px',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: '#6BBDBD',
    },
    width: '60%',
    maxWidth: 'none',
    margin: '0 auto', // This centers the button horizontally
    marginTop: "30px"
  },
});

export default useStylePopupCorreccio;
